import {
  Box,
  Card,
  CardContent,
  Grid,
  IconButton,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";
import { useTheme } from "@emotion/react";
import { useSelector } from "react-redux";

const BsiCoLabSkull = () => {
  const theme = useTheme();
  const isDrawerOpen = useSelector((state) => state.drawer.open);
  return (
    <>
      <Grid item xs={12} md={isDrawerOpen ? 12 : 6} lg={isDrawerOpen ? 6 : 6}>
        <Card
          sx={{
            boxShadow: "none",
            borderRadius: "10px",
            cursor: "pointer",
            backgroundColor: "#fff", // Adjust background color for skeleton
          }}
        >
          <CardContent
            sx={{
              p: 0,
              paddingBottom: "0",
              [theme.breakpoints.down("sm")]: {
                position: "relative",
              },
            }}
          >
            <Stack
              direction="row"
              sx={{
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                gap: 2,
                mb: 2,
                px: 2,
                py: 2,
                // minHeight: "100px",
                [theme.breakpoints.down("sm")]: {
                  display: "block",
                },
              }}
            >
              {/* Left side - Logo and Title */}
              <Box 
              sx={{ 
                display: "flex",
                alignItems: "center",
                gap: 10,
                [theme.breakpoints.down("sm")]: {
                    flexDirection:'column',
                    gap:4,
                    alignItems: "flex-start",
                  },
               }}
                >
                <Skeleton variant="circular" width={34} height={34} />
                <Box>
                  <Skeleton variant="text" width={100} height={20} />
                  <Skeleton variant="text" width={150} height={15} />
                </Box>
              </Box>

              {/* Right side - Open in New Icon */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  alignSelf: "start",
                  [theme.breakpoints.down("sm")]: {
                    position: "absolute",
                    top: "15px",
                    right: "10px",
                  },
                }}
              >
                <Skeleton variant="circular" width={24} height={24} />
              </Box>
            </Stack>

            {/* Bottom section - Requirements */}
            <Box
              sx={{
                py: 2,
                px: 2,
                mt: 2,
                width: "100%",
                backgroundColor: "rgba(219, 235, 248, 0.2)",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                borderTop: "solid 1px rgba(162, 194, 241, 1)",
                [theme.breakpoints.down("md")]: {
                  flexWrap: "wrap",
                  py: 1,
                },
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
                <Skeleton variant="text" width={120} height={20} />
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
                <Skeleton variant="text" width={150} height={20} />
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
};

export default BsiCoLabSkull;
