import { useEffect, useState } from "react";
import {
  Paper,
  Box,
  Typography,
  Button,
  IconButton,
  Tooltip,
  TextField,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import shortid from "shortid";
// import { toast } from 'react-toastify';
import { useUploadDocumentMutation } from "../../redux/api/BsiDrive/uploadDocument";
import { useDispatch, useSelector } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";
import { CloudUpload } from "@mui/icons-material";
import CustomLoader from "../../utils/Loader/CustomLoader";
import {
  setRefetchDocument,
  setRefetchQuickDocsList,
  setRefetchSubFolder,
} from "../../redux/features/refetch";
import { hideLoader, showLoader } from "../../redux/features/loader";
import { useSendFileMutation } from "../../redux/api/SolutionProvider/Inbox/sendFile";
import socket from "../Inbox/socket";

const SendDocument = ({
  handleClose,
  handleFileChange,
  showToastMessage,
  files,
  setFiles,
  selectedFiles,
  setSelectedFiles,
  groupData,
  groupChatId,
  setGroupData,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const userId = localStorage.getItem("userId");
  // const [selectedFiles, setSelectedFiles] = useState([]);
  // const [files, setFiles] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const userName = localStorage.getItem("userName");
  const userPic = localStorage.getItem("avatar");
  const [userInput, setUserInput] = useState("");
  // const {
  //   groupChatId
  // } = useSelector((state) => state.chat);
  const [sendDocument, { isLoading, isError }] = useSendFileMutation();
  // const handleFileChange = (e) => {
  //   const newFiles = Array.from(e.target.files);
  //   setFiles(newFiles);
  //   setSelectedFiles((prevFiles) => [
  //     ...prevFiles,
  //     ...newFiles.map((file) => ({
  //       id: shortid.generate(),
  //       filename: file.name,
  //       filetype: file.type,
  //       fileimage: URL.createObjectURL(file),
  //       datetime: file.lastModifiedDate.toLocaleString("en-IN"),
  //       filesize: filesizes(file.size),
  //     })),
  //   ]);
  // };

  const handleDeleteFile = (id, filename) => {
    // if (window.confirm("Are you sure you want to delete this file?")) {
    setSelectedFiles((prevFiles) => prevFiles.filter((file) => file.id !== id));
    setFiles((prevFiles) =>
      Array.from(prevFiles).filter((file) => file.name !== filename)
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (files.length === 0) {
      showToastMessage("Please select a file to send", "error");
    } else {
      setIsUploading(true);
      try {
        const chatFormData = new FormData();
        files.forEach((file) => chatFormData.append("files", file));
        chatFormData.append("chatId", groupChatId);
        chatFormData.append("owner_id", userId);
        chatFormData.append("isDocument", false);
        chatFormData.append("message", userInput);

        const response = await sendDocument({ userId, chatFormData }).unwrap();
        // Construct the new message object
        if (response?.data) {
          const newMessageRec = {
            sender: {
              _id: userId,
              name: userName,
              avatar: userPic || "",
            },
            chat: {
              _id: groupData?._id,
              chatName: groupData?.chatName,
              isGroupChat: groupData?.isGroupChat || true,
              users: groupData?.users.map((user) => user._id),
            },
            message: userInput || "", // Ensure the message is sent correctly
            is_file: "Yes",
            drive_ids: response.data.map((document) => ({
              name: document.name,
              isDocument: document.isDocument,
              parent_id: document.parent_id,
              owner_id: document.owner_id,
              isDeleted: document.isDeleted,
              s3Url: document.s3Url,
              __v: document.__v,
              _id: document._id,
              createdAt: document.createdAt,
              updatedAt: document.updatedAt,
            })),
            _id: response.data.map((document) => document._id),
            createdAt: response.data[0]?.createdAt || new Date().toISOString(), // Use the first document's createdAt or current date
            updatedAt: response.data[0]?.updatedAt || new Date().toISOString(), // Use the first document's updatedAt or current date
          };

          // Update group data
          // setGroupData((prevGroupData) => {
          //   const existingMessages = prevGroupData?.data?.getGroupMessageData || [];
          //   return {
          //     ...prevGroupData,
          //     data: {
          //       ...prevGroupData?.data,
          //       getGroupMessageData: [...existingMessages, newMessageRec],
          //     },
          //   };
          // });

          // Emit the new message via socket
          socket.emit("new message", newMessageRec);
          setUserInput("");
          setSelectedFiles([]);
          handleClose();
        }
      } catch (isError) {
        const errorMessage = isError?.message || "Failed to send the file";
        showToastMessage(errorMessage, "error");

        // Return early to prevent further processing
        return;
      } finally {
        setIsUploading(false);
      }
    }
  };

  useEffect(() => {
    if (isUploading === "true") {
      dispatch(showLoader());
    } else {
      dispatch(hideLoader());
    }
  }, [isUploading, dispatch]);

  return (
    <Box sx={{ backgroundColor: theme.palette.background.paper }}>
      <Typography variant="h6" sx={{ mb: 2 }}>
        Send Document
      </Typography>
      <form onSubmit={handleSubmit}>
        <Box
          sx={{
            mb: 2,
            maxHeight: "calc(45vh - 70px)",
            overflowY: "auto",
            "&::-webkit-scrollbar": {
              width: "6px",
              borderRadius: "4px",
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "transparent",
              borderRadius: "4px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#9C9C9C",
              borderRadius: "4px",
            },
          }}
        >
          {selectedFiles.map(
            ({ id, filename, fileimage, filesize, datetime }) => (
              <Box
                key={id}
                sx={{ display: "flex", alignItems: "center", mb: 1 }}
              >
                <Box
                  sx={{
                    width: 50,
                    height: 50,
                    mr: 2,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: theme.palette.grey[200],
                  }}
                >
                  {filename.match(/\.(jpg|jpeg|png|gif|svg)$/i) ? (
                    <img
                      src={fileimage}
                      alt={filename}
                      style={{ maxWidth: "100%", maxHeight: "100%" }}
                    />
                  ) : (
                    <Typography variant="body2">File</Typography>
                  )}
                </Box>
                <Box sx={{ flexGrow: 1 }}>
                  <Typography variant="body2" noWrap>
                    {filename}
                  </Typography>
                  <Typography variant="caption" color="textSecondary">
                    Size: {filesize} | Modified: {datetime}
                  </Typography>
                </Box>
                <Tooltip title="Delete">
                  <IconButton
                    color="primary"
                    onClick={() => handleDeleteFile(id, filename)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            )
          )}
          {/* <TextField
          label=""
          variant="outlined"
          placeholder="Type a message"
          value={userInput}
          onChange={(e) => {
            const inputValue = e.target.value;
            const wordCount = inputValue.trim().split(/\s+/).length; // Calculate the number of words
            setUserInput(inputValue); 
           
          }}
          sx={{
            width: "calc(100% - 153px)",
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            "& .MuiInputBase-input": {
              padding: "0px 10px",
              fontSize: "0.95rem",
              color: "lightSecondary.main",
              fontWeight: "600",
              overflowY: "auto",
              maxHeight: "100px", // Limit the height of the input area
              // Apply custom scrollbar styles
              "&::-webkit-scrollbar": {
                width: "6px",
                borderRadius: "4px",
              },
              "&::-webkit-scrollbar-track": {
                backgroundColor: "transparent",
                borderRadius: "4px",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#9C9C9C",
                borderRadius: "4px",
              },
            },
          }}
          /> */}
        </Box>
        <TextField
          label=""
          variant="outlined"
          placeholder="Type a message"
          value={userInput}
          multiline
          rows={3} 
          maxRows={6} // Maximum number of rows allowed
          // onKeyDown={(e) => {
          //   if (e.key === "Enter" && e.shiftKey) {
          //     e.preventDefault();
          //     setUserInput((prev) => prev + "\n"); 
          //   } else if (e.key === "Enter" && !e.shiftKey && !isLoading) {
          //     e.preventDefault();
          //     handleSubmit(); 
          //   }
          // }}
          onChange={(e) => {
            const inputValue = e.target.value;
            const wordCount = inputValue.trim().split(/\s+/).length; 
            setUserInput(inputValue);
          }}
          sx={{
            width: "100%",
            // height: "100px",
            backgroundColor: "extraLTheme.main",
            mt: 3,
            mb: 6,
            borderColor: "ltheme.main", // Light border color
            borderRadius: "10px",
            // borderStyle: "solid",
            "&.Mui-focused": {
                backgroundColor: "inherit", // Prevents the background from changing on focus
                "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "ltheme.main", // Keeps the border color same on focus
                },
            },
            "& .MuiOutlinedInput-root": {
              height: "100px",
              "& fieldset": {
                borderColor:  "ltheme.main", // Light border color
                borderRadius: "15px",
                // borderStyle: "solid",
              },
              "&:hover fieldset": {
                borderColor:  "ltheme.main", // Slightly darker on hover
                borderRadius: "15px",
                // borderStyle: "solid",
              },
              "&.Mui-focused fieldset": {
                borderColor:  "ltheme.main", // Theme color when focused
                borderRadius: "15px",
                // borderStyle: "solid",
              },
              "&.Mui-focused": {
                boxShadow: "none", // Disable the shadow effect on focus
              },
            },
            "& .MuiOutlinedInput-input": {
              padding: "8px",
              fontSize: "0.95rem",
              color: "lightSecondary.main",
              fontWeight: "600",
              lineHeight: "24px",
              overflowY: "auto",
              "&::placeholder": {
                // fontWeight: "bold",
                color: "#000000",
              },
              "&::-webkit-scrollbar": {
                width: "6px",
                borderRadius: "4px",
              },
              "&::-webkit-scrollbar-track": {
                backgroundColor: "transparent",
                borderRadius: "4px",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#9C9C9C",
                borderRadius: "4px",
              },
            },
            "& .MuiInputLabel-root": {
              transform: "translate(14px, 12px) scale(1)",
            },
            "& .MuiInputLabel-shrink": {
              transform: "translate(14px, -6px) scale(0.75)",
            },
          }}
        />

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          {/* <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
            <input
              type="file"
              id="fileupload"
              name="document"
              style={{ display: "none" }}
              onChange={handleFileChange}
              multiple
            />
            <label htmlFor="fileupload">
              <Button
                variant="contained"
                component="span"
                startIcon={<CloudUpload />}
              >
                Choose Files
              </Button>
            </label>
          </Box> */}
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            {isUploading === false ? (
              <Button type="submit" variant="contained" sx={{ mr: 2 }}>
                Send
              </Button>
            ) : (
              <Button variant="contained" sx={{ mr: 2 }}>
                Sending...
              </Button>
            )}
            <Button type="button" variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default SendDocument;
