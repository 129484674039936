import React, { useEffect, useState } from "react";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import {
  Avatar,
  Box,
  Button,
  Grid,
  MenuItem,
  Typography,
  Card,
  CardContent,
  IconButton,
  Select,
  FormControl,
  TextField,
  Input,
  InputAdornment,
  Chip,
  FilledInput,
  Stack,
  Slider,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import { styled } from "@mui/material/styles";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import { Link, useNavigate } from "react-router-dom";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { CloseOutlined } from "@mui/icons-material";
import { getImages } from "../../commonComponents/const";
import { useFetchIndustryListQuery } from "../../redux/api/Industry/getIndustry";
import { useFetchUseCaseListQuery } from "../../redux/api/usecase/getUseCase";
import { useDispatch, useSelector } from "react-redux";
import { resetProfile, setLogo, setHeadquarter, setIndustry, setNewSolutionName, setPriceRange, setPrizeValue, setSelectedLogo, setSolutionTagline, setSolutionWebUrl, setTech, setUseCase, setCheckSolutionName ,setSolutionNameError} from "../../redux/features/SolutionProvider/editSolutionSlice";
import shortid from "shortid";
import { useFetchMasterDataQuery } from "../../redux/api/SolutionProvider/MySolution/getMaster";
import { useFetchSolutionDetailQuery } from "../../redux/api/SolutionProvider/MySolution/getSolutionDetail";
import { setRefetchMySolutionList } from "../../redux/features/refetch";
import { setSolutionData } from "../../redux/features/SolutionProvider/editSolutionSlice";
import { useFetchExistingSolutionNameQuery } from "../../redux/api/SolutionProvider/MySolution/checkExistingSolutionName";
import AvatarWithUpload from "../../commonComponents/Profile/customAvatar";
import { hideLoader, showLoader } from "../../redux/features/loader";


const EditSolutionFirstStep = ({ nextStep, showToastMessage }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userId = localStorage.getItem("userId")
  const s3Url = process.env.REACT_APP_S3_BUCKET_URL;
  const { priceRange,dataLoaded, prizeValue, logo  , checkSolutionName , solutionNameError, solutionName ,solutionTagline ,solutionWebUrl , headquarter  ,  tech  , } = useSelector((state)=> state.editSolution)
  const {selectedSolutionId} = useSelector((state)=> state.editSolution)
  const selectedLogo = useSelector(state => state.editSolution.selectedLogo || []);
  const industry = useSelector(state => state.editSolution.industry || []);
  const useCase = useSelector(state => state.editSolution.useCase || []);
  // const [checkSolutionName , setCheckSolutionName] = useState("");
 
 

// validation state
const [errors, setErrors] = useState({
  logo: false,
  solutionName: false,
  industry: false,
  headquarter: false,
  tech: false,
  useCase: false,
  solutionWebUrl: false,
  checkSolutionName: false,
  solutionNameError: false,
});

const handleNextStep = () => {
  const newErrors = {
    logo: !logo,
    solutionName: solutionName.trim() === "",
    industry: industry.length === 0,
    headquarter: headquarter.trim() === "",
    tech: tech.length === 0,
    useCase: useCase.length === 0,
    solutionWebUrl: solutionWebUrl.trim() === "",
    checkSolutionName: checkSolutionName === "",
    solutionNameError: solutionNameError !== null
  };

  setErrors(newErrors);

  if (!Object.values(newErrors).includes(true)) {
    nextStep();
  }
};


//   api's
// industry
 const {
    data: industryData,
    isLoading: industryLoading,
    error: industryError,
  } = useFetchIndustryListQuery();
//   useCase
  const {
    data: useCaseData,
    isLoading: useCaseLoading,
    error: useCaseError,
  } = useFetchUseCaseListQuery();
//   tech
  const {
    data: techData,
    isLoading: techLoading,
    error: techError,
  } = useFetchMasterDataQuery({
    type:'tech'
  });
// solutionDetail
  const {
    data: solutionDetail,
    isError: solutionDetailError,
    isLoading: solutionDetailLoading,
    refetch,
  } = useFetchSolutionDetailQuery({
    solutionId: selectedSolutionId,
  });
// solutionNameDetail
  const {
    data: existingSolutionNameData,
    isError: existingSolutionNameError,
    isLoading: existingSolutionNameLoading,
    refetch: refetchExistingSolutionName,
  } = useFetchExistingSolutionNameQuery({
    solutionId: selectedSolutionId,
    solutionName: solutionName
  },
// {
//   skip: selectedSolutionId == "" || checkSolutionName === "" 
// }
);

useEffect(() => {
  if (existingSolutionNameData && existingSolutionNameData?.message && existingSolutionNameData?.statusCode !== 500 ) {
    dispatch(setSolutionNameError(existingSolutionNameData.message));
  }else{
    dispatch(setSolutionNameError(null));
  }
}, [existingSolutionNameData]);

  // const handleCheckSolutionName = (event)=>{
  // const inputValue = event.target.value;
  // // dispatch(setNewSolutionName(inputValue));
  // setCheckSolutionName(inputValue)
  // }



  useEffect(() => {
    if (solutionDetail && solutionDetail.data?.solution && !dataLoaded) {
      dispatch(setSolutionData(solutionDetail?.data?.solution));
    }
  }, [solutionDetail,dataLoaded, dispatch]);
  


// solution Logo
const handleFileChange = (e) => {
    const newFiles = e.target.files[0]; // Get the selected file
    if (!newFiles) return;
    dispatch(setLogo(newFiles)); 
    if (e.target.value !== "") {
      setErrors((prevErrors) => ({ ...prevErrors, logo: false }));
    }

    // Update the selectedLogo state
    const newSelectedAvatar = {
    id: shortid.generate(),
    filename: newFiles.name,
    filetype: newFiles.type,
    fileimage: URL.createObjectURL(newFiles),
    datetime: newFiles.lastModifiedDate.toLocaleString("en-IN"),
    filesize: filesizes(newFiles.size),
    
  };

  dispatch(setSelectedLogo([newSelectedAvatar]));
  };

  
const filesizes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };  

  const solutionLogoName = selectedLogo && selectedLogo.length > 0
  ? selectedLogo[0].filename  // Use fileimage to preview uploaded file
  : "Upload Solution Logo"; 

  const previewImage = selectedLogo && selectedLogo.length > 0
  ? selectedLogo[0].fileimage  // Use fileimage to preview uploaded file
  : s3Url + logo;  

  const priceRanges = [
    { value: 0, label: 'Undisclosed' },
    { value: 20, label: '$5000-$10000' },
    { value: 40, label: '$10000-$20000' },
    { value: 60, label: '$20000-$50000' },
    { value: 80, label: '$50000-$70000' },
    { value: 100, label: '$70000+' },
  ];
  const marks = priceRanges.map((range) => ({
    value: range.value,
    label: range.label,
  }));

//   solutionName
const handleSolutionName = (event)=>{
    const inputValue = event.target.value
    dispatch(setNewSolutionName(inputValue));
    dispatch(setCheckSolutionName(inputValue));
    if (event.target.value !== "") {
      setErrors((prevErrors) => ({ ...prevErrors, solutionName: false }));
    }
    if(solutionNameError === null){
      setErrors((prevErrors) => ({ ...prevErrors, solutionNameError: false }));
    }
}
//   solutionTagline
const handleSolutionTagline = (event)=>{
    const inputValue = event.target.value
    dispatch(setSolutionTagline(inputValue));
}
//   solutionWebUrl
const handleSolutionWebUrl = (event)=>{
    const inputValue = event.target.value
    dispatch(setSolutionWebUrl(inputValue));
    if (event.target.value !== "") {
      setErrors((prevErrors) => ({ ...prevErrors, solutionWebUrl: false }));
    }
}

//   HeadQuarter
 const handleHeadQuarter = (event)=>{
    const inputValue = event.target.value
    dispatch(setHeadquarter(inputValue));
    if (event.target.value !== "") {
      setErrors((prevErrors) => ({ ...prevErrors, headquarter: false }));
    }
 }

 // Industry
 const handleIndustry = (event) => {
   const inputValue = event.target.value;
   dispatch(setIndustry(inputValue));
   if (event.target.value !== "") {
    setErrors((prevErrors) => ({ ...prevErrors, industry: false }));
  }
 };
 const handleTech = (event) => {
   const inputValue = event.target.value;
   dispatch(setTech(inputValue));
   if (event.target.value !== "") {
    setErrors((prevErrors) => ({ ...prevErrors, tech: false }));
  }
 };
 // UseCase
 const handleUseCase = (event) => {
   const inputValue = event.target.value;
   dispatch(setUseCase(inputValue));
   if (event.target.value !== "") {
    setErrors((prevErrors) => ({ ...prevErrors, useCase: false }));
  }
 };
//  Prize Range
 const handleSliderChange = (event, newValue) => {
  const selectedRange = priceRanges.find((range) => range.value === newValue);
  if (selectedRange) {
    dispatch(setPriceRange(selectedRange.label)); // Store label in Redux
    dispatch(setPrizeValue(newValue));
  }
 };
 const SelectedPrizeValue = priceRanges.find((range)=> range.label === priceRange)


 const handleGoBack = () => {
  dispatch(resetProfile()); 
  navigate("/my-solutions");
  };

  useEffect(() => {
    if (solutionDetailLoading) {
      dispatch(showLoader());
    } else {
      dispatch(hideLoader());
    }
  }, [solutionDetailLoading, dispatch]);

  return (
    <>
      <Box
      sx={{
        width:'100%',
        height:'100%'
      }}
      >
        <Stack
          direction="row"
          sx={{
            py: 0,
            px: 0,
            borderBottom: "solid 1px #DBEBF8",
            alignItems: "center",
          }}
        >
          <IconButton
          onClick={handleGoBack}
            sx={{
              marginRight: "5px",
              display: "inline-flex",
              p: 0,
            }}
          >
            <KeyboardBackspaceIcon
              sx={{
                color: "lightSecondary.main",
                fontSize: "32px",
                [theme.breakpoints.down("md")]: {
                  fontSize: "20px",
                },
              }}
            />
          </IconButton>
          <Typography
            variant="body2"
            sx={{
              color: "secondary.main",
              fontSize: "1.4rem",
              fontWeight: "700",
              pr: 0.7,
              [theme.breakpoints.down("md")]: {
                fontSize: "1.1rem",
              },
            }}
          >
            Edit Solution
          </Typography>
          <Typography
            variant="body2"
            sx={{
              color: "#A2C2F1",
              fontSize: "1rem",
              fontWeight: "600",
              [theme.breakpoints.down("md")]: {
                fontSize: "1.1rem",
              },
            }}
          >
            (1/2)
          </Typography>
        </Stack>
      </Box>

      <Grid container mt={1} columnSpacing={2}
      sx={{
        // p:2,
        display:'flex',
        justifyContent:'center',
        [theme.breakpoints.up("xl")]: {
            // p: 10,
            mt:8
        },
      }}
      >
        <Grid item xs={12} md={11} lg={11}>
          <Card
            sx={{
              boxShadow: "none",
              borderRadius: "10px",
              mb: 3,
              width:'100%'
            }}
          >
            <CardContent
              sx={{
                p: 4,
                [theme.breakpoints.down("md")]: {
                  p: 2,
                },
              }}
              style={{
                paddingBottom: "0",
              }}
            >
              <Box
                component="form"
                sx={{
                  mt: 0,
                  mb: 0,
                  "& .MuiTextField-root": { m: 1, width: "25ch" },
                }}
                noValidate
                autoComplete="off"
              >
                <Grid
                  container
                  rowSpacing={3}
                  columnSpacing={5}
                  sx={{
                    mb: 1,
                    mt:1,
                  }}
                >
                  {/* Solution Logo */}
                  {/* <Grid item xs={12} md={6} lg={6}>
                    <Grid
                      container
                      rowSpacing={0}
                      columnSpacing={1}
                      sx={{
                        mb: 0,
                      }}
                    >
                      <Grid item xs={3} md={3} lg={3} xl={2}>
                      <Typography
                          noWrap
                          variant="body2"
                          sx={{
                            fontSize: "12px",
                            fontWeight: "500",
                            color: "ltheme.main",
                            width: "100%",
                            mb: 0.5,
                          }}
                        >
                          Solution Logo
                        </Typography>
                        <Box
                          sx={{
                            backgroundColor: "extraLTheme.main",
                            border: "solid 1px #DBEBF8",
                            borderRadius: "4px",
                            alignItems:'center',
                            width:'100%',
                            height:'44px',
                            display:'flex',
                            justifyContent:'center',
                            p: 0.4
                            // height:'100%',
                          }}
                        >
                          <img 
                          style={{
                            maxWidth:'100%',
                            maxHeight:'100%',
                            mixBlendMode:'multiply'
                          }}
                           src={previewImage} 
                           />
                        </Box>
                        
                      </Grid>
                      <Grid item xs={9} md={9} lg={9} xl={10}>
                        <Typography
                          noWrap
                          variant="body2"
                          sx={{
                            fontSize: "12px",
                            fontWeight: "600",
                            color: "secondary.main",
                            fontWeight:'bold',
                            width: "100%",
                            mb: 0.5,
                           visibility:'hidden'
                          }}
                        >
                          Solution Logo*
                        </Typography>
                        <FormControl
                          variant="filled"
                          sx={{
                            mb: 0,
                            width: "100%",
                            "& .MuiInputBase-root:hover:not(.Mui-disabled, .Mui-error):before":
                              {
                                border: "none",
                                background: "transparent",
                              },
                            "& .MuiInputBase-root:before": {
                              border: "none",
                            },
                            "& .MuiInputBase-root:after": {
                              border: "none",
                            },
                          }}
                        >
                          <Input
                            sx={{
                              width: "100%",
                              borderRadius: "4px",
                              backgroundColor: "extraLTheme.main",
                              border: "solid 1px #DBEBF8",
                              "& .MuiInputBase-input": {
                                fontSize: "0.9rem",
                                color: "lightSecondary.main",
                                fontWeight: "500",
                                p: 1.5,
                              },
                            }}
                            id="solution-logo-input"
                            type="text"
                            placeholder="Upload Solution Logo"
                            value={solutionLogoName} 
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="upload solution logo"
                                  sx={{
                                    mr: 1,
                                  }}
                                  onClick={() => document.getElementById('file-input').click()} 
                                >
                                  <CloudUploadIcon
                                    sx={{ color: "lightSecondary.main" }}
                                  />
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                          <input
                                id="file-input"
                                type="file"
                                style={{ display: 'none' }}  // Hidden input for file upload
                                accept="image/*"
                                onChange={handleFileChange}  // Handle file selection
                            />
                          {errors.logo && (
                          <Typography
                            variant="body2"
                            color = "error"
                            sx={{ fontSize: "12px",  mt: 0.5 }}
                          >
                            This field is required.
                          </Typography>
                        )}
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid> */}
                    <Grid container>
                    {/* Business Logo */}
                 <Grid item xs={2} sm={3} md={3} lg={2} xl={2}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    height: "100%", 
                    pl:3,
                    justifyContent: 'flex-start',
                    [theme.breakpoints.down("md")]: {
                      // justifyContent: 'center'
                    },
                  }}
                >
                  <AvatarWithUpload previewImage={previewImage} handleFileChange={handleFileChange}  />
                </Box>
                {errors.avatar && (
                          <Typography
                            variant="body2"
                            color = "error"
                            sx={{ fontSize: "12px",  mt: 0.5 }}
                          >
                            This field is required.
                          </Typography>
                        )}
              </Grid>
              <Grid
                  item
                  xs={12}
                  md={9}
                  lg={10}
                  xl={10}
                  sx={{
                    [theme.breakpoints.down("lg")]: {
                      // mt: 2,
                    },
                  }}
                >
                  <Grid
                      container
                      rowSpacing={2}
                      columnSpacing={{ xs: 2, sm: 2, xl: 2 }}
                      sx={{
                        // mb: 2,
                        // g: 2,
                        pl: 3,
                        // "@media (max-width:1600px) and (min-width:1200px)": {
                        //   flexBasis: "100%",
                        //   maxWidth: "100%",
                        // },
                        [theme.breakpoints.down("lg")]: {
                          pl: 0,
                        },
                      }}
                    >
                  {/* Solution Name */}
                  <Grid item xs={12} md={6} lg={6} xl={6} sx={{ mb: 1 }}>
                    <Typography
                      noWrap
                      variant="body2"
                      sx={{
                        fontSize: "12px",
                        fontWeight: "600",
                        color: "secondary.main",
                        fontWeight:'bold',
                        width: "100%",
                        mb: 0.5,
                      }}
                    >
                      Solution Name*
                    </Typography>
                    <TextField
                      hiddenLabel
                      id="filled-hidden-label-normal"
                      variant="filled"
                      value={solutionName}
                      style={{
                        margin: 0,
                        width: "100%",
                      }}
                      sx={{
                        width: "100%",
                        borderRadius: "4px",
                        backgroundColor: "extraLTheme.main",
                        border: "solid 1px #DBEBF8",
                        m: 0,
                        "& .MuiInputBase-root": {
                          backgroundColor: "extraLTheme.main",
                        },
                        "& .MuiInputBase-input": {
                          fontSize: "0.9rem",
                          color: "lightSecondary.main",
                          fontWeight: "600",
                          pt: 1.5,
                          pb: 1.5,
                          backgroundColor: "extraLTheme.main",
                        },
                        "& .MuiInputBase-root:hover:not(.Mui-disabled, .Mui-error):before":
                          {
                            border: "none",
                          },
                        "& .MuiInputBase-root:hover:before": {
                          border: "none",
                        },
                        "&:hover": {
                          borderColor: "extraLTheme.main",
                        },
                        "& .MuiInputBase-root:before": {
                          border: "none",
                        },
                        "& .MuiInputBase-root:after": {
                          border: "none",
                        },
                      }}
                      onChange={handleSolutionName}
                      // onBlur={handleCheckSolutionName}
                    />
                    {errors.solutionName && (
                    <Typography
                      variant="body2"
                      color= "error"
                      sx={{ fontSize: "12px",  mt: 0.5 }}
                    >
                      This field is required.
                    </Typography>
                  )}
                  {errors.solutionNameError && (
                      <Typography
                        variant="body2"
                        color="error"
                        sx={{ fontSize: "12px", mt: 0.5 }}
                      >
                        {solutionNameError}
                      </Typography>
                    )}
                  </Grid>
                   {/* Website url */}
                  <Grid item xs={12} md={6} lg={6} xl={6} sx={{ mb: 1 }}>
                    <Typography
                      noWrap
                      variant="body2"
                      sx={{
                        fontSize: "12px",
                        fontWeight: "600",
                        color: "secondary.main",
                        fontWeight:'bold',
                        width: "100%",
                        mb: 0.5,
                      }}
                    >
                      Website URL*
                    </Typography>
                    <TextField
                      hiddenLabel
                      id="filled-hidden-label-normal"
                      variant="filled"
                      value={solutionWebUrl}
                      style={{
                        margin: 0,
                        width: "100%",
                      }}
                      sx={{
                        width: "100%",
                        borderRadius: "4px",
                        backgroundColor: "extraLTheme.main",
                        border: "solid 1px #DBEBF8",
                        m: 0,
                        "& .MuiInputBase-root": {
                          backgroundColor: "extraLTheme.main",
                        },
                        "& .MuiInputBase-input": {
                          fontSize: "0.9rem",
                          color: "lightSecondary.main",
                          fontWeight: "600",
                          pt: 1.5,
                          pb: 1.5,
                          backgroundColor: "extraLTheme.main",
                        },
                        "& .MuiInputBase-root:hover:not(.Mui-disabled, .Mui-error):before":
                          {
                            border: "none",
                          },
                        "& .MuiInputBase-root:hover:before": {
                          border: "none",
                        },
                        "&:hover": {
                          borderColor: "extraLTheme.main",
                        },
                        "& .MuiInputBase-root:before": {
                          border: "none",
                        },
                        "& .MuiInputBase-root:after": {
                          border: "none",
                        },
                      }}
                      onChange={handleSolutionWebUrl}
                    />
                    {errors.solutionWebUrl && (
                    <Typography
                      variant="body2"
                      color = "error"
                      sx={{ fontSize: "12px",  mt: 0.5 }}
                    >
                      This field is required.
                    </Typography>
                  )}
                  </Grid>
                  {/* Solution Tagline */}
                  <Grid item xs={12} md={12} lg={12} xl={12} sx={{ mb: 1 }}>
                    <Typography
                      noWrap
                      variant="body2"
                      sx={{
                        fontSize: "12px",
                        fontWeight: "600",
                        color: "secondary.main",
                        fontWeight:'bold',
                        width: "100%",
                        mb: 0.5,
                      }}
                    >
                      Solution Tagline
                    </Typography>
                    <TextField
                      hiddenLabel
                      id="filled-hidden-label-normal"
                      variant="filled"
                      required
                      value={solutionTagline}
                      style={{
                        margin: 0,
                        width: "100%",
                      }}
                      sx={{
                        width: "100%",
                        borderRadius: "4px",
                        backgroundColor: "extraLTheme.main",
                        border: "solid 1px #DBEBF8",
                        m: 0,
                        "& .MuiInputBase-root": {
                          backgroundColor: "extraLTheme.main",
                        },
                        "& .MuiInputBase-input": {
                          fontSize: "0.9rem",
                          color: "lightSecondary.main",
                          fontWeight: "600",
                          pt: 1.5,
                          pb: 1.5,
                          backgroundColor: "extraLTheme.main",
                        },
                        "& .MuiInputBase-root:hover:not(.Mui-disabled, .Mui-error):before":
                          {
                            border: "none",
                          },
                        "& .MuiInputBase-root:hover:before": {
                          border: "none",
                        },
                        "&:hover": {
                          borderColor: "extraLTheme.main",
                        },
                        "& .MuiInputBase-root:before": {
                          border: "none",
                        },
                        "& .MuiInputBase-root:after": {
                          border: "none",
                        },
                      }}
                      onChange={handleSolutionTagline}
                    />
                  </Grid>
                  </Grid>
                  </Grid>
                  </Grid>
                   {/* HeadQuarter  */}
                 <Grid item xs={12} md={6} lg={6} xl={6}>
                 <Typography
                      noWrap
                      variant="body2"
                      sx={{
                        fontSize: "12px",
                        fontWeight: "600",
                        color: "secondary.main",
                        fontWeight:'bold',
                        width: "100%",
                        mb: 0.5,
                      }}
                    >
                      Headquarter*
                    </Typography>
                 <TextField
                  hiddenLabel
                  id="filled-hidden-label-normal"
                  variant="filled"
                  value={headquarter}
                  required
                  style={{
                    margin: 0,
                    width: "100%",
                  }}
                  sx={{
                    width: "100%",
                    borderRadius: "4px",
                    backgroundColor: "extraLTheme.main",
                    border: "solid 1px #DBEBF8",
                    m: 0,
                    "& .MuiInputBase-root": {
                      backgroundColor: "extraLTheme.main",
                    },
                    "& .MuiInputBase-input": {
                      fontSize: "0.9rem",
                      color: "lightSecondary.main",
                      fontWeight: "600",
                      pt: 1.5,
                      pb: 1.5,
                      backgroundColor: "extraLTheme.main",
                    },
                    "& .MuiInputBase-root:hover:not(.Mui-disabled, .Mui-error):before":
                      {
                        border: "none",
                      },
                    "& .MuiInputBase-root:hover:before": {
                      border: "none",
                    },
                    "&:hover": {
                      borderColor: "#DBEBF8",
                    },
                    "& .MuiInputBase-root:before": {
                      border: "none",
                    },
                    "& .MuiInputBase-root:after": {
                      border: "none",
                    },
                  }}
                  onChange={handleHeadQuarter}
                 />
                 {errors.headquarter && (
                    <Typography
                      variant="body2"
                      color = "error"
                      sx={{ fontSize: "12px",  mt: 0.5 }}
                    >
                      This field is required.
                    </Typography>
                  )}
                 </Grid>
                  {/* Tech */}
                  <Grid item xs={12} md={6} lg={6} xl={6}>
                <Typography
                  noWrap
                  variant="body2"
                  sx={{
                    fontSize: "12px",
                    fontWeight: "600",
                    color: "secondary.main",
                    fontWeight:'bold',
                    width: "100%",
                    mb: 0.5,
                  }}
                >
                  Tech*
                </Typography>
                <FormControl
                  fullWidth
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                  }}
                >
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    multiple
                    value={Array.isArray(tech) ? tech : []}
                    onChange={handleTech}
                    displayEmpty
                    IconComponent={KeyboardArrowDownOutlinedIcon}
                    sx={{
                      background: "#ECF6FF",
                      "& .MuiSelect-select": {
                        minHeight: "20px",
                        padding: "11px 14px",
                        color: "lightSecondary.main",
                        fontWeight: "600",
                        fontSize: "0.9rem",
                        border: "solid 1px #DBEBF8",
                        background: "#ECF6FF",
                      },
                      "& .MuiSelect-icon": {
                        color: "lightSecondary.main",
                      },
                    }}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          maxHeight: "300px",
                          overflowY: "auto",
                          "&::-webkit-scrollbar": {
                            width: "6px",
                            borderRadius: "4px",
                          },
                          "&::-webkit-scrollbar-track": {
                            backgroundColor: "transparent",
                            borderRadius: "4px",
                          },
                          "&::-webkit-scrollbar-thumb": {
                            backgroundColor: "#9C9C9C",
                            borderRadius: "4px",
                          },
                        },
                      },
                    }}
                  >
                    {techData && techData.map((tech) => (
                      <MenuItem 
                      key={tech._id} 
                      value={tech._id}
                      sx={{
                      //   "&.Mui-selected": {
                      //   backgroundColor: "lightSecondary.main", 
                      //   color: "#fff"
                      // },
                      // "&.Mui-selected:hover": {
                      //   backgroundColor: "lightSecondary.main",
                      //   color: "#fff"
                      // },
                      }}
                      >
                        {tech.title}
                      </MenuItem>
                    ))}      
                  </Select>
                </FormControl>
                {errors.tech && (
                  <Typography
                    variant="body2"
                    color = "error"
                    sx={{ fontSize: "12px",  mt: 0.5 }}
                  >
                    This field is required.
                  </Typography>
                )}
                  </Grid>
                  {/* Industry */}
                  <Grid item xs={12} md={6} lg={6} xl={6}>
                <Typography
                  noWrap
                  variant="body2"
                  sx={{
                    fontSize: "12px",
                    fontWeight: "600",
                    color: "secondary.main",
                    fontWeight:'bold',
                    width: "100%",
                    mb: 0.5,
                  }}
                >
                  Industry*
                </Typography>
                <FormControl
                  fullWidth
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                  }}
                >
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    multiple
                    value={Array.isArray(industry) ? industry : []}
                    onChange={handleIndustry}
                    displayEmpty
                    IconComponent={KeyboardArrowDownOutlinedIcon}
                    sx={{
                      background: "#ECF6FF",
                      "& .MuiSelect-select": {
                        minHeight: "20px",
                        padding: "11px 14px",
                        color: "lightSecondary.main",
                        fontWeight: "600",
                        fontSize: "0.9rem",
                        border: "solid 1px #DBEBF8",
                      },
                      "& .MuiSelect-icon": {
                        color: "lightSecondary.main",
                      },
                    }}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          maxHeight: "300px",
                          overflowY: "auto",
                          "&::-webkit-scrollbar": {
                            width: "6px",
                            borderRadius: "4px",
                          },
                          "&::-webkit-scrollbar-track": {
                            backgroundColor: "transparent",
                            borderRadius: "4px",
                          },
                          "&::-webkit-scrollbar-thumb": {
                            backgroundColor: "#9C9C9C",
                            borderRadius: "4px",
                          },
                        },
                      },
                    }}
                  >
                    <MenuItem disabled>
                      Select Industry
                    </MenuItem>
                    {industryData &&
                      industryData.data.map((industry) => (
                        <MenuItem key={industry._id} value={industry._id}>
                          {industry.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                {errors.industry && (
                    <Typography
                      variant="body2"
                      color = "error"
                      sx={{ fontSize: "12px",  mt: 0.5 }}
                    >
                      This field is required.
                    </Typography>
                  )}
                  </Grid>
                  {/* UseCase */}
                  <Grid item xs={12} md={6} lg={6} xl={6}>
                <Typography
                  noWrap
                  variant="body2"
                  sx={{
                    fontSize: "12px",
                    fontWeight: "600",
                    color: "secondary.main",
                    fontWeight:'bold',
                    width: "100%",
                    mb: 0.5,
                  }}
                >
                  Use Case*
                </Typography>
                <FormControl
                  fullWidth
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                  }}
                >
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    multiple
                    value={Array.isArray(useCase) ? useCase : []}
                    onChange={handleUseCase}
                    displayEmpty
                    IconComponent={KeyboardArrowDownOutlinedIcon}
                    sx={{
                      background: "#ECF6FF",
                      "& .MuiSelect-select": {
                        minHeight: "20px",
                        padding: "11px 14px",
                        color: "lightSecondary.main",
                        fontWeight: "600",
                        fontSize: "0.9rem",
                        border: "solid 1px #DBEBF8",
                      },
                      "& .MuiSelect-icon": {
                        color: "lightSecondary.main",
                      },
                    }}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          maxHeight: "300px",
                          overflowY: "auto",
                          "&::-webkit-scrollbar": {
                            width: "6px",
                            borderRadius: "4px",
                          },
                          "&::-webkit-scrollbar-track": {
                            backgroundColor: "transparent",
                            borderRadius: "4px",
                          },
                          "&::-webkit-scrollbar-thumb": {
                            backgroundColor: "#9C9C9C",
                            borderRadius: "4px",
                          },
                        },
                      },
                    }}
                  >

                    {Array.isArray(useCaseData?.data) &&
                        useCaseData?.data.length > 0 &&
                        useCaseData?.data.map((useCase) => (
                          <MenuItem key={useCase._id} value={useCase._id}>
                            {useCase.title}
                          </MenuItem>
                        ))}
                  </Select>
                </FormControl>
                {errors.useCase && (
                    <Typography
                      variant="body2"
                      color = "error"
                      sx={{ fontSize: "12px",  mt: 0.5 }}
                    >
                      This field is required.
                    </Typography>
                  )}
                  </Grid>

                  {/*Prize Range  */}
                  <Grid item xs={12} md={12} lg={12} xl={12}
                  sx={{
                    mt:2
                  }}
                  >
                  <Typography
                  noWrap
                  variant="body2"
                  sx={{
                    fontSize: "1.2rem",
                    fontWeight: "700",
                    color: "secondary.main",
                    width: "100%",
                    mb: 0.5,
                  }}
                >
                  Price Range ( In USD )
                </Typography>
                <Slider
                    defaultValue={0}
                    step={null}
                    marks={marks}
                    min={0}
                    max={100}
                    track={false}
                    value={prizeValue}
                    valueLabelDisplay="off"
                    onChangeCommitted={handleSliderChange}
                    sx={{
                        color: 'secondary.main', 
                        width: '90%', 
                        // pl:8,
                        ml:10,
                        mt:2,
                        fontWeight: "700",
                        '& .MuiSlider-thumb': {
                        backgroundColor: '#lightSecondary.main', // Valid thumb color
                        width: 20,
                        },
                        '& .MuiSlider-track': {
                        backgroundColor: '#lightSecondary.main', // Valid track color
                        },
                        "& .MuiSlider-mark": {
                          width: 10, // Increase mark size
                          height: 10,
                          borderRadius: "50%", // Make the mark round
                          backgroundColor: "secondary.main", // Base color of unselected marks
                          "&.MuiSlider-markActive": {
                            backgroundColor: "secondary.main", // Color when mark is selected
                            border: "2px solid secondary.main", // Highlight selected mark
                          },
                        },
                        '& .MuiSlider-markLabel': {
                          fontSize: '0.875rem',  // Customize mark label font size
                          color: 'secondary.main',  // Color matching typography
                          fontWeight:700,
                        },
                    }}
                    // sx={{
                    //     color: 'secondary.main',
                    //     width: '95%',  // Ensure the slider takes up full width
                    //     '& .MuiSlider-thumb': {
                    //       height: 20,  // Customize thumb size
                    //       width: 20,
                    //       backgroundColor: 'secondary.main', // Thumb color matching typography
                    //       border: '2px solid white',
                    //       '&:focus, &:hover, &.Mui-active': {
                    //         boxShadow: 'none',
                    //       },
                    //     },
                    //     '& .MuiSlider-track': {
                    //       height: 4,  // Customize track height
                    //       borderRadius: 2,
                    //     },
                    //     '& .MuiSlider-rail': {
                    //       height: 4,  // Customize rail height for the slider
                    //       borderRadius: 2,
                    //       opacity: 0.5,
                    //       backgroundColor: '#bfbfbf',
                    //     },
                    //     '& .MuiSlider-mark': {
                    //       color: 'secondary.main',  // Marks color matching typography
                    //       fontSize: '0.875rem',  // Adjust mark font size
                    //       top: 26,  // Adjust positioning to avoid overlapping
                    //     },
                    //     '& .MuiSlider-markLabel': {
                    //       fontSize: '0.875rem',  // Customize mark label font size
                    //       color: 'secondary.main',  // Color matching typography
                    //       top: 35,  // Ensure mark labels are positioned properly
                    //       transform: 'none',  // Prevent any unintended scaling
                    //     },
                    //   }}
                />
                  </Grid>
                   
                  {/* Next Button */}
                  <Grid
                    item
                    xs={12}
                    md={12}
                    lg={12}
                    sx={{
                      textAlign: "center",
                      mt: 4,
                      mb: 4,
                    }}
                  >
                    <Button
                      variant="contained"
                      onClick={handleNextStep}
                      sx={{
                        textTransform: "inherit",
                        p: "10px 20px",
                        boxShadow: "none",
                        fontWeight: "600",
                        width: "200px",
                      }}
                    >
                      Next
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default EditSolutionFirstStep;
