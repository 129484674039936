import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl = process.env.REACT_APP_base_URL;

const addGroupUsers = createApi({
  reducerPath: "addGroupUsers",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers) => {
      // Add any headers you need here
      return headers;
    },
  }),
  endpoints: (builder) => ({
    addUser: builder.mutation({
      query: ({ chatId, user_id }) => ({
        url: `chat/add`,
        method: "PUT",
        body: {
          chatId: chatId,
          userId: [user_id],
        },
      }),
      transformResponse: (response) => {
        return response;
      },
    }),
  }),
});

export const { useAddUserMutation } = addGroupUsers;
export default addGroupUsers;
