import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl = process.env.REACT_APP_base_URL;

const createGroup = createApi({
    reducerPath: 'createGroup',
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl,
        prepareHeaders: (headers) => {
            // Add any headers you need here
            return headers;
        },
    }),
    endpoints: (builder) => ({
        createGroup: builder.mutation({
            query: ({ userId ,groupName,solutionProviderId,businessUserId , solutionId}) => ({
                url:`chat/group`,
                method: 'POST',
                body: { 
                    userId:userId,
                    name: groupName, 
                    solution_provider_id:solutionProviderId, 
                    business_user_id: businessUserId,
                    solution_id: solutionId,
             },
            }),
            transformResponse: (response) => {
                return response;
            },
        }),
    }),
});

export const { useCreateGroupMutation } = createGroup;
export default createGroup;
