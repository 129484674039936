import {
    Box,
    Card,
    CardContent,
    Grid,
    IconButton,
    Skeleton,
    Stack,
    Typography,
  } from "@mui/material";
  import { useTheme } from "@emotion/react";
  
  const ManageTeamSkull = () => {
    const theme = useTheme();
    return (
      <>
        <Grid  item xs={12} md={12} lg={4} sm={12}>
          <Card
            sx={{
              boxShadow: "none",
              borderRadius: "10px",
              cursor: "pointer",
              backgroundColor: "#fff" // Adjust background color for skeleton
            }}
          >
            <CardContent
              sx={{
                p: 0,
                paddingBottom: "0",
                [theme.breakpoints.down("sm")]: {
                  position: "relative",
                },
              }}
            >
              <Stack
                direction="row"
                sx={{
                  // alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                  gap: 2,
                  px: 2,
                  py: 2,
                  minHeight: "100px",
                  [theme.breakpoints.down("sm")]: {
                    display: "block",
                  },
                }}
              >
                {/* Left side - Logo and Title */}
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 ,  }}>
                  <Skeleton sx={{mt:2}} variant="rectangular" width={50} height={60} />
                  <Box >
                    <Skeleton sx={{mt:1}} variant="text" width={180} height={20} />
                    <Skeleton sx={{mt:1}} variant="text" width={200} height={15} />
                    <Skeleton sx={{mt:1}} variant="text" width={80} height={15} />
                    {/* <Skeleton sx={{borderRadius: "30px", mt:2}} variant="rounded" width={230} height={20} /> */}
                  </Box>
                </Box>
  
                {/* Right side - Settings Icon */}
                {/* <Skeleton variant="circular" width={24} height={24} /> */}
              </Stack>
  
              {/* Bottom section - Button */}
              {/* <Box sx={{ px: 2, pb: 2 }}>
                <Skeleton variant="rectangular" width={150} height={36} />
              </Box> */}
            </CardContent>
          </Card>
        </Grid>
      </>
    );
  };
  
  export default ManageTeamSkull;
  