import InboxRequirementBoardDropdown from "./RequirementBoardDropdown";
import InboxSolutionsDropdown from "./RequirementSolutionsDropdown";

const TitleMap = (pathname, userType) => {
  const titles = {
    '/dashboard': 'Dashboard',
    '/bsi-co-lab': 'BSI Co-lab',
    '/bsi-co-lab-requirements': 'BSI Co-lab',
    '/bsi-co-lab/kanban': 'BSI Co-lab',
    '/invite-solution-provider': 'BSI Co-lab',
    '/manage-users': 'Manage Users',
    '/edit-team-members': 'Manage Team Members',
    '/inbox': userType === 'solution_provider' ? <InboxSolutionsDropdown /> : <InboxRequirementBoardDropdown />,
    '/bsi-drive': 'BSI Drive',
    '/bsi-sub-drive': 'BSI Drive',
    '/meetings': 'Meetings',
    '/profile': 'Profile',
    '/profile-business': 'Profile',
    '/my-solutions': 'My Solutions',
    '/edit-solution-details': 'My Solutions',
    '/solution-investment-summary': 'My Solutions',
    '/solution-enquiries': 'BSI Co-lab',
    '/kanban-solution-enquiries': 'BSI Co-lab',
    '/investment-enquiries': 'BSI Co-lab',
    '/kanban-investment-enquiries': 'BSI Co-lab',
    '/portfolio': 'Portfolio',
  };

  return titles[pathname] || '';
};

export default TitleMap;
