import React, { useRef, useState } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { useTheme } from "@emotion/react";
import { getImages } from "../../commonComponents/const";
import AllSolutionsList from "../../commonComponents/Inbox/AllSolutionsList";
import SolutionChatsList from "../../commonComponents/Inbox/SolutionChatsList";
import ChatContainer from "../../commonComponents/Inbox/ChatContainer";
import ChatProfileInfo from "../../commonComponents/Inbox/ChatProfileInfo";
import GroupChatContainer from "../../commonComponents/Inbox/GroupChatContainer";
import Modals from "../../commonComponents/Modals";
import InvitetoBSIColab from "../../commonComponents/Inbox/InvitetoBSIColab";
import NewChat from "../../commonComponents/Inbox/NewChat";
import CreateNewGroupChat from "../../commonComponents/Inbox/CreateNewGroupChat";
import ThreadChatContainer from "../../commonComponents/Inbox/ThreadChatContainer";
import ProfileMediaInfo from "../../commonComponents/Inbox/ProfileMediaInfo";
import ProfileDocuments from "../../commonComponents/Inbox/ProfileDocuments";
import { useDispatch, useSelector } from "react-redux";
import { io } from 'socket.io-client';
import { useEffect } from "react";
import {
  setOpenChat,
  toggleOpenChatInfo,
  toggleOpenSolutionList,
  toggleOpenProfileDocument,
  setChatSelected,
  setOpenGroupChat,
  toggleOpenInviteToBSIColab,
  toggleOpenNewChat,
  toggleThreadChat,
  toggleCreateGroup,
  toggleGroupSelected,
} from "../../redux/features/chatSlice";
import AllBusinessUserList from "../../commonComponents/Inbox/AllBusinessUserList";
import socket from "../../commonComponents/Inbox/socket";
import { setRefetchBusinessUserList, setRefetchSolutionList } from "../../redux/features/refetch";

const itemData = [
  {
    img: `${getImages("media1.jpg")}`,
  },
  {
    img: `${getImages("media2.jpg")}`,
  },
  {
    img: `${getImages("media1.jpg")}`,
  },
  {
    img: `${getImages("media2.jpg")}`,
  },
  {
    img: `${getImages("media1.jpg")}`,
  },
  {
    img: `${getImages("media1.jpg")}`,
  },
  {
    img: `${getImages("media2.jpg")}`,
  },
  {
    img: `${getImages("media1.jpg")}`,
  },
  {
    img: `${getImages("media2.jpg")}`,
  },
  {
    img: `${getImages("media1.jpg")}`,
  },
];
const Inbox = () => {

  const showToastMessage = (message, type) => {
    if (type === "success") {
      toast.success(message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (type === "error") {
      toast.error(message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (type === "info") {
      toast.warning(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
  const theme = useTheme();
  const userType = localStorage.getItem("userType");
  const userData = localStorage.getItem("userId");
  const [socketConnected, setSocketConnected] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [messages, setMessages] = useState([]);
  const [count, setCount] = useState({});
  const open = useSelector((state) => state.drawer.open);
  const dispatch = useDispatch();
  const {
    chatData,
    openChat,
    openChatInfo,
    openSolutionList,
    openProfileMedia,
    openProfileDocument,
    chatSelected,
    openGroupChat,
    openInviteToBSIColab,
    openNewChat,
    threadChat,
    createGroup,
    groupSelected,
    businessUserId,
    groupChatId,
  } = useSelector((state) => state.chat);

  useEffect(() => {
    if (!userData) return;

    // Connect socket only if it's disconnected
    if (!socket.connected) {
      socket.connect();
    }

    // On socket connection success
    socket.on("connect", () => {
      setIsLoading(false);
      setSocketConnected(true);
    });

    // On socket disconnect
    socket.on("disconnect", () => {
      setSocketConnected(false);
    });

    // Send the setup event with userData
    socket.emit("setup", userData);

    // Clean up the socket connection on component unmount
    return () => {
      socket.disconnect(); // Only disconnect when the component unmounts
    };
  }, [userData]);


  useEffect(() => {
    const countHandler = (count) => {
      setCount(count);
    };
    if(userType === "solution_provider"){
      dispatch(setRefetchBusinessUserList(true));
    }else{
      dispatch(setRefetchSolutionList(true));
    }
    socket.on("unread count updated", countHandler);
    // Clean up the listener when the component unmounts or on re-renders
    return () => {
      socket.off("unread count updated", countHandler); 
    };
  }, []);
  // Profile chat Info
  
  const handleSolutionList = () => {
    dispatch(toggleOpenSolutionList());
    if (openChatInfo) {
      dispatch(toggleOpenChatInfo());
    }
    dispatch(toggleGroupSelected(false))
  };

  const closeDocumentHandle = () => {
    dispatch(toggleOpenProfileDocument());
    dispatch(toggleOpenChatInfo());
  };

  // Group Chat and single Chat

  const groupChatHandle = () => {
    dispatch(setOpenGroupChat(true));
    dispatch(setChatSelected(false));
    dispatch(toggleOpenChatInfo());
    dispatch(toggleThreadChat());
    dispatch(toggleOpenSolutionList());
  };

  const chatSelectedHandle = () => {
    dispatch(setChatSelected(true));
    dispatch(setOpenGroupChat(false));
    dispatch(toggleOpenChatInfo());
    dispatch(toggleOpenSolutionList());
  };

  const closeSolutionChatList = () => {
    dispatch(setOpenChat(false));
  };

  // Invite to BSI Co-lab
  const inviteToBSIColabHandle = () => {
    dispatch(toggleOpenInviteToBSIColab());
  };

  const closeInviteToBSIColab = () => {
    dispatch(toggleOpenInviteToBSIColab());
  };

  // New Chat
  const newChatHandle = () => {
    dispatch(toggleOpenNewChat());
  };

  const closeNewChat = () => {
    dispatch(toggleOpenNewChat());
  };
  // Create Group
  const createGroupHandle = () => {
    dispatch(toggleCreateGroup());
    dispatch(toggleOpenNewChat());
    dispatch(toggleThreadChat());
  };

  const closeCreateGroup = () => {
    dispatch(toggleCreateGroup());
  };

  // Thread Chat
  const threadChatHandle = () => {
    dispatch(toggleThreadChat());
    dispatch(toggleOpenNewChat());
    dispatch(setOpenGroupChat(false));
  };

  const closeThreadChat = () => {
    dispatch(toggleThreadChat());
    dispatch(setOpenGroupChat(true));
  };

  return (
    <>
      <Box
        sx={{
          [theme.breakpoints.down("lg")]: {
            overflow: "hidden",
            position: "relative",
          },
        }}
      >
        <Grid
          container
          sx={{
            height:"100%",
            minHeight: "calc(100vh - 72px)",
            position: "relative",
            overflow: "hidden",
            [theme.breakpoints.down("md")]: {
              minHeight: "calc(100vh - 97px)",
            },
          }}
        >
          <Grid
            item
            xs={12} // Full width on extra-small screens
            md={12} // Full width on medium screens
            lg={open? 2.85 : 2.5} // Increased width on large screens (3/12 columns, or 25%)
            xl={open? 2.85 : 2.5} // Increased width on extra-large screens (3.5/12 columns, or 29.17%)
            sx={{
              backgroundColor: "#DBEBF8",
              pt: 2,
              pb: 0,
              pr: 0.7,
              pl: 2.2,
              // width:'18.8%',
              position: openSolutionList ? "absolute" : "absolute",
              zIndex: "2",
              boxShadow: "5px 10px 10px rgb(0 0 0 / 10%)",
              height:'100%',
              minHeight: "100%",
              left: openSolutionList ? "-21%" : "0%",
              transition: "all 0.3s ease-in-out 0s",
              minHeight: openSolutionList ? "100%" : "inherit",
              "@media (max-width:1535px) and (min-width:1200px)": {
                left: openSolutionList ? "-21%" : "0%",
                // maxWidth: open ? "28%" : "24.5%",
              },
              "@media (max-width:1420px) and (min-width:1200px)": {
               
                maxWidth: open ? "28%" : "23.5%",
              },
              "@media (max-width:1200px)": {
                left: openSolutionList ? "0%" : "0%",
                width: "100%",
              },
              
            }}
          >
            {userType === "solution_provider" ? (
              <AllBusinessUserList count={count} setCount={setCount} showToastMessage={showToastMessage}/>
            ) : (
              <AllSolutionsList count={count} setCount={setCount} showToastMessage={showToastMessage} />
            )}
          </Grid>

          {/* No Chat Section */}
          {groupChatId ? 
           (
            <Grid
              item
              xs={12}
              md={12}
              lg={openSolutionList ? 12 : 12}
              xl={openSolutionList ? 12 : 12}
              sx={{
                height:'100%',
                minHeight: "100%",
                p: 2,
                transition: "all 0.3s ease-in-out 0s",
                position: "relative",
                right: "0",
                pl: open ? "27%" : "24%",
                "@media (max-width:1420px) and (min-width:1200px)": {
                  pl:  open ? "28%" : "24%", // Fixed the syntax
                },
                "@media (max-width:1750px) and (min-width:1420px)": {
                  pl:  open ? "24%" : "22%", // Fixed the syntax
                },
                "@media (max-width:1910px) and (min-width:1750px)": {
                  pl:  open ? "23%" : "20%", // Fixed the syntax
                },
                "@media (max-width:2100px) and (min-width:1910px)": {
                  pl:  open ? "20%" : "18.3%", // Fixed the syntax
                },
                "@media (max-width:2250px) and (min-width:2100px)": {
                  pl:  open ? "18%" : "16.7%", // Fixed the syntax
                },
                "@media (max-width:2560px) and (min-width:2250px)": {
                  pl:  open ? "17%" : "16%", // Fixed the syntax
                },
                "@media (max-width:2860px) and (min-width:2560px)": {
                  pl:  open ? "15%" : "14%", // Fixed the syntax
                },
                "@media (max-width:3120px) and (min-width:2860px)": {
                  pl:  open ? "14%" : "13%", // Fixed the syntax
                },
                "@media (max-width:3420px) and (min-width:3120px)": {
                  pl:  open ? "12%" : "11%", // Fixed the syntax
                },
                [theme.breakpoints.down("lg")]: {
                  p: 0,
                },
              }}
            >
              <Grid
                container
                sx={{
                  height:"100%",
                  minHeight: "100%",
                  position: "relative",
                }}
              >
                <Button
                  type="button"
                  onClick={handleSolutionList}
                  sx={{
                    position: "absolute",
                    left: openSolutionList ? "-10px" : open? "15px" : "-15px",
                    top: "7px",
                    padding: "0",
                    minWidth: "inherit",
                    zIndex: "11",
                    // transform: openSolutionList
                    //   ? "rotate(0deg)"
                    //   : "rotate(180deg)",
                    transform:"rotate(180deg)",
                    transition: "all 0.3s ease-in-out 0s",
                    "&:hover": {
                      background: "transparent",
                    },
                    "@media (max-width:1535px) and (min-width:1200px)": {
                      left: openSolutionList ? "-15px" : open? "15px" : "-12px",
                      display:"none"
                    },
                    "@media (max-width:2560px) and (min-width:1535px)": {
                      display:"none"
                    },
                    "@media (min-width:2560px)": {
                      display:"none"
                    },
                    
                    "@media (max-width:1200px) and (min-width:700px) " : {
                    //   display: "none",
                    left: groupSelected === true  ? "8px" : open? "8px" : "8px",
                    top: groupSelected === true  ? "8px" : open? "8px" : "8px",
                    display: groupSelected === false ? "none" : ""
                    },
                    "@media (max-width:699px) and (min-width:320px) " : {
                    //   display: "none",
                    left: groupSelected === true  ? "8px" : open? "8px" : "8px",
                    top: groupSelected === true  ? "8px" : open? "8px" : "8px",
                    display: groupSelected === false ? "none" : "",
                    zIndex: open? "1" :"11",
                    },
                    
                  }}
                >
                  <img src={getImages("collaps-icon.png")} />
                </Button>
                {/* <Grid item xs={12} md={12} lg={3.6} xl={4}

                                    sx={{

                                        '@media (max-width:1280px) and (min-width:1200px)': {
                                            maxWidth: openSolutionList ? '30%' : '40%',
                                            flexBasis: openSolutionList ? '30%' : '40%'
                                        },
                                        [theme.breakpoints.down('lg')]: {
                                            position: 'absolute',
                                            right: openChat ? '0' : '-150%',
                                            top: '0',
                                            zIndex: '10',
                                            background: '#dbebf8',
                                            width: '100%',
                                            height: '100%'
                                        }
                                    }}
                                >
                                    <SolutionChatsList/>
                                </Grid> */}
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={
                    openChatInfo || openProfileMedia || openProfileDocument
                      ? 9
                      : 12
                  }
                  xl={
                    openChatInfo || openProfileMedia || openProfileDocument
                      ? 9
                      : 12
                  }
                  sx={{
                    height:'100%',
                    minHeight: "100%",
                    "@media (max-width:1535px) and (min-width:1200px)": {
                      maxWidth: openSolutionList
                        ? openChatInfo ||
                          openProfileMedia ||
                          openProfileDocument
                          ? "40%"
                          : "70%"
                        : "100%",
                      flexBasis: openSolutionList
                        ? openChatInfo ||
                          openProfileMedia ||
                          openProfileDocument
                          ? "40%"
                          : "70%"
                        : "100%",
                    },
                    [theme.breakpoints.down("lg")]: {
                      position: "relative",
                      right:  groupSelected === false  ? "100%" : "0%",
                      top: "0",
                      zIndex: "10",
                      background: "#dbebf8",
                      width: "100%",
                      height: "100%",
                      transition: "right 0.3s ease", 
                    },
                    [theme.breakpoints.down("md")]: {
                      position: "relative",
                      right:  groupSelected === false  ? "100%" : "0%",
                      top: "0",
                      zIndex: "10",
                      background: "#dbebf8",
                      width: "100%",
                      height: "100%",
                      transition: "right 0.3s ease", 
                    },
                    [theme.breakpoints.down("sm")]: {
                      position: "relative",
                      right:  groupSelected === false  ? "100%" : "0%",
                      top: "0",
                      zIndex: open ? "1": "10",
                      background: "#dbebf8",
                      width: "100%",
                      height: "100%",
                      transition: "right 0.3s ease", 
                    },
                  }}
                >
                  <Box
                    sx={{
                      background: "#fff",
                      height:'100%',
                      minHeight: "100%",
                      position: "relative",
                      borderRadius: "10px",
                      p: 1,
                      ml: open? "10px" :"0px",
                      [theme.breakpoints.down("lg")]: {
                        ml: open? "0px" :"0px",
                      },
                    }}
                  >
                    <GroupChatContainer threadChatHandle={threadChatHandle}/>
                    {/* {threadChat ?
                                            <ThreadChatContainer
                                                closeThreadChat={closeThreadChat}
                                            /> :
                                            chatSelected || openGroupChat ?
                                                openGroupChat ? 
                                                    <GroupChatContainer
                                                        threadChatHandle={threadChatHandle}
                                                    /> 
                                                    :
                                                    <ChatContainer
                                                    />
                                                :
                                                <Box
                                                    sx={{
                                                        position: "absolute",
                                                        left: "50%",
                                                        top: "50%",
                                                        transform: "translate(-50%, -50%)",
                                                        textAlign: "center"
                                                    }}
                                                >
                                                    <img
                                                        src={getImages('chat-icon.jpg')} alt='chat'
                                                    />
                                                    <Typography variant='h6'
                                                        sx={{
                                                            fontSize: "1.4rem",
                                                            fontWeight: "700",
                                                            color: "ltheme.main",
                                                            mt: 1
                                                        }}
                                                    >No chat selected</Typography>
                                                    <Typography variant='body2'
                                                        sx={{
                                                            fontSize: "1.1rem",
                                                            fontWeight: "500",
                                                            color: "ltheme.main"
                                                        }}
                                                    >Please select a chat from the list to see the conversation.</Typography>
                                                </Box>
                                        } */}
                  </Box>
                </Grid>
                {openChatInfo && (
                  <Grid
                    item
                    xs={12}
                    md={12}
                    lg={3}
                    xl={3}
                    sx={{
                      pl: 1.5,
                      minHeight: "100%",
                      "@media (max-width:1280px)": {
                        position: "absolute",
                        right: openChatInfo ? "0%" : "-150%",
                        top: "0",
                        zIndex: "10",
                        background: "#dbebf8",
                        width: "100%",
                        pl: 0,
                        minHeight: "calc(100% - 82px)",
                        transition: "all 0.5s ease-in-out 0s",
                      },
                      "@media (max-width:1280px) and (min-width:1200px)": {
                        boxShadow: "0 0 10px rgb(0 0 0 / 20%)",
                      },
                      "@media (max-width:1280px) and (min-width:1200px)": {
                        maxWidth: "30%",
                        flexBasis: "30%",
                      },
                    }}
                  >
                    <ChatProfileInfo itemData={itemData} />
                  </Grid>
                )}
                {openProfileMedia && (
                  <Grid
                    item
                    xs={12}
                    md={12}
                    lg={3}
                    xl={3}
                    sx={{
                      pl: 1.5,
                      minHeight: "100%",
                      "@media (max-width:1600px)": {
                        position: "absolute",
                        right: openProfileMedia ? "0" : "-150%",
                        top: "0",
                        zIndex: "10",
                        background: "#dbebf8",
                        width: "100%",
                      },
                      "@media (max-width:1280px) and (min-width:1200px)": {
                        maxWidth: "30%",
                        flexBasis: "30%",
                      },
                    }}
                  >
                    <ProfileMediaInfo itemData={itemData} />
                  </Grid>
                )}
                {openProfileDocument && (
                  <Grid
                    item
                    xs={12}
                    md={12}
                    lg={3}
                    xl={3}
                    sx={{
                      pl: 1.5,
                      minHeight: "100%",
                      "@media (max-width:1600px)": {
                        position: "absolute",
                        right: openProfileDocument ? "0" : "-150%",
                        top: "0",
                        zIndex: "10",
                        background: "#dbebf8",
                        width: "100%",
                      },
                      "@media (max-width:1280px) and (min-width:1200px)": {
                        maxWidth: "30%",
                        flexBasis: "30%",
                      },
                    }}
                  >
                    <ProfileDocuments
                      closeDocumentHandle={closeDocumentHandle}
                      itemData={itemData}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          )
          :
          (
            <Grid
            item
            xs={12}
            md={6}
            lg={openSolutionList ? 12 : 12}
            xl={openSolutionList ? 12 : 12}
            sx={{
              pl: openSolutionList ? "2" : "20%",
              "@media (max-width:1535px) and (min-width:1200px)": {
                pl: openSolutionList ? "2%" : "18%",
              },
              [theme.breakpoints.down("lg")]: {
                position: "absolute",
                right: "-150%",
                top: "0",
                zIndex: "10",
                background: "#dbebf8",
              },
            }}
          >
            <Box
              sx={{
                position: "relative",
                width: "100%",
                minHeight: "100%",
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  left: "50%",
                  top: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                <img src={getImages("no_inbox.png")} />
                <Typography
                  variant="body2"
                  sx={{
                    color: "ltheme.main",
                    fontSize: "1.3rem",
                    fontWeight: "500",
                    textAlign: "center",
                    transform: "rotate(-5deg) skew(10deg)",
                    pl: 12,
                  }}
                >
                  No chat selected
                </Typography>
              </Box>
            </Box>
          </Grid>
          )
          }
        </Grid>
      </Box>
      <Modals
        open={openInviteToBSIColab}
        handleClose={closeInviteToBSIColab}
        modalWidth={500}
      >
        <InvitetoBSIColab handleClose={closeInviteToBSIColab} />
      </Modals>
      <Modals open={openNewChat} handleClose={closeNewChat} modalWidth={500}>
        <NewChat
          handleClose={closeNewChat}
          createGroupHandle={createGroupHandle}
        />
      </Modals>
      <Modals
        open={createGroup}
        handleClose={closeCreateGroup}
        modalWidth={500}
      >
        <CreateNewGroupChat handleClose={closeCreateGroup} />
      </Modals>
    </>
  );
};

export default Inbox;
