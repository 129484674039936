import { createSlice } from '@reduxjs/toolkit';

const chatLoadingSlice = createSlice({
  name: 'chatLoadingSlice',
  initialState: {
    visible: false,
  },
  reducers: {
    showChatLoader: state => {
      state.visible = true;
    },
    hideChatLoader: state => {
      state.visible = false;
    },
  },
});

export const { showChatLoader ,  hideChatLoader} = chatLoadingSlice.actions;
export default chatLoadingSlice.reducer;