import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Box,
  Typography,
  styled,
  InputBase,
  IconButton,
  ListItemButton,
  Badge,
  List,
  Button,
  MenuItem,
  Menu,
  Paper,
  Divider,
  Avatar,
  debounce,
  Tooltip,
  Grid,
  ListItemAvatar,
  ListItemText,
  Stack,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";
import CancelIcon from "@mui/icons-material/Cancel";
import SearchIcon from "@mui/icons-material/Search";
import { UserAddOutlined } from "@ant-design/icons";
import { getImages } from "../const";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import { useDispatch, useSelector } from "react-redux";
import {
  getBusinessLogo,
  getBusinessName,
  getChatId,
  getGroupName,
  setBusinessUserId,
  setOpenChat,
  setOpenChatUserId,
  setSolutionId,
  setSolutionName,
  setSolutionProviderId,
  toggleChatData,
  toggleGroupSelected,
} from "../../redux/features/chatSlice";
import { toggleDrawer } from "../../redux/features/drawerSlice";
import { useFetchInboxSelectedSolutionListQuery } from "../../redux/api/Inbox/getInboxSelectedSolutionList";
import { hideLoader, showLoader } from "../../redux/features/loader";
import { toast } from "react-toastify";
import { useFetchInboxBusinessUserListQuery } from "../../redux/api/SolutionProvider/Inbox/getBusinessUserList";
import InvitetoBSIColab from "./InvitetoBSIColab";
import Modals from "../Modals";
import {
  resetRefetchBusinessUserList,
  setRefetchTeamMemberList,
} from "../../redux/features/refetch";
import { useLocation, useNavigate } from "react-router-dom";

const AllBusinessUserList = ({showToastMessage , count , setCount}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const userId = localStorage.getItem("userId");
  const selectedSolutionId = useSelector((state) => state.inbox.solutionId);
  const RedirectedSolutionId = useSelector((state) => state.chat.solutionId);
  const  groupChatId  = useSelector((state) => state.chat.groupChatId);

  const open = useSelector((state) => state.drawer.open);

  const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "#ECF6FF",
    "&:hover": {
      backgroundColor: "#ECF6FF",
    },
    marginRight: "0",
    marginLeft: 0,
    marginBottom: 5,
    width: "100%",
    display: "flex",
    alignItems: "center",
  }));
  const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    right: "0",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }));

  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "#106EED",
    width: "100%",
    "& .MuiInputBase-input": {
      padding: theme.spacing(1.7, 0, 1.7, 1.7),
      paddingRight: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create("width"),
      width: "100%",
      fontSize: "12px",
      fontWeight: "500",
    },
  }));

  // searching Logic
  const [searchParams, setSearchParams] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [filteredUsers, setFilteredUsers] = useState([]);
  const {
    data: businessUserList,
    error: businessUserListError,
    isLoading: businessUserListLoading,
    refetch,
  } = useFetchInboxBusinessUserListQuery({
    userId: userId,
    solutionId:  selectedSolutionId || RedirectedSolutionId,
  });


 const sortedBusinessUserList = [...businessUserList || []].sort((a, b) => {
  // Get timestamps from latestMessage or fallback to 0 for undefined messages
  const timeA = a?.latestMessage?.createdAt ? new Date(a.latestMessage.createdAt).getTime() : 0;
  const timeB = b?.latestMessage?.createdAt ? new Date(b.latestMessage.createdAt).getTime() : 0;

  // Sort by descending order (latest first)
  return timeB - timeA;
});
  

    // Find the index of the solution that matches the chatId
    const chatSolutionIndex = sortedBusinessUserList.findIndex(
      (user) => user?._id === count?.chatId
    );

    // If a matching solution is found, remove it from its original position and place it at the top
    if (chatSolutionIndex !== -1) {
      const [chatUsers] = sortedBusinessUserList.splice(chatSolutionIndex, 1);
      sortedBusinessUserList.unshift(chatUsers);
    }

  // set refetchBusinessUserList in reduxStore
   const refetchBusinessUserList = useSelector(
    (state) => state.refetch.refetchBusinessUserList
  );
  useEffect(() => {
    if (refetchBusinessUserList) {
      refetch().finally(() => {
        dispatch(resetRefetchBusinessUserList());
      });
    }
  }, [refetchBusinessUserList, refetch, dispatch]);

  const debouncedSearch = useCallback(
    debounce((query) => {
      if (businessUserList && query.length > 0) {
        const filtered = businessUserList.filter((user) =>
          user?.userProfile?.business_name
            .toLowerCase()
            .includes(query.toLowerCase())
        );
        setFilteredUsers(filtered);
      } else {
        setFilteredUsers(businessUserList || []);
      }
    }, 300),
    [businessUserList]
  );

  // Function to search business users based on the name
  const handleSearchBusinessUser = (event) => {
    if (event.key === "Enter") {
      debouncedSearch(searchParams);
    }
  };

  const handleInputChange = (event) => {
    const inputValue = event.target.value.trim();
    if (!inputValue) {
      setSearchParams("");
      setFilteredUsers([]);
      refetch();
      return;
    }
    setSearchParams(inputValue);
  };

  const handleClearSearch = () => {
    setSearchParams("");
    setFilteredUsers([]);
    refetch(); 
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openDw = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [anchorElStatus, setAnchorElStatus] = React.useState(null);
  const openStatus = Boolean(anchorElStatus);
  const handleStatusClick = (event) => {
    setAnchorElStatus(event.currentTarget);
  };
  const handleStatusClose = () => {
    setAnchorElStatus(null);
  };

  const [anchorElSolution, setAnchorElSolution] = React.useState(null);
  const openUser = Boolean(anchorElSolution);
  const handleBusinessUserClick = (event) => {
    setAnchorElSolution(event.currentTarget);
  };
  const handleBusinessUserClose = () => {
    setAnchorElSolution(null);
  };

  // InviteBsiCoLab Modal
  const [openInviteBsiCoLab, setOpenInviteBsiCoLab] = useState(false);
  const InviteBsiCoLabHandle = () => {
    setOpenInviteBsiCoLab(true);
  };
  const closeInviteBsiCoLab = () => {
    setOpenInviteBsiCoLab(false);
  };

  const { openChat, openSolutionList, chatData, solutionProviderId } =
    useSelector((state) => state.chat);
  const chatOpenHandle = (
    buttonIndex,
    solutionProviderId,
    solutionId,
    businessUserId,
    solutionName,
    businessName,
    businessLogo,
    chatId
  ) => {
    dispatch(setSolutionProviderId(solutionProviderId));
    dispatch(setSolutionId(solutionId));
    dispatch(setBusinessUserId(businessUserId));
    dispatch(setSolutionName(solutionName));
    dispatch(getBusinessName(businessName));
    dispatch(getGroupName(businessName));
    dispatch(getBusinessLogo(businessLogo));
    dispatch(getChatId(chatId));
    dispatch(toggleGroupSelected(true));
    if (open) {
      dispatch(toggleDrawer());
    }
    if(count){
    setCount((prevCount) => {
      if (prevCount.chatId === groupChatId) {
        return {
          ...prevCount,
          chatId: prevCount.chatId,  
          latestMessage: prevCount.latestMessage,
          unreadMessageCount: 0  // Set unreadMessageCount to 0
        };
      }
    });
    refetch();
  }
}

  const refetchTeamMemberList = () => {
    dispatch(setRefetchTeamMemberList(true));
  };

  const formatMessageDate = (createdAt) => {
    const now = new Date();
    const messageDate = new Date(createdAt);
  
    const isToday = now.toDateString() === messageDate.toDateString();
  
    // Check if the message was sent yesterday
    const yesterday = new Date(now);
    yesterday.setDate(now.getDate() - 1);
    const isYesterday = yesterday.toDateString() === messageDate.toDateString();
  
    const timeDifferenceInDays = (now - messageDate) / (1000 * 60 * 60 * 24);
  
    // Check if the message is within the last 7 days (excluding today and yesterday)
    const isWithinLast7Days = timeDifferenceInDays > 1 && timeDifferenceInDays <= 7;
  
    if (isToday) {
      // Return the time if the message is from today
      return messageDate.toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });
    } else if (isYesterday) {
      // Return "Yesterday" if the message is from yesterday
      return "Yesterday";
    } else if (isWithinLast7Days) {
      // Return the day if the message is from the last 7 days (excluding today and yesterday)
      return messageDate.toLocaleDateString("en-US", { weekday: "long" });
    } else {
      // Return the full date in MM/DD/YYYY format if the message is older than 7 days
      return messageDate.toLocaleDateString("en-US", {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      });
    }
  };
  

  useEffect(() => {
    if (businessUserListLoading ) {
      dispatch(showLoader());
    } else {
      dispatch(hideLoader());
    }
  }, [businessUserListLoading, dispatch ]);

  return (
    <Box
      sx={{
        minWidth: "213px",
        "@media(min-width:2560px)" :{
          minWidth: "313px",
        },
        "@media(min-width:1460px)" :{
          minWidth: "313px",
        },
        // "@media (max-width:1910px) and (min-width: 1901px)": {
        //   minWidth: open ? "344px" :"324px",
        // },
        // "@media (max-width:1900px) and (min-width: 1660px)": {
        //   minWidth: open ? "341px" :"316px",
        // },
        // "@media (max-width:1660px) and (min-width: 1540px)": {
        //   minWidth: open ? "300px" :"312px",
        // },
        "@media (max-width:1540px) and (min-width: 1310px)": {
          minWidth:"280px",
        },
        
        "@media (max-width:1250px) and (min-width: 1200px)": {
          minWidth: "250px",
        },
        "@media (max-width:1310px) and (min-width: 1250px)": {
          minWidth: "260px",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          [theme.breakpoints.down("md")]: {
            flexWrap: "wrap",
          },
        }}
      >
        <Button
          variant="none"
          sx={{
            textTransform: "inherit",
            p: "5px 5px 5px 5px",
            color: "primary.main",
            fontWeight: "600",
            "&:hover": {
              background: "transparent",
            },
            "@media (max-width:1600px) and (min-width: 1300px)": {
              fontSize: "0.9rem",
            },
            [theme.breakpoints.down("xl")]: {
              fontSize: "0.765rem",
            },
          }}
          onClick={InviteBsiCoLabHandle}
        >
          <UserAddOutlined
            style={{
              width: "15px",
              height: "20px",
              position: "relative",
            }}
          />
          Invite to BSI Co-lab
        </Button>
        <Typography
          variant="h6"
          sx={{
            display: "flex",
            alignItems: "center",
            color: "secondary.main",
            fontSize: "0.9rem",
            fontWeight: "700",
            pr: 1.5,
            visibility: "hidden",
          }}
        >
           Business Chats
          </Typography>
      </Box>
      <Box
        sx={{
          mb: 1.5,
          pl: 0,
          pr: 1.5,
        }}
      >
        <Search>
          <StyledInputBase
            placeholder="Search by Business Name"
            inputProps={{ "aria-label": "search" }}
            value={searchParams}
            onChange={handleInputChange}
            onKeyDown={handleSearchBusinessUser}
            autoFocus
          />
          {searchParams ? (
            <IconButton
              onClick={handleClearSearch}
              sx={{ position: "absolute", right: 0 }}
            >
              <CancelIcon sx={{ color: "lightSecondary.main" }} />
            </IconButton>
          ) : (
            <SearchIconWrapper sx={{ position: "absolute", right: 0 }}>
              <SearchIcon sx={{ color: "lightSecondary.main" }} />
            </SearchIconWrapper>
          )}
        </Search>
      </Box>

      <List
        component="nav"
        sx={{
          px: 0,
          py: 0,
          pr: 1.2,
          "& .MuiListItemButton-root": {
            py: 1.5,
          },
          "& .MuiButtonBase-root + .MuiButtonBase-root": {
            marginTop: "10px",
          },
          overflowY: "auto",
          maxHeight: "calc(100vh - 214px)",
          "&::-webkit-scrollbar": {
            width: "6px",
            borderRadius: "4px",
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "transparent",
            borderRadius: "4px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#9C9C9C",
            borderRadius: "4px",
          },
          [theme.breakpoints.down("lg")]: {
            maxHeight: "calc(100vh - 170px)",
          },
        }}
      >
        {/* <Grid
          mt={0.5}
          container
          rowSpacing={2}
          columnSpacing={2}
          sx={{
            mb: 0,
            "& .MuiButtonBase-root + .MuiButtonBase-root": {
              marginTop: "10px",
            },
            pl: 0,
            pr: 0.9,
            overflowY: openSolutionList ? "hidden" : "auto",
            maxHeight: "calc(100vh - 192px)",
            "&::-webkit-scrollbar": {
              width: "6px",
              borderRadius: "4px",
              display: "block",
              position: "absolute",
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "transparent",
              borderRadius: "4px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#9C9C9C",
              borderRadius: "4px",
            },
            [theme.breakpoints.down("lg")]: {
              maxHeight: "calc(100vh - 192px)",
            },
          }}
        > */}
        {filteredUsers.length > 0
          ? filteredUsers.map((user, index) => (
              <ListItemButton
                key={user._id}
                index={user._id}
                onClick={() =>
                  chatOpenHandle(
                    index,
                    user?.solution_provider_id?._id,
                    user?.solution_id,
                    user?.user_id?._id,
                    user?.solution_name,
                    // user?.solution_name,
                    user?.userProfile?.business_name,
                    user?.userProfile?.business_logo,
                    user?._id
                  )
                }
                sx={{
                  // px: 0.5,
                  p:"10px 10px 10px 10px",
                  borderRadius: "10px",
                  background: groupChatId === user?._id ?  "#ECF6FF" : "#d0e4f3" ,
                  border: groupChatId === user?._id ? 'solid 1px #106EED' : '',
                  "&:hover": {
                    background:
                      groupChatId === user?._id ? "#ECF6FF" : "#d0e4f3" ,
                      border: groupChatId === user?._id ? 'solid 1px #106EED' : '',
                  },
                }}
              >
                {/* Avatar Section */}
                <ListItemAvatar
                  sx={{
                    width: "56px",
                  }}
                >
                  {/* <Badge
                                color="secondary"
                                overlap="circular"
                                badgeContent=" "
                                variant="dot"
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                sx={{
                                    '& .MuiBadge-badge': {
                                        backgroundColor: 'green.main',
                                        minWidth: '14px',
                                        height: '14px',
                                        borderRadius: '50%',
                                        border: 'solid 2px #ECF6FF'
                                    }
                                }}
                            > */}
                  {user?.userProfile?.business_logo ? (
                    <Avatar
                      src={user?.userProfile?.business_logo}
                      alt={`${user?.userProfile?.business_name || ""} logo`}
                      sx={{
                        color: "success.main",
                        bgcolor: "success.lighter",
                        width: "50px",
                        height: "50px",
                      }}
                    />
                  ) : (
                    <Avatar
                      sx={{
                        color: "#fff",
                        bgcolor: "lightSecondary.main",
                        width: "50px",
                        height: "50px",
                      }}
                    >
                      {user?.userProfile?.business_name
                        ? user.userProfile?.business_name
                            .slice(0, 2)
                            .toUpperCase()
                        : ""}
                    </Avatar>
                  )}
                  {/* </Badge> */}
                </ListItemAvatar>

                {/* <Box
                  sx={{
                    backgroundColor: "extraLTheme.main",
                    borderRadius: "50%",
                    overflow: "hidden",
                    maxWidth: "20%",
                    width: "52px",
                    height: "52px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginRight: "12px",
                  }}
                >
                  {user?.userProfile?.business_logo ? (
                    <Avatar
                      src={user?.userProfile?.business_logo}
                      alt={`${user?.userProfile?.business_name || ""} logo`}
                      sx={{
                        width: "100%",
                        height: "100%",
                      }}
                    />
                  ) : (
                    <Avatar
                      sx={{
                        width: "100%",
                        height: "100%",
                        backgroundColor: "lightSecondary.main",
                        backgroundColor: "lightSecondary.main", 
                        fontSize: "1.2rem",
                        color: businessUserId === user?.user_id?._id ? "#fff" : "#fff",
                      }}
                    >
                      {user?.userProfile?.business_name ? (
                        user.userProfile?.business_name.slice(0, 2).toUpperCase()
                      ) : ""}
                    </Avatar>
                  )}
                </Box> */}
                <ListItemText
                  sx={{
                    pl: "10px",
                    pb: "10px",
                    width: "60%",
                    minWidth:'60%',
                  }}
                  primary={
                    <Typography
                      noWrap
                      sx={{
                        fontSize: "1rem",
                        fontWeight: "600",
                        color: "secondary.main",
                        lineHeight: "22px",
                        maxWidth: "90%",
                        [theme.breakpoints.down("sm")]: {
                          maxWidth: "100%",
                        },
                      }}
                      variant="h6"
                    >
                      <Tooltip
                        title={
                          user?.userProfile?.business_name
                            ? `${user.userProfile.business_name
                                .charAt(0)
                                .toUpperCase()}${user.userProfile.business_name.slice(
                                1
                              )}`
                            : ""
                        }
                      >
                        {user?.userProfile?.business_name
                          ? `${user.userProfile.business_name
                              .charAt(0)
                              .toUpperCase()}${user.userProfile.business_name.slice(
                              1
                            )}`
                          : ""}
                      </Tooltip>
                    </Typography>
                  }
                  secondary={
                    <Typography
                      noWrap
                      variant="body2"
                      sx={{
                        fontSize: "12px",
                        fontWeight: "500",
                        color: "primary.main",
                        maxWidth: "100%",
                        visibility: "hidden"
                      }}
                    >
                      {user?.latestMessage?.message}
                    </Typography>
                  }
                />
                <ListItemText
                  sx={{
                    width: "calc(40% - 56px)",
                  }}
                >
                  <Stack alignItems="flex-end">
                  {
                    count?.chatId === user?._id && count?.latestMessage?.createdAt ?
                    (
                        <Typography
                        variant="body2"
                        noWrap
                        sx={{
                          color: "ltheme.main",
                          fontSize: "12px",
                          fontWeight: "500",
                          mb: "5px",
                          visibility: count?.latestMessage?.createdAt
                            ? "visible"
                            : "hidden",
                        }}
                      >
                        {count?.latestMessage?.createdAt
                          ? isNaN(new Date(count?.latestMessage.createdAt))
                            ? "Invalid Date"
                            : formatMessageDate(count?.latestMessage.createdAt)
                          : ""}
                      </Typography>
                    )
                    :
                    (
                        <Typography
                        variant="body2"
                        noWrap
                        sx={{
                          color: "ltheme.main",
                          fontSize: "12px",
                          fontWeight: "500",
                          mb: "5px",
                          visibility: user?.latestMessage?.createdAt
                            ? "visible"
                            : "hidden",
                        }}
                      >
                         {user?.latestMessage?.createdAt
                        ? isNaN(new Date(user?.latestMessage?.createdAt))
                          ? "Invalid Date"
                          : formatMessageDate(user?.latestMessage?.createdAt)
                        : ""}
                      </Typography>
                    )
                }
                    {
                    count?.chatId === user?._id && count?.unreadMessageCount > 0 ?
                    (
                        <>
                         <Box
                      component="span"
                      sx={{
                        bgcolor: "primary.main",
                        width: 24,
                        height: 24,
                        borderRadius: "50%",
                        fontSize: "12px",
                        color: "#fff",
                        textAlign: "center",
                        lineHeight: "24px",
                        visibility: user?._id === groupChatId || count?.unreadMessageCount == 0 ? "hidden" : "visible"
                      }}
                    >

                        {count.unreadMessageCount}
                    </Box>
                        </>
                    ):
                    (
                        <>
                         <Box
                      component="span"
                      sx={{
                        bgcolor: "primary.main",
                        width: 24,
                        height: 24,
                        borderRadius: "50%",
                        fontSize: "12px",
                        color: "#fff",
                        textAlign: "center",
                        lineHeight: "24px",
                        visibility: user?._id === groupChatId || user?.unreadMessageCount == 0 ? "hidden" : "visible"
                      }}
                    >
                      {user?.unreadMessageCount}
                    </Box>
                        </>
                    )
                }
                  </Stack>
                </ListItemText>
                {/* <Box
                  sx={{
                    display: "flex",
                    width:"70%",
                    maxWidth: "80%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width:'80%',
                      maxWidth: "100%",
                      justifyContent:'flex-start'
                    }}
                  >
                    <Typography
                    noWrap
                      variant="subtitle1"
                      sx={{
                        fontSize: "0.9rem",
                        fontWeight: 600,
                        color: businessUserId === user?.user_id?._id ? "black" : "black",
                        maxWidth: "80%",
                      }}
                    >
                     <Tooltip 
                     title=
                     {user?.userProfile?.business_name ? 
                      `${user.userProfile.business_name.charAt(0).toUpperCase()}${user.userProfile.business_name.slice(1)}` 
                      : ""
                    }
                     >
                      {user?.userProfile?.business_name ? 
                        `${user.userProfile.business_name.charAt(0).toUpperCase()}${user.userProfile.business_name.slice(1)}` 
                        : ""
                      }
                    </Tooltip>
                    </Typography>
                    <Typography
                        noWrap
                        variant="body2"
                        sx={{
                          fontSize: "0.8rem",
                          color: businessUserId === user?.user_id?._id ? "primary.main" : "ltheme.main",
                          maxWidth:'80%'
                        }}
                      >
                        {user?.latestMessage?.message}
                      </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      width:'10%',
                      maxWidth: "30%",
                      justifyContent:'flex-end'
                    }}
                  >
                    <Typography
                      variant="caption"
                      sx={{
                        fontSize: "0.75rem",
                        color: "grey.main",
                        marginTop: "2px",
                        // maxWidth: "100%",
                      }}
                    >
                      {user?.latestMessage?.createdAt ? (
                        isNaN(new Date(user.latestMessage.createdAt)) ? (
                          "Invalid Date"
                        ) : (
                          new Date(user.latestMessage.createdAt).toLocaleTimeString("en-US", {
                            hour: "numeric",
                            minute: "numeric",
                            hour12: true,
                          })
                        )
                      ) : (
                        ""
                      )}
                    </Typography>
                  </Box>
                </Box> */}
              </ListItemButton>
            ))
          : sortedBusinessUserList?.map((user, index) => (
              <ListItemButton
                key={user._id}
                index={user._id}
                onClick={() =>
                  chatOpenHandle(
                    index,
                    user?.solution_provider_id?._id,
                    user?.solution_id,
                    user?.user_id?._id,
                    user?.solution_name,
                    user?.userProfile?.business_name,
                    user?.userProfile?.business_logo,
                    user?._id
                  )
                }
                sx={{
                  // px: 0.5,
                  p:"10px 10px 10px 10px",
                  borderRadius: "10px",
                  background: groupChatId === user?._id ? "#ECF6FF" : "#d0e4f3" ,
                  border: groupChatId === user?._id ? 'solid 1px #106EED' : '',
                  "&:hover": {
                    background:
                      groupChatId === user?._id ? "#ECF6FF" : "#d0e4f3" ,
                      border: groupChatId === user?._id ? 'solid 1px #106EED' : '',
                  },
                }}
              >
                {/* Avatar Section */}
                <ListItemAvatar
                  sx={{
                    width: "56px",
                  }}
                >
                  {user?.userProfile?.business_logo ? (
                    <Avatar
                      src={user?.userProfile?.business_logo}
                      alt={`${user?.userProfile?.business_name || ""} logo`}
                      sx={{
                        // color: "success.main",
                        bgcolor: "success.lighter",
                        width: "50px",
                        height: "50px",
                      }}
                    />
                  ) : (
                    <Avatar
                      sx={{
                        color: "#fff",
                        bgcolor: "lightSecondary.main",
                        width: "50px",
                        height: "50px",
                      }}
                    >
                      {user?.userProfile?.business_name
                        ? user.userProfile?.business_name
                            .slice(0, 2)
                            .toUpperCase()
                        : ""}
                    </Avatar>
                  )}
                  {/* </Badge> */}
                </ListItemAvatar>
                <ListItemText
                  sx={{
                    pl: "10px",
                    pb: "10px",
                    width: "60%",
                    minWidth:'60%',
                  }}
                  primary={
                    <Typography
                      noWrap
                      sx={{
                        fontSize: "1rem",
                        fontWeight: "600",
                        color: "secondary.main",
                        lineHeight: "22px",
                        maxWidth: "90%",
                        [theme.breakpoints.down("sm")]: {
                          maxWidth: "100%",
                        },
                      }}
                      variant="h6"
                    >
                      <Tooltip
                        title={
                          user?.userProfile?.business_name
                            ? `${user.userProfile.business_name
                                .charAt(0)
                                .toUpperCase()}${user.userProfile.business_name.slice(
                                1
                              )}`
                            : ""
                        }
                      >
                        {user?.userProfile?.business_name
                          ? `${user.userProfile.business_name
                              .charAt(0)
                              .toUpperCase()}${user.userProfile.business_name.slice(
                              1
                            )}`
                          : ""}
                      </Tooltip>
                    </Typography>
                  }
                  secondary={
                    <Typography
                      noWrap
                      variant="body2"
                      sx={{
                        fontSize: "12px",
                        fontWeight: "500",
                        color: "primary.main",
                        maxWidth: "100%",
                        // visibility: user?.latestMessage?.message
                        //   ? "visible"
                        //   : "hidden",
                        visibility:"hidden",
                        [theme.breakpoints.down("sm")]: {
                          maxWidth: "100%",
                        },
                      }}
                    >
                      {user?.latestMessage?.message}
                    </Typography>
                  }
                />
                <ListItemText
                  sx={{
                    width: "calc(40% - 56px)",
                  }}
                >
                  <Stack alignItems="flex-end">                 
                    {
                    count?.chatId === user?._id && count?.latestMessage?.createdAt ?
                    (
                        <Typography
                        variant="body2"
                        noWrap
                        sx={{
                          color: "ltheme.main",
                          fontSize: "12px",
                          fontWeight: "500",
                          mb: "5px",
                          visibility: count?.latestMessage?.createdAt
                            ? "visible"
                            : "hidden",
                        }}
                      >
                        {count?.latestMessage?.createdAt
                          ? isNaN(new Date(count?.latestMessage.createdAt))
                            ? "Invalid Date"
                            : formatMessageDate(count?.latestMessage.createdAt)
                          : ""}
                      </Typography>
                    )
                    :
                    (
                        <Typography
                        variant="body2"
                        noWrap
                        sx={{
                          color: "ltheme.main",
                          fontSize: "12px",
                          fontWeight: "500",
                          mb: "5px",
                          visibility: user?.latestMessage?.createdAt
                            ? "visible"
                            : "hidden",
                        }}
                      >
                         {user?.latestMessage?.createdAt
                        ? isNaN(new Date(user?.latestMessage?.createdAt))
                          ? "Invalid Date"
                          : formatMessageDate(user?.latestMessage?.createdAt)
                        : ""}
                      </Typography>
                    )
                }
                    {
                    count?.chatId === user?._id && count?.unreadMessageCount > 0 ?
                    (
                        <>
                         <Box
                      component="span"
                      sx={{
                        bgcolor: "primary.main",
                        width: 24,
                        height: 24,
                        borderRadius: "50%",
                        fontSize: "12px",
                        color: "#fff",
                        textAlign: "center",
                        lineHeight: "24px",
                        visibility: user?._id === groupChatId ||   count?.unreadMessageCount == 0 ? "hidden" : "visible"
                      }}
                    >

                        {count.unreadMessageCount}
                    </Box>
                        </>
                    ):
                    (
                        <>
                         <Box
                      component="span"
                      sx={{
                        bgcolor: "primary.main",
                        width: 24,
                        height: 24,
                        borderRadius: "50%",
                        fontSize: "12px",
                        color: "#fff",
                        textAlign: "center",
                        lineHeight: "24px",
                        visibility:  user?._id === groupChatId || user?.unreadMessageCount == 0 ? "hidden" : "visible"
                      }}
                    >
                      {user?.unreadMessageCount}
                    </Box>
                        </>
                    )
                }
               
                  </Stack>
                </ListItemText>
                
              </ListItemButton>
            ))}
      </List>
      {/* </Grid> */}
      {/* </Grid> */}
      <Modals
        open={openInviteBsiCoLab}
        handleClose={closeInviteBsiCoLab}
        modalWidth={500}
      >
        <InvitetoBSIColab
          showToastMessage={showToastMessage}
          handleClose={closeInviteBsiCoLab}
          refetchTeamMemberList={refetchTeamMemberList}
        />
      </Modals>
    </Box>
  );
};

export default AllBusinessUserList;
