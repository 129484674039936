import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl = process.env.REACT_APP_base_URL;

const removeGroupUsers = createApi({
  reducerPath: "removeGroupUsers",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers) => {
      // Add any headers you need here
      return headers;
    },
  }),
  endpoints: (builder) => ({
    removeUser: builder.mutation({
      query: ({ chatId, user_id }) => ({
        url: `chat/remove`,
        method: "DELETE",
        body: {
          chatId: chatId,
          userId: [user_id],
        },
      }),
      transformResponse: (response) => {
        return response;
      },
    }),
  }),
});

export const { useRemoveUserMutation } = removeGroupUsers;
export default removeGroupUsers;
