import React, { useState } from 'react';
import { Typography, Button, Box } from '@mui/material';
import { useTheme } from '@emotion/react';
import { useDispatch } from 'react-redux';
import { UserAddOutlined } from "@ant-design/icons";
import InvitetoBSIColab from '../Inbox/InvitetoBSIColab';
import { setRefetchTeamMemberList } from '../../redux/features/refetch';
import Modals from '../Modals';

const NoTeamMemberScreen = () => {
    const theme = useTheme();
    const dispatch = useDispatch();

    const showToastMessage = (message, type) => {
        if (type === "success") {
          toast.success(message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else if (type === "error") {
          toast.error(message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else if (type === "info") {
          toast.warning(message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      };

      // InviteBsiCoLab Modal
    const [openInviteBsiCoLab, setOpenInviteBsiCoLab] = useState(false);
    const InviteBsiCoLabHandle = () => {
        setOpenInviteBsiCoLab(true);
    };
    const closeInviteBsiCoLab = () => {
        setOpenInviteBsiCoLab(false);
    };

    
  const refetchTeamMemberList = () => {
    dispatch(setRefetchTeamMemberList(true));
  };

    return (
        <>
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            minHeight="calc(100% - 164px)"
            textAlign="center"
            padding={3}
        >
            {/* <img
                src="/path-to-your-image/no-data-illustration.png" // Replace with your image path
                // alt="No Data"
                style={{ width: '200px', height: 200, marginBottom: '20px', background: 'gray', borderRadius: 8 }} // Adjust the size as needed
            /> */}
            <Typography variant="h6" 
            sx={{
              color:"black",
              fontWeight: 600,
            }}
            gutterBottom
            >
                No team member added yet
            </Typography>
            <Typography 
            variant="body1" 
            sx={{
                color:"black",
                fontWeight: 500,  
              }}
            gutterBottom>
                You can start by inviting a new team member
            </Typography>
            
            <Button
            onClick={InviteBsiCoLabHandle}
            variant="contained"
            sx={{
              textTransform: "inherit",
              mt:1,
              py: "11px",
              boxShadow: "none",
              px: 12.5,
              "&:hover": {
                boxShadow: "none",
              },
              [theme.breakpoints.down("md")]: {
                px: 2.5,
                width: "100%",
              },
            }}
          >
            <UserAddOutlined
                  style={{
                    width: "20px",
                    height: "20px",
                    marginRight: "1px",
                    // marginTop: "4px",
                  }}
                />{" "}
            Invite to BSI Co-lab
          </Button>
        </Box>
           <Modals
           open={openInviteBsiCoLab}
           handleClose={closeInviteBsiCoLab}
           modalWidth={500}
         >
           <InvitetoBSIColab
             showToastMessage={showToastMessage}
             refetchTeamMemberList={refetchTeamMemberList}
             handleClose={closeInviteBsiCoLab}
           />
         </Modals>
         </>
    );
};

export default NoTeamMemberScreen;
