import { fetchBaseQuery, createApi } from '@reduxjs/toolkit/query/react';


const baseUrl = process.env.REACT_APP_base_URL;

export const getInboxBusinessUserList = createApi({
    reducerPath: 'getInboxBusinessUserList',
    baseQuery: fetchBaseQuery({
      baseUrl: baseUrl,
      prepareHeaders: (headers) => {
        // Add any headers you need here
        return headers;
      },
    }),
    endpoints: (builder) => ({
      fetchInboxBusinessUserList: builder.query({
        query: ({ userId, solutionId}) => ({
          url: `/inbox/solution_provider/${solutionId}?userId=${userId}`,
          method: 'GET',
        }),
        transformResponse: (response) => {
          if(response.status != "error"){
            return response.data
          }else{
           return  response = [];
          }
        },
        refetchOnWindowFocus: true,
        // retry: RetryOnError,
        onError: (error) => {
          console.error('Fetch user details error:', error);
          // Handle error cases as per your application's requirements
        },
      })
    })
})


export const { useFetchInboxBusinessUserListQuery } = getInboxBusinessUserList;
export default getInboxBusinessUserList;