import { createSlice } from "@reduxjs/toolkit";

const viewRequirementSlice = createSlice({
    name :'viewRequirementSlice',
    initialState: {
        boardId: "",
        boardName: "",
        industry: "",
    },
    reducers:{
        getBoardIdForViewRequirement: (state , action)=> {
          state.boardId =  action.payload
        },
        getBoardNameForViewRequirement: (state , action)=> {
          state.boardName =  action.payload
        },
        getIndustryForViewRequirement: (state, action)=>{
          state.industry = action.payload
        },  
    },
});

export const {getBoardIdForViewRequirement , getBoardNameForViewRequirement , getIndustryForViewRequirement } = viewRequirementSlice.actions;
export default viewRequirementSlice.reducer;