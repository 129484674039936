import React from 'react'
import {
    Avatar,
    Box,
    IconButton,
    List,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
    Typography,
    TextField,
    styled,
    InputBase,
    Button,
    Stack
} from '@mui/material'
import { useTheme } from '@emotion/react';
import SearchIcon from '@mui/icons-material/Search';
import { getImages } from '../const';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';

const DirectMessage = ({initiateNewDMHandle }) => {
    const theme = useTheme();
    const Search = styled('div')(({ theme }) => ({
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: '#ECF6FF',
        '&:hover': {
            backgroundColor: '#ECF6FF',
        },
        marginRight: '0',
        marginLeft: 0,
        marginBottom: 5,
        width: '100%'
    }));
    const SearchIconWrapper = styled('div')(({ theme }) => ({
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        right: '0',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }));

    const StyledInputBase = styled(InputBase)(({ theme }) => ({
        color: '#106EED',
        width: '100%',
        '& .MuiInputBase-input': {
            padding: theme.spacing(1.7, 0, 1.7, 1.7),
            paddingRight: `calc(1em + ${theme.spacing(4)})`,
            transition: theme.transitions.create('width'),
            width: '100%',
            fontSize: '12px',
            fontWeight: '500'
        },
    }));
    return (
        <>


            <Box
                sx={{
                    mb: 3
                }}
            >
                <Typography variant='h5'
                    sx={{
                        fontSize: "1.1rem",
                        color: "secondary.main",
                        fontWeight: "700"
                    }}>
                    Direct Message
                </Typography>
            </Box>
            <Search
                sx={{
                    backgroundColor: 'extraLTheme.main',
                    mb: 2
                }}
            >
                <SearchIconWrapper>
                    <SearchIcon sx={{ color: "lightSecondary.main" }} />
                </SearchIconWrapper>
                <StyledInputBase
                    placeholder="Search solution"
                    inputProps={{ 'aria-label': 'search' }}
                />
            </Search>
            <Box
                sx={{
                    borderBottom: 'solid 1px #DBEBF8',
                    pb: 1.5,
                    mb: 2
                }}
            >
                <Button variant="text"
                    sx={{
                        textTransform: 'inherit',
                        p: "0",
                        borderWidth: '2px',
                        fontWeight: '600',
                        '&:hover': {
                            borderWidth: '2px',
                            backgroundColor: 'transparent'
                        }
                    }}
                    onClick={initiateNewDMHandle}
                >
                    <IconButton aria-label="Group add"
                        sx={{
                            backgroundColor: '#DBEBF8',
                            color: 'primary.main',
                            mr: 1.5
                        }}
                    >
                        <MessageOutlinedIcon />
                    </IconButton>
                    Initiate New DM
                </Button>
            </Box>
            <Typography
                variant="body2"
                sx={{
                    fontSize: '0.9rem',
                    fontWeight: '500',
                    color: 'ltheme.main',
                    mb: 1,
                    mt:1,

                }}
            >Continue DM on</Typography>

            <List
                component="nav"
                sx={{
                    px: 0,
                    py: 0,
                    mb: 3,
                    '& .MuiListItemButton-root': {
                        py: 0.5,
                    },
                    overflowY: 'auto',
                    maxHeight: '480px',
                    '&::-webkit-scrollbar': {
                        width: '6px',
                        borderRadius: '4px'
                    },
                    '&::-webkit-scrollbar-track': {
                        backgroundColor: 'transparent',
                        borderRadius: '4px'
                    },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: '#9C9C9C',
                        borderRadius: '4px'
                    }
                }}
            >
                <ListItemButton
                    sx={{
                        minHeight: "50px",
                        justifyContent: "space-between",
                        alignItems: "center",
                        background: "#FFFFFF",
                        borderRadius: "5px",
                        marginTop: "5px",
                        '&:hover': {
                            backgroundColor: 'extraLTheme.main'
                        }
                    }}
                >
                    <Stack
                        sx={{
                            flexDirection: "inherit",
                            alignItems: "center",
                            width: "100%"
                        }}
                    >
                        <ListItemAvatar
                            sx={{
                                display: "inline-block",
                                marginRight: "7px"
                            }}
                        >
                            <img
                                style={{ maxWidth: "54px", display: "block" }} src={getImages('image_172.png')} />
                        </ListItemAvatar>
                        <Typography variant="subtitle1"
                            noWrap
                            sx={{
                                fontSize: "1.1rem",
                                color: "lightSecondary.main",
                                fontWeight: "600",
                                lineHeight: "20px",
                                maxWidth: '260px'
                            }}
                        >TRUSTDOCK Digital ID</Typography>
                    </Stack>
                    <ArrowForwardIosOutlinedIcon sx={{
                        color: "lightSecondary.main",
                        fontSize: '15px'
                    }} />
                </ListItemButton>
                <ListItemButton
                    sx={{
                        minHeight: "50px",
                        justifyContent: "space-between",
                        alignItems: "center",
                        background: "#FFFFFF",
                        borderRadius: "5px",
                        marginTop: "5px",
                        '&:hover': {
                            backgroundColor: 'extraLTheme.main'
                        }
                    }}
                >
                    <Stack
                        sx={{
                            flexDirection: "inherit",
                            alignItems: "center",
                            width: "100%"
                        }}
                    >
                        <ListItemAvatar
                            sx={{
                                display: "inline-block",
                                marginRight: "7px"
                            }}
                        >
                            <img
                                style={{ maxWidth: "54px", display: "block" }} src={getImages('image_154.png')} />
                        </ListItemAvatar>
                        <Typography variant="subtitle1"
                            noWrap
                            sx={{
                                fontSize: "1.1rem",
                                color: "lightSecondary.main",
                                fontWeight: "600",
                                lineHeight: "20px",
                                maxWidth: '260px'
                            }}
                        >KYC as a Service</Typography>
                    </Stack>
                    <ArrowForwardIosOutlinedIcon sx={{
                        color: "lightSecondary.main",
                        fontSize: '15px'
                    }} />
                </ListItemButton>
                <ListItemButton
                    sx={{
                        minHeight: "50px",
                        justifyContent: "space-between",
                        alignItems: "center",
                        background: "#FFFFFF",
                        borderRadius: "5px",
                        marginTop: "5px",
                        '&:hover': {
                            backgroundColor: 'extraLTheme.main'
                        }
                    }}
                >
                    <Stack
                        sx={{
                            flexDirection: "inherit",
                            alignItems: "center",
                            width: "100%"
                        }}
                    >
                        <ListItemAvatar
                            sx={{
                                display: "inline-block",
                                marginRight: "7px"
                            }}
                        >
                            <img
                                style={{ maxWidth: "54px", display: "block" }} src={getImages('image_155.png')} />
                        </ListItemAvatar>
                        <Typography variant="subtitle1"
                            noWrap
                            sx={{
                                fontSize: "1.1rem",
                                color: "lightSecondary.main",
                                fontWeight: "600",
                                lineHeight: "20px",
                                maxWidth: '260px'
                            }}
                        >Blockpass Identity for a Connected World</Typography>
                    </Stack>
                    <ArrowForwardIosOutlinedIcon sx={{
                        color: "lightSecondary.main",
                        fontSize: '15px'
                    }} />
                </ListItemButton>
                <ListItemButton
                    sx={{
                        minHeight: "50px",
                        justifyContent: "space-between",
                        alignItems: "center",
                        background: "#FFFFFF",
                        borderRadius: "5px",
                        marginTop: "5px",
                        '&:hover': {
                            backgroundColor: 'extraLTheme.main'
                        }
                    }}
                >
                    <Stack
                        sx={{
                            flexDirection: "inherit",
                            alignItems: "center",
                            width: "100%"
                        }}
                    >
                        <ListItemAvatar
                            sx={{
                                display: "inline-block",
                                marginRight: "7px"
                            }}
                        >
                            <img
                                style={{ maxWidth: "54px", display: "block" }} src={getImages('image_53.png')} />
                        </ListItemAvatar>
                        <Typography variant="subtitle1"
                            noWrap
                            sx={{
                                fontSize: "1.1rem",
                                color: "lightSecondary.main",
                                fontWeight: "600",
                                lineHeight: "20px",
                                maxWidth: '260px'
                            }}
                        >Advanced Solutions in Digital Healthcare</Typography>
                    </Stack>
                    <ArrowForwardIosOutlinedIcon sx={{
                        color: "lightSecondary.main",
                        fontSize: '15px'
                    }} />
                </ListItemButton>
                <ListItemButton
                    sx={{
                        minHeight: "50px",
                        justifyContent: "space-between",
                        alignItems: "center",
                        background: "#FFFFFF",
                        borderRadius: "5px",
                        marginTop: "5px",
                        '&:hover': {
                            backgroundColor: 'extraLTheme.main'
                        }
                    }}
                >
                    <Stack
                        sx={{
                            flexDirection: "inherit",
                            alignItems: "center",
                            width: "100%"
                        }}
                    >
                        <ListItemAvatar
                            sx={{
                                display: "inline-block",
                                marginRight: "7px"
                            }}
                        >
                            <img
                                style={{ maxWidth: "54px", display: "block" }} src={getImages('image_153.png')} />
                        </ListItemAvatar>
                        <Typography variant="subtitle1"
                            noWrap
                            sx={{
                                fontSize: "1.1rem",
                                color: "lightSecondary.main",
                                fontWeight: "600",
                                lineHeight: "20px",
                                maxWidth: '260px'
                            }}
                        >Claim Settlement Platform</Typography>
                    </Stack>
                    <ArrowForwardIosOutlinedIcon sx={{
                        color: "lightSecondary.main",
                        fontSize: '15px'
                    }} />
                </ListItemButton>
                <ListItemButton
                    sx={{
                        minHeight: "50px",
                        justifyContent: "space-between",
                        alignItems: "center",
                        background: "#FFFFFF",
                        borderRadius: "5px",
                        marginTop: "5px",
                        '&:hover': {
                            backgroundColor: 'extraLTheme.main'
                        }
                    }}
                >
                    <Stack
                        sx={{
                            flexDirection: "inherit",
                            alignItems: "center",
                            width: "100%"
                        }}
                    >
                        <ListItemAvatar
                            sx={{
                                display: "inline-block",
                                marginRight: "7px"
                            }}
                        >
                            <img
                                style={{ maxWidth: "54px", display: "block" }} src={getImages('image_172.png')} />
                        </ListItemAvatar>
                        <Typography variant="subtitle1"
                            noWrap
                            sx={{
                                fontSize: "1.1rem",
                                color: "lightSecondary.main",
                                fontWeight: "600",
                                lineHeight: "20px",
                                maxWidth: '260px'
                            }}
                        >TRUSTDOCK Digital ID</Typography>
                    </Stack>
                    <ArrowForwardIosOutlinedIcon sx={{
                        color: "lightSecondary.main",
                        fontSize: '15px'
                    }} />
                </ListItemButton>
                <ListItemButton
                    sx={{
                        minHeight: "50px",
                        justifyContent: "space-between",
                        alignItems: "center",
                        background: "#FFFFFF",
                        borderRadius: "5px",
                        marginTop: "5px",
                        '&:hover': {
                            backgroundColor: 'extraLTheme.main'
                        }
                    }}
                >
                    <Stack
                        sx={{
                            flexDirection: "inherit",
                            alignItems: "center",
                            width: "100%"
                        }}
                    >
                        <ListItemAvatar
                            sx={{
                                display: "inline-block",
                                marginRight: "7px"
                            }}
                        >
                            <img
                                style={{ maxWidth: "54px", display: "block" }} src={getImages('image_154.png')} />
                        </ListItemAvatar>
                        <Typography variant="subtitle1"
                            noWrap
                            sx={{
                                fontSize: "1.1rem",
                                color: "lightSecondary.main",
                                fontWeight: "600",
                                lineHeight: "20px",
                                maxWidth: '260px'
                            }}
                        >KYC as a Service</Typography>
                    </Stack>
                    <ArrowForwardIosOutlinedIcon sx={{
                        color: "lightSecondary.main",
                        fontSize: '15px'
                    }} />
                </ListItemButton>
                <ListItemButton
                    sx={{
                        minHeight: "50px",
                        justifyContent: "space-between",
                        alignItems: "center",
                        background: "#FFFFFF",
                        borderRadius: "5px",
                        marginTop: "5px",
                        '&:hover': {
                            backgroundColor: 'extraLTheme.main'
                        }
                    }}
                >
                    <Stack
                        sx={{
                            flexDirection: "inherit",
                            alignItems: "center",
                            width: "100%"
                        }}
                    >
                        <ListItemAvatar
                            sx={{
                                display: "inline-block",
                                marginRight: "7px"
                            }}
                        >
                            <img
                                style={{ maxWidth: "54px", display: "block" }} src={getImages('image_155.png')} />
                        </ListItemAvatar>
                        <Typography variant="subtitle1"
                            noWrap
                            sx={{
                                fontSize: "1.1rem",
                                color: "lightSecondary.main",
                                fontWeight: "600",
                                lineHeight: "20px",
                                maxWidth: '260px'
                            }}
                        >Blockpass Identity for a Connected World</Typography>
                    </Stack>
                    <ArrowForwardIosOutlinedIcon sx={{
                        color: "lightSecondary.main",
                        fontSize: '15px'
                    }} />
                </ListItemButton>
                <ListItemButton
                    sx={{
                        minHeight: "50px",
                        justifyContent: "space-between",
                        alignItems: "center",
                        background: "#FFFFFF",
                        borderRadius: "5px",
                        marginTop: "5px",
                        '&:hover': {
                            backgroundColor: 'extraLTheme.main'
                        }
                    }}
                >
                    <Stack
                        sx={{
                            flexDirection: "inherit",
                            alignItems: "center",
                            width: "100%"
                        }}
                    >
                        <ListItemAvatar
                            sx={{
                                display: "inline-block",
                                marginRight: "7px"
                            }}
                        >
                            <img
                                style={{ maxWidth: "54px", display: "block" }} src={getImages('image_53.png')} />
                        </ListItemAvatar>
                        <Typography variant="subtitle1"
                            noWrap
                            sx={{
                                fontSize: "1.1rem",
                                color: "lightSecondary.main",
                                fontWeight: "600",
                                lineHeight: "20px",
                                maxWidth: '260px'
                            }}
                        >Advanced Solutions in Digital Healthcare</Typography>
                    </Stack>
                    <ArrowForwardIosOutlinedIcon sx={{
                        color: "lightSecondary.main",
                        fontSize: '15px'
                    }} />
                </ListItemButton>
                <ListItemButton
                    sx={{
                        minHeight: "50px",
                        justifyContent: "space-between",
                        alignItems: "center",
                        background: "#FFFFFF",
                        borderRadius: "5px",
                        marginTop: "5px",
                        '&:hover': {
                            backgroundColor: 'extraLTheme.main'
                        }
                    }}
                >
                    <Stack
                        sx={{
                            flexDirection: "inherit",
                            alignItems: "center",
                            width: "100%"
                        }}
                    >
                        <ListItemAvatar
                            sx={{
                                display: "inline-block",
                                marginRight: "7px"
                            }}
                        >
                            <img
                                style={{ maxWidth: "54px", display: "block" }} src={getImages('image_153.png')} />
                        </ListItemAvatar>
                        <Typography variant="subtitle1"
                            noWrap
                            sx={{
                                fontSize: "1.1rem",
                                color: "lightSecondary.main",
                                fontWeight: "600",
                                lineHeight: "20px",
                                maxWidth: '260px'
                            }}
                        >Claim Settlement Platform</Typography>
                    </Stack>
                    <ArrowForwardIosOutlinedIcon sx={{
                        color: "lightSecondary.main",
                        fontSize: '15px'
                    }} />
                </ListItemButton>
            </List>
        </>
    )
}

export default DirectMessage