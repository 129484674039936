import React, { useState } from "react";
import { Typography, Box, Button } from "@mui/material";

const MessageDisplay = ({ receiverMessage, senderMessage }) => {
  const wordLimit = 200; // Limit of 200 words
  const receiverWords = receiverMessage ? receiverMessage.split(" ") : [];
  const senderWords = senderMessage ? senderMessage.split(" ") : [];
  const [showFullReceiverMessage, setShowFullReceiverMessage] = useState(false);
  const [showFullSenderMessage, setShowFullSenderMessage] = useState(false);

  const toggleReceiverMessage = () => {
    setShowFullReceiverMessage(true); // Only allows expanding, not collapsing
  };

  const toggleSenderMessage = () => {
    setShowFullSenderMessage(true); // Only allows expanding, not collapsing
  };

  return (
    <>
      {/* Display receiver message */}
      {receiverMessage && (
        <Box
        >
          <Typography
            variant="body2"
            sx={{
              fontSize: "0.9rem",
              color: "lightSecondary.main",
              fontWeight: "500",
              whiteSpace: "pre-wrap",
              
            }}
          >
            {showFullReceiverMessage || receiverWords.length <= wordLimit
              ? receiverMessage
              : (
                <>
                  {`${receiverWords.slice(0, wordLimit).join(" ")}... `}
                  <span style={{ marginLeft: "4px" }} />
                  {/* Display "Read More" button inline */}
                  <Button
                    onClick={toggleReceiverMessage}
                    sx={{
                      textTransform: "none",
                      color: "secondary.main",
                      fontWeight:'bold',
                      fontSize: "0.9rem",
                      padding: 0, // Remove padding to align with text
                      minWidth: "auto", // Adjust width
                      backgroundColor: "transparent", // No background color
                      "&:hover": {
                        backgroundColor: "transparent", // Prevent background on hover
                      },
                    }}
                  >
                    Read More
                  </Button>
                </>
              )}
          </Typography>

          {/* Display "Read More" / "Show Less" button for receiver */}
          {/* {receiverWords.length > wordLimit  && !showFullReceiverMessage &&  (
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end", // Align button to the right
                width: "100%",
                mt: 1,
              }}
            >
              <Button
                onClick={toggleReceiverMessage}
                sx={{
                  textTransform: "none",
                  color: "secondary.main",
                  fontSize: "0.9rem",
                  backgroundColor: "transparent",
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                }}
              >
                Read More
              </Button>
            </Box>
          )} */}
        </Box>
      )}

      {/* Display sender message */}
      {senderMessage && (
        <Box
        >
          <Typography
            variant="body2"
            sx={{
              fontSize: "0.9rem",
              color: "#fff", // Darker color for sender text
              fontWeight: "500",
              whiteSpace: "pre-wrap",
            }}
          >
            {showFullSenderMessage || senderWords.length <= wordLimit
              ? senderMessage
              : (
                <>
                  {`${senderWords.slice(0, wordLimit).join(" ")}... `}
                  <span style={{ marginLeft: "4px" }} />
                  {/* Display "Read More" button inline */}
                  <Button
                    onClick={toggleSenderMessage}
                    sx={{
                      textTransform: "none",
                      color: "#fff",
                      fontSize: "0.9rem",
                      fontWeight:'bold',
                      padding: 0, // Remove padding to align with text
                      minWidth: "auto", // Adjust width
                      backgroundColor: "transparent", // No background color
                      "&:hover": {
                        backgroundColor: "transparent", // Prevent background on hover
                      },
                    }}
                  >
                    Read More
                  </Button>
                </>
              )}
          </Typography>

          {/* Display "Read More" / "Show Less" button for sender */}
          {/* {senderWords.length > wordLimit  && !showFullSenderMessage && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end", // Align button to the right
                width: "100%",
                mt: 1,
              }}
            >
              <Button
                onClick={toggleSenderMessage}
                sx={{
                  textTransform: "none",
                  color: "black",
                  fontSize: "0.9rem",
                  backgroundColor: "transparent",
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                }}
              >
                Read More
              </Button>
            </Box>
          )} */}
        </Box>
      )}
    </>
  );
};

export default MessageDisplay;
