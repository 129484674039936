import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Typography,
  styled,
  InputBase,
  ListItemButton,
  IconButton,
  Badge,
  List,
  Button,
  MenuItem,
  Menu,
  Paper,
  Divider,
  Tooltip,
  ListItemText,
  Stack,
  debounce,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import CancelIcon from "@mui/icons-material/Cancel";
import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";
import SearchIcon from "@mui/icons-material/Search";
import { getImages } from "../const";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import { useDispatch, useSelector } from "react-redux";
import {
    getChatId,
    getGroupName,
    getSolutionLogo,
  setBusinessUserId,
  setOpenChat,
  setSolutionId,
  setSolutionName,
  setSolutionProviderId,
  toggleChatData,
  toggleGroupSelected,
} from "../../redux/features/chatSlice";
import { toggleDrawer } from "../../redux/features/drawerSlice";
import { useFetchInboxSelectedSolutionListQuery } from "../../redux/api/Inbox/getInboxSelectedSolutionList";
import { hideLoader, showLoader } from "../../redux/features/loader";
import { toast } from "react-toastify";
import { UserAddOutlined } from "@ant-design/icons";
import InvitetoBSIColab from "./InvitetoBSIColab";
import Modals from "../Modals";
import { resetRefetchSolutionList, setRefetchTeamMemberList } from "../../redux/features/refetch";

const AllSolutionsList = ({ count ,  showToastMessage , setCount }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const logoBaseUrl = process.env.REACT_APP_S3_BUCKET_URL;
  const userId = localStorage.getItem("userId");
  const boardId = useSelector((state) => state.inbox.boardId);
  const {  solutionId } = useSelector((state) => state.chat);
  const { groupChatId } = useSelector((state) => state.chat);


  // Search Box
  const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "#ECF6FF",
    "&:hover": {
      backgroundColor: "#ECF6FF",
    },
    marginRight: "0",
    marginLeft: 0,
    marginBottom: 5,
    width: "100%",
    display: "flex",
    alignItems: "center",
  }));
  const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    right: "0",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }));

  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "#106EED",
    width: "100%",
    "& .MuiInputBase-input": {
      padding: theme.spacing(1.7, 0, 1.7, 1.7),
      paddingRight: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create("width"),
      width: "100%",
      fontSize: "12px",
      fontWeight: "500",
    },
  }));

  // searching Logic
  const [searchParams, setSearchParams] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [filteredSolution, setFilteredSolution] = useState("");
  const {
    data: solutionList,
    error: boardDetailError,
    isLoading: boardDetailLoading,
    refetch,
  } = useFetchInboxSelectedSolutionListQuery({
    userId: userId,
    selectedBoardId: boardId,
    searchParams: searchValue,
  });

  const sortedSolutionList = [...(solutionList?.solutions?.solutions || [])].map((solution) => {
    // Check if the current solution matches the chatId
    // if (solution._id === count.chatId) {
    //   return {
    //     ...solution,
    //     latestMessage: count.latestMessage, 
    //     unreadMessageCount: count.unreadMessageCount  
    //   };
    // }
    return solution; 
  }).sort((a, b) => {
    // Get timestamps from latestMessage or fallback to a very old date for undefined messages
    const timeA = a?.latestMessage?.createdAt ? new Date(a.latestMessage.createdAt).getTime() : 0;
    const timeB = b?.latestMessage?.createdAt ? new Date(b.latestMessage.createdAt).getTime() : 0;
    // Sort by descending order (latest first)
    return timeB - timeA;
  });

  const debouncedSearch = useCallback(
    debounce((query) => {
      if (sortedSolutionList && query.length > 0) {
        const filtered = sortedSolutionList.filter((solution) =>
            solution?.solution_id?.solution_name
            .toLowerCase()
            .includes(query.toLowerCase())
        );
        setFilteredSolution(filtered);
      } else {
        setFilteredSolution(businessUserList || []);
      }
    }, 300),
    [sortedSolutionList]
  );

  // Function to search business users based on the name
  const handleSearchSolutionProvider = (event) => {
    if (event.key === "Enter") {
      debouncedSearch(searchParams);
    }
  };

  const handleInputChange = (event) => {
    const inputValue = event.target.value.trim();
    if (!inputValue) {
      setSearchParams("");
      setFilteredSolution([]);
      refetch();
      return;
    }
    setSearchParams(inputValue);
  };

  const handleClearSearch = () => {
    setSearchParams("");
    setFilteredSolution([]);
    refetch(); 
  };
  
// Find the index of the solution that matches the chatId
const chatSolutionIndex = sortedSolutionList.findIndex(
  (solution) => solution?._id === count?.chatId
);

// If a matching solution is found, remove it from its original position and place it at the top
if (chatSolutionIndex !== -1) {
  const [chatSolution] = sortedSolutionList.splice(chatSolutionIndex, 1);
  sortedSolutionList.unshift(chatSolution);
}
   // set refetchBusinessUserList in reduxStore
   const refetchSolutionList = useSelector(
    (state) => state.refetch.refetchSolutionList
  );
  useEffect(() => {
    if (refetchSolutionList) {
      refetch().finally(() => {
        dispatch(resetRefetchSolutionList());
      });
    }
  }, [refetchSolutionList, refetch, dispatch]);


  // InviteBsiCoLab Modal
  const [openInviteBsiCoLab, setOpenInviteBsiCoLab] = useState(false);
  const InviteBsiCoLabHandle = () => {
    setOpenInviteBsiCoLab(true);
  };
  const closeInviteBsiCoLab = () => {
    setOpenInviteBsiCoLab(false);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openDw = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [anchorElStatus, setAnchorElStatus] = React.useState(null);
  const openStatus = Boolean(anchorElStatus);
  const handleStatusClick = (event) => {
    setAnchorElStatus(event.currentTarget);
  };
  const handleStatusClose = () => {
    setAnchorElStatus(null);
  };

  const [anchorElSolution, setAnchorElSolution] = React.useState(null);
  const openSolution = Boolean(anchorElSolution);
  const handleSolutionClick = (event) => {
    setAnchorElSolution(event.currentTarget);
  };
  const handleSolutionClose = () => {
    setAnchorElSolution(null);
  };
  const { open } = useSelector((state) => state.drawer);

  const { openChat, openSolutionList, chatData, solutionProviderId } =
    useSelector((state) => state.chat);
  const chatOpenHandle = (
    buttonIndex,
    solutionProviderId,
    solutionId,
    businessUserId,
    solutionName,
    solutionLogo,
    groupChatId
  ) => {
    dispatch(setSolutionProviderId(solutionProviderId));
    dispatch(setSolutionId(solutionId));
    dispatch(setBusinessUserId(businessUserId));
    dispatch(setSolutionName(solutionName));
    dispatch(getGroupName(solutionName));
    dispatch(getSolutionLogo(solutionLogo));
    dispatch(getChatId(groupChatId));
    dispatch(toggleGroupSelected(true));
    if (open) {
      dispatch(toggleDrawer());
    }
    if(count){
        setCount((prevCount) => {
          if (prevCount.chatId === groupChatId) {
            return {
              ...prevCount,
              chatId: prevCount.chatId,  
              latestMessage: prevCount.latestMessage,
              unreadMessageCount: 0  // Set unreadMessageCount to 0
            };
          }
        });
      }
      refetch();
  };

//   useEffect(()=>{
//     if(RedirectSolutionName && RedirectGroupChatId  && RedirectSolutionId){
//       dispatch(setSolutionName(RedirectSolutionName));
//       dispatch(getGroupName(RedirectSolutionName));
//       dispatch(getChatId(RedirectGroupChatId));
//       dispatch(getSolutionLogo(RedirectSolutionLogo));
//       dispatch(setSolutionId(RedirectSolutionId));
//       dispatch(toggleGroupSelected(true));
//     }
//   },[ RedirectSolutionName , RedirectGroupChatId , RedirectSolutionLogo , RedirectSolutionId])

//   useEffect(()=>{
//     if( RedirectSolutionId && RedirectGroupChatId){
//       dispatch(setSolutionId(RedirectSolutionId));
//       dispatch(getChatId(RedirectGroupChatId));
//     }
//   },[RedirectSolutionId ,  RedirectGroupChatId])

  const refetchTeamMemberList = () => {
    dispatch(setRefetchTeamMemberList(true));
  };

  const formatMessageDate = (createdAt) => {
    const now = new Date();
    const messageDate = new Date(createdAt);
  
    const isToday = now.toDateString() === messageDate.toDateString();
  
    // Check if the message was sent yesterday
    const yesterday = new Date(now);
    yesterday.setDate(now.getDate() - 1);
    const isYesterday = yesterday.toDateString() === messageDate.toDateString();
  
    const timeDifferenceInDays = (now - messageDate) / (1000 * 60 * 60 * 24);
  
    // Check if the message is within the last 7 days (excluding today and yesterday)
    const isWithinLast7Days = timeDifferenceInDays > 1 && timeDifferenceInDays <= 7;
  
    if (isToday) {
      // Return the time if the message is from today
      return messageDate.toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });
    } else if (isYesterday) {
      // Return "Yesterday" if the message is from yesterday
      return "Yesterday";
    } else if (isWithinLast7Days) {
      // Return the day if the message is from the last 7 days (excluding today and yesterday)
      return messageDate.toLocaleDateString("en-US", { weekday: "long" });
    } else {
      // Return the full date in MM/DD/YYYY format if the message is older than 7 days
      return messageDate.toLocaleDateString("en-US", {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      });
    }
  };
  

  useEffect(() => {
    if (boardDetailLoading) {
      dispatch(showLoader());
    } else {
      dispatch(hideLoader());
    }
  }, [boardDetailLoading, dispatch]);

  return (
    <Box
    sx={{
        minWidth: "213px",
        "@media(min-width:2560px)" :{
          minWidth: "313px",
        },
        "@media(min-width:1460px)" :{
          minWidth: "313px",
        },
        // "@media (max-width:1910px) and (min-width: 1901px)": {
        //   minWidth: open ? "344px" :"324px",
        // },
        // "@media (max-width:1900px) and (min-width: 1660px)": {
        //   minWidth: open ? "341px" :"316px",
        // },
        // "@media (max-width:1660px) and (min-width: 1540px)": {
        //   minWidth: open ? "300px" :"312px",
        // },
        "@media (max-width:1540px) and (min-width: 1310px)": {
          minWidth:"280px",
        },
        
        "@media (max-width:1250px) and (min-width: 1200px)": {
          minWidth: "250px",
        },
        "@media (max-width:1310px) and (min-width: 1250px)": {
          minWidth: "260px",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        //   pr:1,
          [theme.breakpoints.down("md")]: {
            flexWrap: "wrap",
          },
        }}
      >
        
          {/* <div
          style={{
            position: "relative",
          }}
        >
          <Button
            id="basic-button1"
            aria-controls={openUser ? "basic-menu1" : undefined}
            aria-haspopup="true"
            aria-expanded={openUser ? "true" : undefined}
            onClick={handleBusinessUserClick}
            sx={{
              p: "10px 0",
              minWidth: "10px",
              width: "100%",
              verticalAlign: "-5px",
              display: "flex",
              justifyContent: "space-between",
              color: "secondary.main",
            }}
          >
            <MoreVertOutlinedIcon
              sx={{
                fontSize: "1.2rem",
              }}
            />
          </Button>
          <Menu
            id="basic-menu1"
            anchorEl={anchorElSolution}
            open={openUser}
            onClose={handleBusinessUserClose}
            MenuListProps={{
              "aria-labelledby": "basic-button1",
            }}
            sx={{
              "& .MuiMenu-paper": {
                borderRadius: "4px",
                border: "none",
                minWidth: "210px",
                p: 0.5,
              },
            }}
          >
            <Paper
              sx={{
                width: 210,
                maxWidth: "100%",
                background: "#fff",
                borderRadius: "10px",
                borderBottom: "none",
                boxShadow: "none",
                p: 0.5,
              }}
            >
              <MenuItem
                onClick={handleBusinessUserClose}
                sx={{
                  fontSize: "0.9rem",
                  fontWeight: "600",
                  lineHeight: "20px",
                  color: "lightSecondary.main",
                  py: 1,
                  px: 1.4,
                  "&:hover": {
                    backgroundColor: "extraLTheme.main",
                  },
                  "&.active": {
                    backgroundColor: "primary.main",
                    color: "#fff",
                    borderRadius: "10px",
                  },
                }}
              >
                All
              </MenuItem>
              <MenuItem
                onClick={handleBusinessUserClose}
                sx={{
                  fontSize: "0.9rem",
                  fontWeight: "600",
                  lineHeight: "20px",
                  color: "lightSecondary.main",
                  py: 1,
                  px: 1.4,
                  "&:hover": {
                    backgroundColor: "extraLTheme.main",
                  },
                  "&.active": {
                    backgroundColor: "primary.main",
                    color: "#fff",
                    borderRadius: "10px",
                  },
                }}
              >
                Archived
              </MenuItem>
              <MenuItem
                onClick={handleBusinessUserClose}
                sx={{
                  fontSize: "0.9rem",
                  fontWeight: "600",
                  lineHeight: "20px",
                  color: "lightSecondary.main",
                  py: 1,
                  px: 1.4,
                  "&:hover": {
                    backgroundColor: "extraLTheme.main",
                  },
                  "&.active": {
                    backgroundColor: "primary.main",
                    color: "#fff",
                    borderRadius: "10px",
                  },
                }}
              >
                Muted
              </MenuItem>
            </Paper>
          </Menu>
        </div> */}
        
        <Button
          variant="none"
          sx={{
            textTransform: "inherit",
            p: "5px 5px 5px 5px",
            color: "primary.main",
            fontWeight: "600",
            "&:hover": {
              background: "transparent",
            },
            "@media (max-width:1600px) and (min-width: 1300px)": {
              fontSize: "0.9rem",
            },
            [theme.breakpoints.down("xl")]: {
              fontSize: "0.8rem",
            },
          }}
          onClick={InviteBsiCoLabHandle}
        >
          <UserAddOutlined
            style={{
              width: "15px",
              height: "20px",
              position: "relative",
            }}
          />
          Invite to BSI Co-lab
        </Button>
        <Typography
          variant="h6"
          sx={{
            display: "flex",
            alignItems: "center",
            color: "secondary.main",
            fontSize: "0.9rem",
            fontWeight: "700",
            pr: 1.5,
            visibility: "hidden",
          }}
        >
          Solution Chats
          </Typography>
      </Box>
      <Box
        sx={{
          mb: 1.5,
          pl: 0,
          pr: 1.5,
        }}
      >
        <Search>
          <StyledInputBase
            placeholder="Search by Solution Name"
            inputProps={{ "aria-label": "search" }}
            value={searchParams}
            onChange={handleInputChange}
            onKeyDown={handleSearchSolutionProvider}
            autoFocus
          />
          {searchParams ? (
           <IconButton
            onClick={handleClearSearch}
            sx={{ position: "absolute", right: 0 }}
            >
            <CancelIcon sx={{ color: "lightSecondary.main" }} />
            </IconButton>
           ) : (
            <SearchIconWrapper>
            <SearchIcon sx={{ color: "lightSecondary.main" }} />
            </SearchIconWrapper>
         )}
        </Search>
      </Box>
      <List
        component="nav"
        sx={{
          mb: 0,
          "& .MuiButtonBase-root + .MuiButtonBase-root": {
            marginTop: "10px",
          },
          pl: 0,
          pr: 0.9,
          overflowY: openSolutionList ? "hidden" : "auto",
          maxHeight: "calc(100vh - 192px)",
          "&::-webkit-scrollbar": {
            width: "6px",
            borderRadius: "4px",
            display: "block",
            position: "absolute",
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "transparent",
            borderRadius: "4px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#9C9C9C",
            borderRadius: "4px",
          },
          [theme.breakpoints.down("lg")]: {
            maxHeight: "calc(100vh - 192px)",
          },
        }}
      >
        {
            filteredSolution.length > 0 ?
            filteredSolution.map((solution , index)=>(
<ListItemButton
                key={solution.solution_id?._id}
                onClick={() =>
                  chatOpenHandle(
                    index,
                    solution?.solution_provider_id?._id,
                    solution?.solution_id?._id,
                    solution?.user_id?._id,
                    solution?.solution_id.solution_name,
                    `${logoBaseUrl}${solution.solution_id.logo}`,
                    solution?._id
                  )
                }
                sx={{
                  boxShadow: "none",
                  backgroundColor:  solutionId === solution?.solution_id?._id
                  ? "#ECF6FF" : "#d0e4f3" ,
                  borderRadius: "10px",
                  minHeight: "70px",
                  maxHeight: "100px",
                  display: "block",
                 border: solutionId === solution?.solution_id?._id ? 'solid 1px #106EED' : '',
                  "&:hover": {
                  backgroundColor: solutionId === solution?.solution_id?._id
                    ? "#ECF6FF" : "#d0e4f3" ,
                  border: solutionId === solution?.solution_id?._id ? 'solid 1px #106EED' : ''
                },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    mb: 1.5,
                  }}
                >
                  <Box>
                    <img
                      style={{
                        maxWidth: "106px",
                        maxHeight: "30px",
                        mixBlendMode: "multiply",
                      }}
                      src={`${logoBaseUrl}${solution.solution_id.logo}`}
                      alt={`${solution.solution_id.solution_name} logo`}
                    />
                  </Box>
                  {
                    count?.chatId === solution._id && count?.latestMessage?.createdAt ?
                    (
                        <Typography
                        variant="body2"
                        noWrap
                        sx={{
                          color: "ltheme.main",
                          fontSize: "12px",
                          fontWeight: "500",
                          mb: "5px",
                          visibility: count?.latestMessage?.createdAt
                            ? "visible"
                            : "hidden",
                        }}
                      >
                        {count?.latestMessage?.createdAt
                          ? isNaN(new Date(count?.latestMessage.createdAt))
                            ? "Invalid Date"
                            : formatMessageDate(count?.latestMessage.createdAt)
                          : ""}
                      </Typography>
                    )
                    :
                    (
                        <Typography
                        variant="body2"
                        noWrap
                        sx={{
                          color: "ltheme.main",
                          fontSize: "12px",
                          fontWeight: "500",
                          mb: "5px",
                          visibility: solution?.latestMessage?.createdAt
                            ? "visible"
                            : "hidden",
                        }}
                      >
                         {solution?.latestMessage?.createdAt
                        ? isNaN(new Date(solution?.latestMessage.createdAt))
                          ? "Invalid Date"
                          : formatMessageDate(solution?.latestMessage.createdAt)
                        : ""}
                      </Typography>
                    )
                }
                 
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    mb: 1.5,
                  }}
                >
                <Typography
                  noWrap
                  variant="body2"
                  sx={{
                    fontSize: "0.9rem",
                    color: solutionId === solution?.solution_id?._id? "black" : "black",
                  }}
                >
                  <Tooltip 
                     title=
                     {solution?.solution_id?.solution_name ? 
                      `${solution?.solution_id?.solution_name.charAt(0).toUpperCase()}${solution?.solution_id?.solution_name.slice(1)}` 
                      : ""
                    }
                     >
                      {solution?.solution_id?.solution_name? 
                        `${solution?.solution_id?.solution_name.charAt(0).toUpperCase()}${solution?.solution_id?.solution_name.slice(1)}` 
                        : ""
                      }
                    </Tooltip>
                </Typography>
                {
                    count?.chatId === solution._id && count?.unreadMessageCount > 0 ?
                    (
                        <>
                         <Box
                      component="span"
                      sx={{
                        bgcolor: "primary.main",
                        width: 24,
                        height: 24,
                        borderRadius: "50%",
                        fontSize: "12px",
                        color: "#fff",
                        textAlign: "center",
                        lineHeight: "24px",
                        visibility:  solutionId === solution?.solution_id?._id|| count?.unreadMessageCount == 0 ? "hidden" : "visible"
                      }}
                    >
                        {count.unreadMessageCount}
                    </Box>
                        </>
                    ):
                    (
                        <>
                         <Box
                      component="span"
                      sx={{
                        bgcolor: "primary.main",
                        width: 24,
                        height: 24,
                        borderRadius: "50%",
                        fontSize: "12px",
                        color: "#fff",
                        textAlign: "center",
                        lineHeight: "24px",
                        visibility: solutionId === solution?.solution_id?._id|| solution?.unreadMessageCount == 0 ? "hidden" : "visible"
                      }}
                    >
                        {solution?.unreadMessageCount}
                    </Box>
                        </>
                    )
                }
                    </Box>
              </ListItemButton>
            ))
            :



         sortedSolutionList?.map(

          (solution, index) =>
           (
                <>
              <ListItemButton
                key={solution.solution_id?._id}
                onClick={() =>
                  chatOpenHandle(
                    index,
                    solution?.solution_provider_id?._id,
                    solution?.solution_id?._id,
                    solution?.user_id?._id,
                    solution?.solution_id.solution_name,
                    `${logoBaseUrl}${solution.solution_id.logo}`,
                    solution?._id
                  )
                }
                sx={{
                  boxShadow: "none",
                  backgroundColor:  solutionId === solution?.solution_id?._id
                  ? "#ECF6FF" : "#d0e4f3" ,
                  borderRadius: "10px",
                  minHeight: "70px",
                  maxHeight: "100px",
                  display: "block",
                 border: solutionId === solution?.solution_id?._id ? 'solid 1px #106EED' : '',
                  "&:hover": {
                  backgroundColor: solutionId === solution?.solution_id?._id
                    ? "#ECF6FF" : "#d0e4f3" ,
                  border: solutionId === solution?.solution_id?._id ? 'solid 1px #106EED' : ''
                },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    mb: 1.5,
                  }}
                >
                  <Box>
                    <img
                      style={{
                        maxWidth: "106px",
                        maxHeight: "30px",
                        mixBlendMode: "multiply",
                      }}
                      src={`${logoBaseUrl}${solution.solution_id.logo}`}
                      alt={`${solution.solution_id.solution_name} logo`}
                    />
                  </Box>
                  {
                    count?.chatId === solution._id && count?.latestMessage?.createdAt ?
                    (
                        <Typography
                        variant="body2"
                        noWrap
                        sx={{
                          color: "ltheme.main",
                          fontSize: "12px",
                          fontWeight: "500",
                          mb: "5px",
                          visibility: count?.latestMessage?.createdAt
                            ? "visible"
                            : "hidden",
                        }}
                      >
                        {count?.latestMessage?.createdAt
                          ? isNaN(new Date(count?.latestMessage.createdAt))
                            ? "Invalid Date"
                            : formatMessageDate(count?.latestMessage.createdAt)
                          : ""}
                      </Typography>
                    )
                    :
                    (
                        <Typography
                        variant="body2"
                        noWrap
                        sx={{
                          color: "ltheme.main",
                          fontSize: "12px",
                          fontWeight: "500",
                          mb: "5px",
                          visibility: solution?.latestMessage?.createdAt
                            ? "visible"
                            : "hidden",
                        }}
                      >
                         {solution?.latestMessage?.createdAt
                        ? isNaN(new Date(solution?.latestMessage.createdAt))
                          ? "Invalid Date"
                          : formatMessageDate(solution?.latestMessage.createdAt)
                        : ""}
                      </Typography>
                    )
                }
                 
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    mb: 1.5,
                  }}
                >
                <Typography
                  noWrap
                  variant="body2"
                  sx={{
                    fontSize: "0.9rem",
                    color: solutionId === solution?.solution_id?._id? "black" : "black",
                  }}
                >
                  <Tooltip 
                     title=
                     {solution?.solution_id?.solution_name ? 
                      `${solution?.solution_id?.solution_name.charAt(0).toUpperCase()}${solution?.solution_id?.solution_name.slice(1)}` 
                      : ""
                    }
                     >
                      {solution?.solution_id?.solution_name? 
                        `${solution?.solution_id?.solution_name.charAt(0).toUpperCase()}${solution?.solution_id?.solution_name.slice(1)}` 
                        : ""
                      }
                    </Tooltip>
                </Typography>
                {
                    count?.chatId === solution._id && count?.unreadMessageCount > 0 ?
                    (
                        <>
                         <Box
                      component="span"
                      sx={{
                        bgcolor: "primary.main",
                        width: 24,
                        height: 24,
                        borderRadius: "50%",
                        fontSize: "12px",
                        color: "#fff",
                        textAlign: "center",
                        lineHeight: "24px",
                        visibility:  solutionId === solution?.solution_id?._id|| count?.unreadMessageCount == 0 ? "hidden" : "visible"
                      }}
                    >
                        {count.unreadMessageCount}
                    </Box>
                        </>
                    ):
                    (
                        <>
                         <Box
                      component="span"
                      sx={{
                        bgcolor: "primary.main",
                        width: 24,
                        height: 24,
                        borderRadius: "50%",
                        fontSize: "12px",
                        color: "#fff",
                        textAlign: "center",
                        lineHeight: "24px",
                        visibility: solutionId === solution?.solution_id?._id|| solution?.unreadMessageCount == 0 ? "hidden" : "visible"
                      }}
                    >
                        {solution?.unreadMessageCount}
                    </Box>
                        </>
                    )
                }
                    </Box>
              </ListItemButton>
             
          </>
           
        ))}

        {/* <ListItemButton
                    onClick={() => chatOpenHandle(2)}
                    sx={{
                        boxShadow: 'none',
                        backgroundColor: 'extraLTheme.main',
                        borderRadius: "10px",
                        minHeight: "100px",
                        display: 'block',
                        border: openChat[2] ? 'solid 1px #106EED' : 'solid 1px #ECF6FF'
                    }}
                >

                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            mb: 1.5
                        }}>
                        <Box>
                            <img
                                style={{ maxWidth: '100px', display: "block" }}
                                src={getImages('image_53.png')}
                            />
                        </Box>
                        <Box>
                            <Typography variant="subtitle1"
                                sx={{
                                    fontSize: "0.95rem",
                                    color: "ltheme.main",
                                    fontWeight: "600"
                                }}>
                                <MessageOutlinedIcon
                                    sx={{
                                        verticalAlign: "middle",
                                        fill: "ltheme.main",
                                        width: "20px",
                                        height: "20px"
                                    }}
                                />
                                &nbsp;
                                5
                            </Typography>
                        </Box>
                    </Box>
                       <div>
                                <Button
                                    id="basic-button"
                                    aria-controls={openDw ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={openDw ? 'true' : undefined}
                                    onClick={handleClick}
                                    sx={{
                                        p: 0,
                                        minWidth: '10px',
                                        verticalAlign: '-5px'
                                    }}
                                >
                                    <MoreVertOutlinedIcon sx={{ color: "secondary.main" }} style={{ marginLeft: "0" }} />
                                </Button>
                                <Menu
                                    id="basic-menu"
                                    anchorEl={anchorEl}
                                    open={openDw}
                                    onClose={handleClose}
                                    MenuListProps={{
                                        'aria-labelledby': 'basic-button',
                                    }}
                                    sx={{
                                        '& .MuiMenu-paper': {
                                            borderRadius: "4px",
                                            border: "none",
                                            minWidth: "210px",
                                            p: 0.5,
                                        }
                                    }}
                                >
                                    <Paper
                                        sx={{
                                            width: 210,
                                            maxWidth: '100%',
                                            background: "#fff",
                                            borderRadius: "10px",
                                            borderBottom: "none",
                                            boxShadow: "none",
                                            p: 0.5
                                        }}>
                                        <MenuItem onClick={handleClose}
                                            sx={{
                                                fontSize: "0.9rem",
                                                fontWeight: "600",
                                                lineHeight: "20px",
                                                color: "lightSecondary.main",
                                                py: 1,
                                                px: 1.4,
                                                '&:hover': {
                                                    backgroundColor: 'extraLTheme.main'
                                                },
                                                '&.active': {
                                                    backgroundColor: 'primary.main',
                                                    color: "#fff",
                                                    borderRadius: "10px"
                                                }
                                            }}
                                        >Go to solution page</MenuItem>
                                        <MenuItem onClick={handleClose}
                                            sx={{
                                                fontSize: "0.9rem",
                                                fontWeight: "600",
                                                lineHeight: "20px",
                                                color: "lightSecondary.main",
                                                py: 1,
                                                px: 1.4,
                                                '&:hover': {
                                                    backgroundColor: 'extraLTheme.main'
                                                },
                                                '&.active': {
                                                    backgroundColor: 'primary.main',
                                                    color: "#fff",
                                                    borderRadius: "10px"
                                                }
                                            }}
                                        >Archive</MenuItem>
                                        <MenuItem onClick={handleClose}
                                            sx={{
                                                fontSize: "0.9rem",
                                                fontWeight: "600",
                                                lineHeight: "20px",
                                                color: "lightSecondary.main",
                                                py: 1,
                                                px: 1.4,
                                                '&:hover': {
                                                    backgroundColor: 'extraLTheme.main'
                                                },
                                                '&.active': {
                                                    backgroundColor: 'primary.main',
                                                    color: "#fff",
                                                    borderRadius: "10px"
                                                }
                                            }}
                                        >Mute</MenuItem>
                                        <Divider
                                            sx={{
                                                borderColor: 'ltheme.main',
                                            }}
                                        />
                                        <Typography
                                            sx={{
                                                fontSize: "0.9rem",
                                                fontWeight: "600",
                                                lineHeight: "20px",
                                                color: "lightSecondary.main",
                                                display: 'block',
                                                py: 1,
                                                px: 1.4,
                                                '&:hover': {
                                                    backgroundColor: 'extraLTheme.main'
                                                },
                                                '&.active': {
                                                    backgroundColor: 'primary.main',
                                                    color: "#fff",
                                                    borderRadius: "10px"
                                                }
                                            }}
                                        >Status: <br />

                                            <div>

                                                <Button
                                                    id="basic-button1"
                                                    aria-controls={openStatus ? 'basic-menu1' : undefined}
                                                    aria-haspopup="true"
                                                    aria-expanded={openStatus ? 'true' : undefined}
                                                    onClick={handleStatusClick}
                                                    sx={{
                                                        p: '10px 0',
                                                        minWidth: '10px',
                                                        width: '100%',
                                                        verticalAlign: '-5px',
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        color: 'secondary.main'
                                                    }}
                                                >
                                                    <div>Invited</div>
                                                    <ChevronRightOutlinedIcon sx={{ color: "secondary.main" }} style={{ marginLeft: "0" }} />
                                                </Button>
                                                <Menu
                                                    id="basic-menu1"
                                                    anchorEl={anchorElStatus}
                                                    open={openStatus}
                                                    onClose={handleStatusClose}
                                                    MenuListProps={{
                                                        'aria-labelledby': 'basic-button1',
                                                    }}
                                                    sx={{
                                                        '& .MuiMenu-paper': {
                                                            borderRadius: "4px",
                                                            border: "none",
                                                            minWidth: "210px",
                                                            p: 0.5,
                                                        }
                                                    }}
                                                >
                                                    <Paper
                                                        sx={{
                                                            width: 210,
                                                            maxWidth: '100%',
                                                            background: "#fff",
                                                            borderRadius: "10px",
                                                            borderBottom: "none",
                                                            boxShadow: "none",
                                                            p: 0.5
                                                        }}>
                                                        <MenuItem onClick={handleStatusClose}
                                                            sx={{
                                                                fontSize: "0.9rem",
                                                                fontWeight: "600",
                                                                lineHeight: "20px",
                                                                color: "lightSecondary.main",
                                                                py: 1,
                                                                px: 1.4,
                                                                '&:hover': {
                                                                    backgroundColor: 'extraLTheme.main'
                                                                },
                                                                '&.active': {
                                                                    backgroundColor: 'primary.main',
                                                                    color: "#fff",
                                                                    borderRadius: "10px"
                                                                }
                                                            }}
                                                        >Invited</MenuItem>
                                                        <MenuItem onClick={handleStatusClose}
                                                            sx={{
                                                                fontSize: "0.9rem",
                                                                fontWeight: "600",
                                                                lineHeight: "20px",
                                                                color: "lightSecondary.main",
                                                                py: 1,
                                                                px: 1.4,
                                                                '&:hover': {
                                                                    backgroundColor: 'extraLTheme.main'
                                                                },
                                                                '&.active': {
                                                                    backgroundColor: 'primary.main',
                                                                    color: "#fff",
                                                                    borderRadius: "10px"
                                                                }
                                                            }}
                                                        >Shortlisted</MenuItem>
                                                        <MenuItem onClick={handleStatusClose}
                                                            sx={{
                                                                fontSize: "0.9rem",
                                                                fontWeight: "600",
                                                                lineHeight: "20px",
                                                                color: "lightSecondary.main",
                                                                py: 1,
                                                                px: 1.4,
                                                                '&:hover': {
                                                                    backgroundColor: 'extraLTheme.main'
                                                                },
                                                                '&.active': {
                                                                    backgroundColor: 'primary.main',
                                                                    color: "#fff",
                                                                    borderRadius: "10px"
                                                                }
                                                            }}
                                                        >Rejected</MenuItem>
                                                        <MenuItem onClick={handleStatusClose}
                                                            sx={{
                                                                fontSize: "0.9rem",
                                                                fontWeight: "600",
                                                                lineHeight: "20px",
                                                                color: "lightSecondary.main",
                                                                py: 1,
                                                                px: 1.4,
                                                                '&:hover': {
                                                                    backgroundColor: 'extraLTheme.main'
                                                                },
                                                                '&.active': {
                                                                    backgroundColor: 'primary.main',
                                                                    color: "#fff",
                                                                    borderRadius: "10px"
                                                                }
                                                            }}
                                                        >Selected</MenuItem>
                                                    </Paper>
                                                </Menu>
                                            </div>
                                        </Typography>
                                    </Paper>
                                </Menu>
                            </div> 
                    <Typography variant='body2'
                        sx={{
                            fontSize: "0.9rem",
                            color: openChat[2] ? 'primary.main' : 'ltheme.main'
                        }}
                    >
                        Advanced Solutions in Digital Healthcare
                    </Typography>

                </ListItemButton>
                <ListItemButton
                    onClick={() => chatOpenHandle(3)}
                    sx={{
                        boxShadow: 'none',
                        backgroundColor: 'extraLTheme.main',
                        borderRadius: "10px",
                        minHeight: "100px",
                        display: 'block',
                        border: openChat[3] ? 'solid 1px #106EED' : 'solid 1px #ECF6FF'
                    }}
                >

                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            mb: 1.5
                        }}>
                        <Box>
                            <img
                                style={{ maxWidth: '100px', display: "block" }}
                                src={getImages('image_155.png')}
                            />
                        </Box>
                        <Box>
                            <Typography variant="subtitle1"
                                sx={{
                                    fontSize: "0.95rem",
                                    color: "ltheme.main",
                                    fontWeight: "600"
                                }}>
                                <MessageOutlinedIcon
                                    sx={{
                                        verticalAlign: "middle",
                                        fill: "ltheme.main",
                                        width: "20px",
                                        height: "20px"
                                    }}
                                />
                                &nbsp;
                                27
                            </Typography>
                        </Box>
                    </Box>
                    <Typography variant='body2'
                        sx={{
                            fontSize: "0.9rem",
                            color: openChat[3] ? 'primary.main' : 'ltheme.main'
                        }}
                    >
                        Blockpass Identity for a Connected World
                    </Typography>

                </ListItemButton>
                <ListItemButton
                    onClick={() => chatOpenHandle(4)}
                    sx={{
                        boxShadow: 'none',
                        backgroundColor: 'extraLTheme.main',
                        borderRadius: "10px",
                        minHeight: "100px",
                        display: 'block',
                        border: openChat[4] ? 'solid 1px #106EED' : 'solid 1px #ECF6FF'
                    }}
                >

                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            mb: 1.5
                        }}>
                        <Box>
                            <img
                                style={{ maxWidth: '100px', display: "block" }}
                                src={getImages('image_153.png')}
                            />
                        </Box>
                        <Box>
                            <Typography variant="subtitle1"
                                sx={{
                                    fontSize: "0.95rem",
                                    color: "ltheme.main",
                                    fontWeight: "600"
                                }}>
                                <MessageOutlinedIcon
                                    sx={{
                                        verticalAlign: "middle",
                                        fill: "ltheme.main",
                                        width: "20px",
                                        height: "20px"
                                    }}
                                />
                                &nbsp;
                                14
                            </Typography>
                        </Box>
                    </Box>
                    <Typography variant='body2'
                        sx={{
                            fontSize: "0.9rem",
                            color: openChat[4] ? 'primary.main' : 'ltheme.main'
                        }}
                    >
                        Claim Settlement Platform
                    </Typography>

                </ListItemButton>
                <ListItemButton
                    onClick={() => chatOpenHandle(5)}
                    sx={{
                        boxShadow: 'none',
                        backgroundColor: 'extraLTheme.main',
                        borderRadius: "10px",
                        minHeight: "100px",
                        display: 'block',
                        border: openChat[5] ? 'solid 1px #106EED' : 'solid 1px #ECF6FF'
                    }}
                >

                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            mb: 1.5
                        }}>
                        <Box>
                            <img style={{ maxWidth: '100px', display: "block" }} src={getImages('image_154.png')} />
                        </Box>
                        <Box>
                            <Typography variant="subtitle1"
                                sx={{
                                    fontSize: "0.95rem",
                                    color: "ltheme.main",
                                    fontWeight: "600"
                                }}>
                                <Badge color="secondary" badgeContent=" " variant="dot"
                                    sx={{
                                        '& .MuiBadge-badge': {
                                            backgroundColor: "red.main"
                                        }
                                    }}
                                >
                                    <MessageOutlinedIcon
                                        sx={{
                                            verticalAlign: "middle",
                                            fill: "ltheme.main",
                                            width: "20px",
                                            height: "20px"
                                        }}
                                    />
                                </Badge>
                                &nbsp;
                                13
                            </Typography>
                        </Box>
                    </Box>
                    <Typography variant='body2'
                        sx={{
                            fontSize: "0.9rem",
                            color: openChat[5] ? 'primary.main' : 'ltheme.main'
                        }}
                    >
                        CIAM - Customer Identity & Access Management
                    </Typography>

                </ListItemButton> */}
        {/* <ListItemButton
                    onClick={() => chatOpenHandle(6)}
                    sx={{
                        boxShadow: 'none',
                        backgroundColor: 'extraLTheme.main',
                        borderRadius: "10px",
                        minHeight: "100px",
                        display: 'block',
                        border: openChat[6] ? 'solid 1px #106EED' : 'solid 1px #ECF6FF'
                    }}
                >

                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            mb: 1.5
                        }}>
                        <Box>
                            <img
                                style={{ maxWidth: '100px', display: "block" }}
                                src={getImages('image_53.png')}
                            />
                        </Box>
                        <Box>
                            <Typography variant="subtitle1"
                                sx={{
                                    fontSize: "0.95rem",
                                    color: "ltheme.main",
                                    fontWeight: "600"
                                }}>
                                <MessageOutlinedIcon
                                    sx={{
                                        verticalAlign: "middle",
                                        fill: "ltheme.main",
                                        width: "20px",
                                        height: "20px"
                                    }}
                                />
                                &nbsp;
                                5
                            </Typography>
                        </Box>
                    </Box>
                    <Typography variant='body2'
                        sx={{
                            fontSize: "0.9rem",
                            color: openChat[6] ? 'primary.main' : 'ltheme.main'
                        }}
                    >
                        Advanced Solutions in Digital Healthcare
                    </Typography>

                </ListItemButton>
                <ListItemButton
                    onClick={() => chatOpenHandle(1)}
                    sx={{
                        boxShadow: 'none',
                        backgroundColor: 'extraLTheme.main',
                        borderRadius: "10px",
                        minHeight: "100px",
                        display: 'block',
                        border: openChat[1] ? 'solid 1px #106EED' : 'solid 1px #ECF6FF'
                    }}
                >

                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            mb: 1.5
                        }}>
                        <Box>
                            <img style={{ maxWidth: '100px', display: "block" }} src={getImages('image_154.png')} />
                        </Box>
                        <Box>
                            <Typography variant="subtitle1"
                                sx={{
                                    fontSize: "0.95rem",
                                    color: "ltheme.main",
                                    fontWeight: "600"
                                }}>
                                <Badge color="secondary" badgeContent=" " variant="dot"
                                    sx={{
                                        '& .MuiBadge-badge': {
                                            backgroundColor: "red.main"
                                        }
                                    }}
                                >
                                    <MessageOutlinedIcon
                                        sx={{
                                            verticalAlign: "middle",
                                            fill: "ltheme.main",
                                            width: "20px",
                                            height: "20px"
                                        }}
                                    />
                                </Badge>
                                &nbsp;
                                13
                            </Typography>
                        </Box>
                    </Box>
                    <Typography variant='body2'
                        sx={{
                            fontSize: "0.9rem",
                            color: openChat[1] ? 'primary.main' : 'ltheme.main'
                        }}
                    >
                        CIAM - Customer Identity & Access Management
                    </Typography>

                </ListItemButton>
                <ListItemButton
                    onClick={() => chatOpenHandle(2)}
                    sx={{
                        boxShadow: 'none',
                        backgroundColor: 'extraLTheme.main',
                        borderRadius: "10px",
                        minHeight: "100px",
                        display: 'block',
                        border: openChat[2] ? 'solid 1px #106EED' : 'solid 1px #ECF6FF'
                    }}
                >

                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            mb: 1.5
                        }}>
                        <Box>
                            <img
                                style={{ maxWidth: '100px', display: "block" }}
                                src={getImages('image_53.png')}
                            />
                        </Box>
                        <Box>
                            <Typography variant="subtitle1"
                                sx={{
                                    fontSize: "0.95rem",
                                    color: "ltheme.main",
                                    fontWeight: "600"
                                }}>
                                <MessageOutlinedIcon
                                    sx={{
                                        verticalAlign: "middle",
                                        fill: "ltheme.main",
                                        width: "20px",
                                        height: "20px"
                                    }}
                                />
                                &nbsp;
                                5
                            </Typography>
                        </Box>
                    </Box>
                    <Typography variant='body2'
                        sx={{
                            fontSize: "0.9rem",
                            color: openChat[2] ? 'primary.main' : 'ltheme.main'
                        }}
                    >
                        Advanced Solutions in Digital Healthcare
                    </Typography>

                </ListItemButton>
                <ListItemButton
                    onClick={() => chatOpenHandle(3)}
                    sx={{
                        boxShadow: 'none',
                        backgroundColor: 'extraLTheme.main',
                        borderRadius: "10px",
                        minHeight: "100px",
                        display: 'block',
                        border: openChat[3] ? 'solid 1px #106EED' : 'solid 1px #ECF6FF'
                    }}
                >

                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            mb: 1.5
                        }}>
                        <Box>
                            <img
                                style={{ maxWidth: '100px', display: "block" }}
                                src={getImages('image_155.png')}
                            />
                        </Box>
                        <Box>
                            <Typography variant="subtitle1"
                                sx={{
                                    fontSize: "0.95rem",
                                    color: "ltheme.main",
                                    fontWeight: "600"
                                }}>
                                <MessageOutlinedIcon
                                    sx={{
                                        verticalAlign: "middle",
                                        fill: "ltheme.main",
                                        width: "20px",
                                        height: "20px"
                                    }}
                                />
                                &nbsp;
                                27
                            </Typography>
                        </Box>
                    </Box>
                    <Typography variant='body2'
                        sx={{
                            fontSize: "0.9rem",
                            color: openChat[3] ? 'primary.main' : 'ltheme.main'
                        }}
                    >
                        Blockpass Identity for a Connected World
                    </Typography>

                </ListItemButton>
                <ListItemButton
                    onClick={() => chatOpenHandle(4)}
                    sx={{
                        boxShadow: 'none',
                        backgroundColor: 'extraLTheme.main',
                        borderRadius: "10px",
                        minHeight: "100px",
                        display: 'block',
                        border: openChat[4] ? 'solid 1px #106EED' : 'solid 1px #ECF6FF'
                    }}
                >

                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            mb: 1.5
                        }}>
                        <Box>
                            <img
                                style={{ maxWidth: '100px', display: "block" }}
                                src={getImages('image_153.png')}
                            />
                        </Box>
                        <Box>
                            <Typography variant="subtitle1"
                                sx={{
                                    fontSize: "0.95rem",
                                    color: "ltheme.main",
                                    fontWeight: "600"
                                }}>
                                <MessageOutlinedIcon
                                    sx={{
                                        verticalAlign: "middle",
                                        fill: "ltheme.main",
                                        width: "20px",
                                        height: "20px"
                                    }}
                                />
                                &nbsp;
                                14
                            </Typography>
                        </Box>
                    </Box>
                    <Typography variant='body2'
                        sx={{
                            fontSize: "0.9rem",
                            color: openChat[4] ? 'primary.main' : 'ltheme.main'
                        }}
                    >
                        Claim Settlement Platform
                    </Typography>

                </ListItemButton>
                <ListItemButton
                    onClick={() => chatOpenHandle(5)}
                    sx={{
                        boxShadow: 'none',
                        backgroundColor: 'extraLTheme.main',
                        borderRadius: "10px",
                        minHeight: "100px",
                        display: 'block',
                        border: openChat[5] ? 'solid 1px #106EED' : 'solid 1px #ECF6FF'
                    }}
                >

                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            mb: 1.5
                        }}>
                        <Box>
                            <img style={{ maxWidth: '100px', display: "block" }} src={getImages('image_154.png')} />
                        </Box>
                        <Box>
                            <Typography variant="subtitle1"
                                sx={{
                                    fontSize: "0.95rem",
                                    color: "ltheme.main",
                                    fontWeight: "600"
                                }}>
                                <Badge color="secondary" badgeContent=" " variant="dot"
                                    sx={{
                                        '& .MuiBadge-badge': {
                                            backgroundColor: "red.main"
                                        }
                                    }}
                                >
                                    <MessageOutlinedIcon
                                        sx={{
                                            verticalAlign: "middle",
                                            fill: "ltheme.main",
                                            width: "20px",
                                            height: "20px"
                                        }}
                                    />
                                </Badge>
                                &nbsp;
                                13
                            </Typography>
                        </Box>
                    </Box>
                    <Typography variant='body2'
                        sx={{
                            fontSize: "0.9rem",
                            color: openChat[5] ? 'primary.main' : 'ltheme.main'
                        }}
                    >
                        CIAM - Customer Identity & Access Management
                    </Typography>

                </ListItemButton>
                <ListItemButton
                    onClick={() => chatOpenHandle(6)}
                    sx={{
                        boxShadow: 'none',
                        backgroundColor: 'extraLTheme.main',
                        borderRadius: "10px",
                        minHeight: "100px",
                        display: 'block',
                        border: openChat[6] ? 'solid 1px #106EED' : 'solid 1px #ECF6FF'
                    }}
                >

                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            mb: 1.5
                        }}>
                        <Box>
                            <img
                                style={{ maxWidth: '100px', display: "block" }}
                                src={getImages('image_53.png')}
                            />
                        </Box>
                        <Box>
                            <Typography variant="subtitle1"
                                sx={{
                                    fontSize: "0.95rem",
                                    color: "ltheme.main",
                                    fontWeight: "600"
                                }}>
                                <MessageOutlinedIcon
                                    sx={{
                                        verticalAlign: "middle",
                                        fill: "ltheme.main",
                                        width: "20px",
                                        height: "20px"
                                    }}
                                />
                                &nbsp;
                                5
                            </Typography>
                        </Box>
                    </Box>
                    <Typography variant='body2'
                        sx={{
                            fontSize: "0.9rem",
                            color: openChat[6] ? 'primary.main' : 'ltheme.main'
                        }}
                    >
                        Advanced Solutions in Digital Healthcare
                    </Typography>

                </ListItemButton>
                <ListItemButton
                    onClick={() => chatOpenHandle(1)}
                    sx={{
                        boxShadow: 'none',
                        backgroundColor: 'extraLTheme.main',
                        borderRadius: "10px",
                        minHeight: "100px",
                        display: 'block',
                        border: openChat[1] ? 'solid 1px #106EED' : 'solid 1px #ECF6FF'
                    }}
                >

                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            mb: 1.5
                        }}>
                        <Box>
                            <img style={{ maxWidth: '100px', display: "block" }} src={getImages('image_154.png')} />
                        </Box>
                        <Box>
                            <Typography variant="subtitle1"
                                sx={{
                                    fontSize: "0.95rem",
                                    color: "ltheme.main",
                                    fontWeight: "600"
                                }}>
                                <Badge color="secondary" badgeContent=" " variant="dot"
                                    sx={{
                                        '& .MuiBadge-badge': {
                                            backgroundColor: "red.main"
                                        }
                                    }}
                                >
                                    <MessageOutlinedIcon
                                        sx={{
                                            verticalAlign: "middle",
                                            fill: "ltheme.main",
                                            width: "20px",
                                            height: "20px"
                                        }}
                                    />
                                </Badge>
                                &nbsp;
                                13
                            </Typography>
                        </Box>
                    </Box>
                    <Typography variant='body2'
                        sx={{
                            fontSize: "0.9rem",
                            color: openChat[1] ? 'primary.main' : 'ltheme.main'
                        }}
                    >
                        CIAM - Customer Identity & Access Management
                    </Typography>

                </ListItemButton>
                <ListItemButton
                    onClick={() => chatOpenHandle(2)}
                    sx={{
                        boxShadow: 'none',
                        backgroundColor: 'extraLTheme.main',
                        borderRadius: "10px",
                        minHeight: "100px",
                        display: 'block',
                        border: openChat[2] ? 'solid 1px #106EED' : 'solid 1px #ECF6FF'
                    }}
                >

                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            mb: 1.5
                        }}>
                        <Box>
                            <img
                                style={{ maxWidth: '100px', display: "block" }}
                                src={getImages('image_53.png')}
                            />
                        </Box>
                        <Box>
                            <Typography variant="subtitle1"
                                sx={{
                                    fontSize: "0.95rem",
                                    color: "ltheme.main",
                                    fontWeight: "600"
                                }}>
                                <MessageOutlinedIcon
                                    sx={{
                                        verticalAlign: "middle",
                                        fill: "ltheme.main",
                                        width: "20px",
                                        height: "20px"
                                    }}
                                />
                                &nbsp;
                                5
                            </Typography>
                        </Box>
                    </Box>
                    <Typography variant='body2'
                        sx={{
                            fontSize: "0.9rem",
                            color: openChat[2] ? 'primary.main' : 'ltheme.main'
                        }}
                    >
                        Advanced Solutions in Digital Healthcare
                    </Typography>

                </ListItemButton>
                <ListItemButton
                    onClick={() => chatOpenHandle(3)}
                    sx={{
                        boxShadow: 'none',
                        backgroundColor: 'extraLTheme.main',
                        borderRadius: "10px",
                        minHeight: "100px",
                        display: 'block',
                        border: openChat[3] ? 'solid 1px #106EED' : 'solid 1px #ECF6FF'
                    }}
                >

                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            mb: 1.5
                        }}>
                        <Box>
                            <img
                                style={{ maxWidth: '100px', display: "block" }}
                                src={getImages('image_155.png')}
                            />
                        </Box>
                        <Box>
                            <Typography variant="subtitle1"
                                sx={{
                                    fontSize: "0.95rem",
                                    color: "ltheme.main",
                                    fontWeight: "600"
                                }}>
                                <MessageOutlinedIcon
                                    sx={{
                                        verticalAlign: "middle",
                                        fill: "ltheme.main",
                                        width: "20px",
                                        height: "20px"
                                    }}
                                />
                                &nbsp;
                                27
                            </Typography>
                        </Box>
                    </Box>
                    <Typography variant='body2'
                        sx={{
                            fontSize: "0.9rem",
                            color: openChat[3] ? 'primary.main' : 'ltheme.main'
                        }}
                    >
                        Blockpass Identity for a Connected World
                    </Typography>

                </ListItemButton>
                <ListItemButton
                    onClick={() => chatOpenHandle(4)}
                    sx={{
                        boxShadow: 'none',
                        backgroundColor: 'extraLTheme.main',
                        borderRadius: "10px",
                        minHeight: "100px",
                        display: 'block',
                        border: openChat[4] ? 'solid 1px #106EED' : 'solid 1px #ECF6FF'
                    }}
                >

                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            mb: 1.5
                        }}>
                        <Box>
                            <img
                                style={{ maxWidth: '100px', display: "block" }}
                                src={getImages('image_153.png')}
                            />
                        </Box>
                        <Box>
                            <Typography variant="subtitle1"
                                sx={{
                                    fontSize: "0.95rem",
                                    color: "ltheme.main",
                                    fontWeight: "600"
                                }}>
                                <MessageOutlinedIcon
                                    sx={{
                                        verticalAlign: "middle",
                                        fill: "ltheme.main",
                                        width: "20px",
                                        height: "20px"
                                    }}
                                />
                                &nbsp;
                                14
                            </Typography>
                        </Box>
                    </Box>
                    <Typography variant='body2'
                        sx={{
                            fontSize: "0.9rem",
                            color: openChat[4] ? 'primary.main' : 'ltheme.main'
                        }}
                    >
                        Claim Settlement Platform
                    </Typography>

                </ListItemButton>
                <ListItemButton
                    onClick={() => chatOpenHandle(5)}
                    sx={{
                        boxShadow: 'none',
                        backgroundColor: 'extraLTheme.main',
                        borderRadius: "10px",
                        minHeight: "100px",
                        display: 'block',
                        border: openChat[5] ? 'solid 1px #106EED' : 'solid 1px #ECF6FF'
                    }}
                >

                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            mb: 1.5
                        }}>
                        <Box>
                            <img style={{ maxWidth: '100px', display: "block" }} src={getImages('image_154.png')} />
                        </Box>
                        <Box>
                            <Typography variant="subtitle1"
                                sx={{
                                    fontSize: "0.95rem",
                                    color: "ltheme.main",
                                    fontWeight: "600"
                                }}>
                                <Badge color="secondary" badgeContent=" " variant="dot"
                                    sx={{
                                        '& .MuiBadge-badge': {
                                            backgroundColor: "red.main"
                                        }
                                    }}
                                >
                                    <MessageOutlinedIcon
                                        sx={{
                                            verticalAlign: "middle",
                                            fill: "ltheme.main",
                                            width: "20px",
                                            height: "20px"
                                        }}
                                    />
                                </Badge>
                                &nbsp;
                                13
                            </Typography>
                        </Box>
                    </Box>
                    <Typography variant='body2'
                        sx={{
                            fontSize: "0.9rem",
                            color: openChat[5] ? 'primary.main' : 'ltheme.main'
                        }}
                    >
                        CIAM - Customer Identity & Access Management
                    </Typography>

                </ListItemButton>
                <ListItemButton
                    onClick={() => chatOpenHandle(6)}
                    sx={{
                        boxShadow: 'none',
                        backgroundColor: 'extraLTheme.main',
                        borderRadius: "10px",
                        minHeight: "100px",
                        display: 'block',
                        border: openChat[6] ? 'solid 1px #106EED' : 'solid 1px #ECF6FF'
                    }}
                >

                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            mb: 1.5
                        }}>
                        <Box>
                            <img
                                style={{ maxWidth: '100px', display: "block" }}
                                src={getImages('image_53.png')}
                            />
                        </Box>
                        <Box>
                            <Typography variant="subtitle1"
                                sx={{
                                    fontSize: "0.95rem",
                                    color: "ltheme.main",
                                    fontWeight: "600"
                                }}>
                                <MessageOutlinedIcon
                                    sx={{
                                        verticalAlign: "middle",
                                        fill: "ltheme.main",
                                        width: "20px",
                                        height: "20px"
                                    }}
                                />
                                &nbsp;
                                5
                            </Typography>
                        </Box>
                    </Box>
                    <Typography variant='body2'
                        sx={{
                            fontSize: "0.9rem",
                            color: openChat[6] ? 'primary.main' : 'ltheme.main'
                        }}
                    >
                        Advanced Solutions in Digital Healthcare
                    </Typography>

                </ListItemButton> */}
      </List>
      <Modals
        open={openInviteBsiCoLab}
        handleClose={closeInviteBsiCoLab}
        modalWidth={500}
      >
        <InvitetoBSIColab
          showToastMessage={showToastMessage}
          handleClose={closeInviteBsiCoLab}
          refetchTeamMemberList={refetchTeamMemberList}
        />
      </Modals>
    </Box>
  );
};

export default AllSolutionsList;
