
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl =process.env.REACT_APP_base_URL;

const createSolution = createApi({
    reducerPath: 'submitSolution',
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl,
        prepareHeaders: (headers) => {
            // Add any headers you need here
            return headers;
        },
    }),
    endpoints: (builder) => ({
        createSolution: builder.mutation({
            query: ({ userId, formData}) => ({
                url:`solution/createSolution/${userId}`,
                method: 'POST',
                body: formData,
            }),
            transformResponse: (response) => {
                return response;
            },
        }),
    }),
});

export const { useCreateSolutionMutation } = createSolution;
export default createSolution;
