import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Checkbox,
  FormGroup,
  Tooltip,
  Chip,
} from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useTheme } from "@emotion/react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useFetchViewRequirementQuery } from "../../redux/api/Requirement Boards/viewRequirement";
import Modals from "../../commonComponents/Modals";
import DeleteBoardAlert from "../../commonComponents/Kanban/DeleteBoardAlert";
import { hideLoader, showLoader } from "../../redux/features/loader";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { DeleteOutlineOutlined } from "@mui/icons-material";
// import MemoryIcon from '../../assets/img/tech2-icon.png';
// import HealthAndSafetyIcon from '../../assets/img/industries-icon.svg';
// import FingerprintIcon from '../../assets/img/use-cases-icon.svg';

const BsiColabRequirement = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const userType = localStorage.getItem("userType");
  const userId = localStorage.getItem("userId");
  const dispatch = useDispatch();
  // Extract boardName from URL query parameters
  // const queryParams = new URLSearchParams(location.search);
  // const boardName = queryParams.get("boardName");
  // const boardId = queryParams.get("boardId");
  const updateRequirementLink = process.env.REACT_APP_CREATE_REQUIREMENT_URL;
  const boardId = localStorage.getItem("viewRequirementBoardId");
  const boardName = localStorage.getItem("viewRequirementBoardName");

  const handleGoBack = () => {
    navigate("/bsi-co-lab");
  };
  // toast messages
  const showToastMessage = (message, type) => {
    if (type === "success") {
      toast.success(message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (type === "error") {
      toast.error(message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (type === "info") {
      toast.warning(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  // Delete list1Alert Modal
  const [openDeleteBoardAlert, setOpenDeleteBoardAlert] = useState(false);
  const deleteBoardAlertHandle = () => {
    setOpenDeleteBoardAlert(true);
  };
  const closeDeleteBoardAlert = () => {
    setOpenDeleteBoardAlert(false);
  };
  const goBackFromAlertHandle = () => {
    setOpenDeleteBoardAlert(false);
  };

  const {
    data: requirementData,
    error: requirementError,
    isLoading: requirementLoading,
    refetch: refetchRequirement,
  } = useFetchViewRequirementQuery({
    userId: userId,
    boardId: boardId,
  });

  useEffect(() => {
    if (requirementLoading) {
      dispatch(showLoader());
    } else {
      dispatch(hideLoader());
    }
  }, [requirementLoading, dispatch]);

  const handleUpdateRequirement = () => {
    if (boardId) {
      // Append boardId as a query parameter to the update requirement URL
      const updatedUrl = `${updateRequirementLink}?boardId=${boardId}`;
      window.location.href = updatedUrl;
    } else {
      // Handle case where boardId is missing (optional)
      showToastMessage("Board ID is missing", "error");
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "15px",
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          gap="12px"
        >
          {/* Board Name Field */}
          <Typography
            sx={{
              fontSize: "1.4rem",
              fontWeight: "700",
              color: "secondary.main",
              [theme.breakpoints.down("md")]: {
                fontSize: "1.1rem",
                maxWidth: "350px",
              },
              [theme.breakpoints.down("sm")]: {
                maxWidth: "250px",
              },
            }}
            variant="h4"
            noWrap
            component="div"
          >
            <Link
              style={{
                color: "#2A589C",
                textDecoration: "none",
              }}
            >
              <KeyboardBackspaceIcon
                style={{
                  verticalAlign: "-8px",
                  fontSize: "32px",
                  marginRight: "7px",
                }}
                onClick={handleGoBack}
              />
            </Link>
            <Tooltip title={boardName}>{boardName}</Tooltip>
          </Typography>

          {/* Accept and Reject Buttons */}
          {userType === "solution_provider" ? (
            <Box display="flex" gap="12px" alignSelf="flex-start">
              <Button
                sx={{
                  backgroundColor: "blue",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "blue",
                    color: "white",
                  },
                }}
              >
                Accept
              </Button>
              <Button
                sx={{
                  backgroundColor: "#FF0000",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#FF0000",
                    color: "white",
                  },
                }}
              >
                Reject
              </Button>
            </Box>
          ) : (
            <></>
          )}
          {/* <Box display="flex" gap="12px" alignSelf="flex-start">
            <Button
              sx={{
                backgroundColor: "blue",
                color: "white",
                "&:hover": {
                  backgroundColor: "blue",
                  color: "white",
                },
              }}
            >
              Accept
            </Button>
            <Button
              sx={{
                backgroundColor: "#FF0000",
                color: "white",
                "&:hover": {
                  backgroundColor: "#FF0000",
                  color: "white",
                },
              }}
            >
              Reject
            </Button>
          </Box> */}
        </Box>

        {/* first box */}
        {userType === "solution_provider" ? (
          <Box
            sx={{
              // maxWidth: "900px",
              width: "100%",
              margin: "auto",
              padding: "2rem",
              borderRadius: "8px",
              backgroundColor: "#ffffff",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            }}
          >
            {/* Business Profile and Business Name */}
            <Box
              sx={{
                display: "flex",
                // justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "2rem",
                gap: "10vh",
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  lineHeight: "normal",
                  fontSize: "1.4rem",
                  fontWeight: "700",
                  color: "#073985",
                }}
              >
                Business Profile
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  color: "secondary.main",
                  fontSize: "1.3rem",
                  fontWeight: "600",
                  lineHeight: "normal",
                }}
              >
                Business Name
              </Typography>
            </Box>
            {/* Business name , website and role */}

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "2rem",
                width: "100%",
              }}
            >
              <Box
                display="flex"
                alignItems="center"
                minWidth="30%"
                maxWidth="30%"
              >
                {/* <MemoryIcon sx={{ marginRight: "8px", fontSize:48 ,color: "primary.main"  }} />  */}
                <Typography
                  variant="h6"
                  sx={{
                    color: "secondary.main",
                    fontSize: "1.2rem",
                    fontWeight: "500",
                    lineHeight: "normal",
                  }}
                >
                  Aman Sharma
                </Typography>
              </Box>

              <Box
                display="flex"
                alignItems="center"
                minWidth="50%"
                maxWidth="50%"
              >
                {/* <MemoryIcon sx={{ marginRight: "8px", fontSize:48 ,color: "primary.main"  }} />  */}
                <Typography
                  variant="h6"
                  sx={{
                    color: "secondary.main",
                    fontSize: "1.2rem",
                    fontWeight: "500",
                    lineHeight: "normal",
                  }}
                >
                  http://localhost:3000/bsi-co-lab-requirements
                </Typography>
              </Box>

              <Box
                display="flex"
                alignItems="center"
                minWidth="20%"
                maxWidth="20%"
              >
                {/* <MemoryIcon sx={{ marginRight: "8px", fontSize:48 ,color: "primary.main"  }} />  */}
                <Typography
                  variant="h6"
                  sx={{
                    color: "secondary.main",
                    fontSize: "1.2rem",
                    fontWeight: "500",
                    lineHeight: "normal",
                  }}
                >
                  Business User
                </Typography>
              </Box>
            </Box>

            {/* Header Icons */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "2rem",
              }}
            >
              <Box
                display="flex"
                alignItems="center"
                minWidth="30%"
                maxWidth="30%"
              >
                <img
                  src="https://bsda81.wedighq.com/frontend/img/cpu.png"
                  alt="tech"
                  style={{ width: "30px", height: "30px", marginRight: "10px" }}
                />
                <Box>
                  <Typography
                    variant="h6"
                    sx={{
                      lineHeight: "normal",
                      fontSize: "1rem",
                      fontWeight: "700",
                      color: "#073985",
                    }}
                  >
                    Tech
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{
                      marginTop: "4px",
                      fontSize: "0.8rem",
                      color: "secondary.main",
                      fontWeight: "500",
                      lineHeight: "normal",
                    }}
                  >
                    Blockchain
                  </Typography>
                </Box>
              </Box>

              <Box
                display="flex"
                alignItems="center"
                minWidth="50%"
                maxWidth="50%"
              >
                {/* <HealthAndSafetyIcon sx={{ marginRight: "8px", fontSize:48 ,color: "primary.main"  }}/> */}
                <img
                  src="https://bsda81.wedighq.com/frontend/img/industries.svg"
                  alt="health"
                  style={{ width: "30px", height: "30px", marginRight: "10px" }}
                />

                <Box>
                  <Typography
                    variant="h6"
                    sx={{
                      lineHeight: "normal",
                      fontSize: "1rem",
                      fontWeight: "700",
                      color: "#073985",
                    }}
                  >
                    Industries
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{
                      marginTop: "4px",
                      fontSize: "0.8rem",
                      color: "secondary.main",
                      fontWeight: "500",
                      lineHeight: "normal",
                    }}
                  >
                    Healthcare
                  </Typography>
                </Box>
              </Box>

              <Box
                display="flex"
                alignItems="center"
                minWidth="20%"
                maxWidth="20%"
              >
                {/* <FingerprintIcon sx={{ marginRight: "8px", fontSize:48 ,color: "primary.main"  }} />  */}
                <img
                  src="https://bsda81.wedighq.com/frontend/img/use-cases.svg"
                  alt="tech"
                  style={{ width: "30px", height: "30px", marginRight: "10px" }}
                />
                <Box>
                  <Typography
                    variant="h6"
                    sx={{
                      lineHeight: "normal",
                      fontSize: "1rem",
                      fontWeight: "700",
                      color: "#073985",
                    }}
                  >
                    Use Cases
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{
                      marginTop: "4px",
                      fontSize: "0.8rem",
                      color: "secondary.main",
                      fontWeight: "500",
                      lineHeight: "normal",
                    }}
                  >
                    Biometric
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        ) : (
          <></>
        )}

        {/* second box */}
        <Box
          sx={{
            // maxWidth: "900px",
            margin: "auto",
            width: "100%",
            padding: "2rem",
            borderRadius: "8px",
            backgroundColor: "#ffffff",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            marginBottom:'20px',
          }}
        >
          <Box
            sx={{
              display: "flex",
              // justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "2rem",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                lineHeight: "normal",
                fontSize: "1.4rem",
                fontWeight: "700",
                color: "#073985",
              }}
            >
              Business Requirement
            </Typography>
          </Box>
          <Box>
            {requirementData?.data[0]?.user_question_responses.map(
              (question, index) => {
                const options = JSON.parse(question.options[0]); // All options
                const selectedOptions = JSON.parse(question.selected_option[0]); // Only selected options

                // Filter only selected options from all available options
                const filteredOptions = options.filter((option) =>
                  selectedOptions.includes(option)
                );

                return (
                  <Box
                    key={question._id}
                    sx={{ marginBottom: "1.5rem" }}
                    color="#143f86"
                  >
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        fontWeight: "700",
                        color: "#073985",
                      }}
                    >
                      {index + 1}. {question.question}
                    </Typography>

                    {question?.question_type === "multi-select" || question?.question_type === "multi" || question?.question_type === "multi-choice" ? (
                           <FormGroup
                           sx={{
                             display: "flex",
                             flexDirection: "row",
                             gap: "1rem",
                           }}
                         >
                           {filteredOptions.map((option) => (
                             <FormControlLabel
                               key={option}
                               control={
                                 <Checkbox
                                   checked
                                   readOnly
                                   sx={{ color: "#073985" }}
                                 />
                               }
                               label={
                                 <Typography
                                   sx={{
                                     color: "#073985",
                                     fontWeight: "500",
                                   }}
                                 >
                                   {option}
                                 </Typography>
                               }
                             />
                           ))}
                         </FormGroup>
                        ) : (
                          <RadioGroup>
                          {filteredOptions.map((option) => (
                            <FormControlLabel
                              key={option}
                              value={option}
                              control={
                                <Radio
                                  sx={{ color: "#073985" }}
                                  checked
                                  readOnly
                                />
                              }
                              label={
                                <Typography
                                  sx={{
                                    color: "#073985",
                                    fontWeight: "500",
                                  }}
                                >
                                  {option}
                                </Typography>
                              }
                            />
                          ))}
                        </RadioGroup>
                        )}
                  </Box>
                );
              }
            )}
          </Box>
          <Box 
            sx={{
              display:"flex",
              justifyContent:"center",
              alignItems:"center",
              gap:"12px",
              width:"100%",
            }}
          >
            <Button
            onClick={handleUpdateRequirement}
              sx={{
                backgroundColor: "#106EED",
                color: "white",
                width:'15%',
                "&:hover": {
                  backgroundColor: "#106EED",
                  color: "white",
                },
              }}
            >
              Update
            </Button>
            <Button
              sx={{
                backgroundColor: "red.main",
                color: "white",
                width:'15%',
                "&:hover": {
                  backgroundColor: "red.main",
                  color: "white",
                },
              }}
              onClick={deleteBoardAlertHandle}
            >
              <DeleteOutlineOutlined
                        style={{
                            fontSize: '15px',
                            marginRight: "5px",
                            marginTop: "-1px"
                        }} />
              Delete
            </Button>
          </Box>
        </Box>
      </Box>
      <Modals
        open={openDeleteBoardAlert}
        handleClose={closeDeleteBoardAlert}
        modalWidth={500}
      >
        <DeleteBoardAlert
          handleClose={closeDeleteBoardAlert}
          goBackFromAlertHandle={goBackFromAlertHandle}
          showToastMessage={showToastMessage}
          currentBoardId={boardId}
          deleteTitle={"requirement"}
        />
      </Modals>
    </>
  );
};

export default BsiColabRequirement;
