import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl =process.env.REACT_APP_base_URL;

const updateSolutionDetail = createApi({
    reducerPath: 'updateSolutionDetail',
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl,
        prepareHeaders: (headers) => {
            // Add any headers you need here
            return headers;
        },
    }),
    endpoints: (builder) => ({
        updateSolutionDetail: builder.mutation({
            query: ({ userId, selectedSolutionId , formData}) => ({
                url: `solution/editSolution/${userId}/${selectedSolutionId}`,
                method: 'PUT',
                body: formData,
            }),
            transformResponse: (response) => {
                return response;
            },
        }),
    }),
});

export const { useUpdateSolutionDetailMutation } = updateSolutionDetail;
export default updateSolutionDetail;
