import { createApi , fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl = process.env.REACT_APP_base_URL;
const logoBaseUrl = 'https://bsdawebfiledata.s3.ca-central-1.amazonaws.com/solution/';
export const SolutionProviderKanbanList = createApi({
    reducerPath: 'SolutionProviderKanbanList',
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl,
        prepareHeaders:(header)=>{
            return header;
        }
    }),
    endpoints:(builder)=>({
        fetchSolutionProviderKanbanDetails: builder.query({
        query:({user_id , board_id , searchParams})=>({
            url:`requirement_boards/solution-provider/kanban/${user_id}?solution_id=${board_id}&search=${searchParams}`,
            method: 'GET',
        }),
        retry: (failureCount, error,) => {
            // Retry up to 3 times if the request fails due to network issues or server errors
            return failureCount <= 3 && (error.status === 503 || error.message.includes('Network Error'));
          },
          transformResponse: (response) => {
            if(response.status != "error"){
              const { data } = response;
              const transformSolutions = (solutions) => {
                if (solutions.status !== "error") {
                  return solutions.map((savedData) => ({
                    boardId: savedData?.board_id?._id || "",
                    boardName: savedData?.board_id?.board_name || "",
                    solution_id: savedData?._id || "",
                    date_added: savedData?.date_added || "",
                    solution_status: savedData?.solution_status || "",
                    requirementBoardId: savedData?.board_id?._id || "",
                    businessUserId:savedData?.userProfile?._id || "",
                    businessLogo:savedData?.userProfile?.business_logo || "",
                    businessName: savedData?.userProfile?.business_name || "",
                    businessUrl: savedData?.userProfile?.business_url || "",
                    designation: savedData?.userProfile?.company_role || "",
                    userId: savedData?.user_id?._id || "",
                    userName: savedData?.user_id?.first_name + " " + savedData?.user_id?.last_name || "",
                    techName: savedData?.tech_name || "",
                    industryName: savedData?.industry_name || "",
                    industryId: savedData?.industry_id?._id || [],
                    displayOrder: savedData?.industry_id?.display_order || [],
                    useCaseName: savedData?.usecase_name || "",
                    useCaseId: savedData?.use_case_id?._id || [],
                    solutionId: savedData?.solution_id?._id || [],
                    solutionName: savedData?.solution_id?.solution_name || "",
                    description: savedData?.solution_id?.slug || [],
                    solutionProviderId: savedData?.solution_id?.solution_provider_id || [],
                    logo: savedData?.solution_id?.logo
                      ? `${logoBaseUrl}${savedData.solution_id.logo}`
                      : '',
                    priceRange: savedData?.solution_id?.price_range || [],
                    sourceLink: savedData?.solution_id?.source_link || [],
                    groupChatId: savedData?.chatData?._id || []

                  }));
                } else {
                  return solutions = [];
                }
              };
        
              const invited = data.invited.length > 0 ?  transformSolutions(data.invited) : [];
              const shortlisted =  data.shortlisted.length > 0 ?  transformSolutions(data.shortlisted) : [];
              const rejected =   data.rejected.length > 0 ?  transformSolutions(data.rejected) : [];
              const selected =  data.selected.length > 0 ?  transformSolutions(data.selected) : [];
              const responded =   data.responded.length > 0 ? transformSolutions(data.responded) : [];
              return {
                boardName: data.board_name,
                invited,
                shortlisted,
                rejected,
                selected,
                responded,
              };
            }else{
              const { data } = response;

              const invited = [];
              const shortlisted =  [];
              const rejected =  [];
              const selected =  [];
              const responded = [];
              return {

                boardName: data.board_name,
                invited,
                shortlisted,
                rejected,
                selected,
                responded,
              };
            }
         
          },
        
        refetchOnWindowFocus: true,
        // retry: RetryOnError,
        onError: (error) => {
          console.error('Fetch user details error:', error);
          // Handle error cases as per your application's requirements
        },
        }),

    })
})

export const{useFetchSolutionProviderKanbanDetailsQuery} = SolutionProviderKanbanList;
export default SolutionProviderKanbanList;