import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl = process.env.REACT_APP_base_URL;

const sendFile = createApi({
  reducerPath: 'sendFile',
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers) => {
      // Add any headers you need here
      return headers;
    },
  }),
  endpoints: (builder) => ({
    sendFile: builder.mutation({
      query: ({userId , chatFormData}) => {
        return {
          url: `message/document/${userId}`, // Replace with your API endpoint URL
          method: 'POST',
          body: chatFormData,
        };
      },
      transformResponse: (response) => {
        return response;
      },
    }),
  }),
});

export const { useSendFileMutation } = sendFile;
export default sendFile;
