import React, { useState } from "react";
import ProfileStep1 from "../../commonComponents/Profile/ProfileStep1";
import UpdateBusinessProfile from "../../commonComponents/Profile/businessProfile"; // Make sure this is the correct import
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import CreateSolutionFirstStep from "./createSolutionFirstStep";
import CreateSolutionSecondStep from "./createSolutionSecondStep";

const CreateSolution = () => {
  const user = useSelector((state) => state.user.data);
  const userType = localStorage.getItem("userType");

  const showToastMessage = (message, type) => {
    if (type === "success") {
      toast.success(message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (type === "error") {
      toast.error(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const [currentStep, setCurrentStep] = useState(1);

  const nextStep = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const prevStep = () => {
    if (currentStep > 1) {
      setCurrentStep((prevStep) => prevStep - 1);
    }
  };

  const isLastStep = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  switch (currentStep) {
    case 1:
      return (
        <CreateSolutionFirstStep
          nextStep={nextStep}
          showToastMessage={showToastMessage}
        />
      );

    case 2:
      return (
        <CreateSolutionSecondStep
          prevStep={prevStep}
          isLastStep={isLastStep}
          showToastMessage={showToastMessage}
        />
      );

    default:
      return <div>Success</div>;
  }
};

export default CreateSolution;
