import React from 'react';
import styled, { keyframes } from 'styled-components';

const loadingAnimation = keyframes`
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
`;

// Adjusted Loader positioning
const ChatLoader = styled.div`
  position: relative; // Keep it absolute to position relative to AppBar
  bottom: 0; // Align to the bottom of the AppBar
  left: 0;
  width: 100%; // Match the width of the AppBar
  height: 4px;
  background: linear-gradient(90deg, #007bff, #00d4ff);
  background-size: 200% 100%;
  animation: ${loadingAnimation} 2s infinite;
`;

const ChatBoxLoader = () => {
  return (
    <ChatLoader/>
  );
};

export default ChatBoxLoader;

