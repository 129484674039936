import { fetchBaseQuery, createApi } from '@reduxjs/toolkit/query/react';

const baseUrl = process.env.REACT_APP_base_URL;

export const getExistingSolutionName  = createApi({
    reducerPath: 'existingSolutionName',
    baseQuery: fetchBaseQuery({
      baseUrl: baseUrl,
      prepareHeaders: (headers) => {
        // Add any headers you need here
        return headers;
      },
    }),
    endpoints: (builder) => ({
      fetchExistingSolutionName: builder.query({
        query: ({ solutionId , solutionName}) => ({
            url: `solution/check_solution/${
                solutionName ? `${solutionName}` : ''
              }${solutionId && solutionName ? '?' : ''}${
                // solutionName ? `solution_name=${solutionName}` : ''
                solutionId ? `solution_id=${solutionId}` : ''
              }`,
          //  url:    `solution/check_solution/${solutionName}`,
           method: 'GET',
        }),
        transformResponse: (response) => {
           return response;
        },
        refetchOnWindowFocus: true,
        // retry: RetryOnError,
        onError: (error) => {
          console.error('Fetch solution name  error:', error);
          // Handle error cases as per your application's requirements
        },
      })
    })
})


export const { useFetchExistingSolutionNameQuery } = getExistingSolutionName;
