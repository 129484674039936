// profileSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { setSolutionName } from "../chatSlice";
import shortid from "shortid";

const initialState = {
  solutionName: "",
  solutionTagline: "",
  solutionWebUrl: "",
  headquarter: "",
  industry: [],
  tech: [],
  useCase: [],
  avatar: null, // For storing avatar file
  selectedAvatar: null, // For storing avatar metadata
  priceRange: "",
  solutionBenefits: [],
  solutionKeyFeatures: [],
  shortOverview : '',
  overview: '' ,
  trailAvailability: '',
  solutionNameError: null,
  checkSolutionName: ""
};

const createSolutionSlice = createSlice({
  name: "createSolution",
  initialState,
  reducers: {
    setAvatar(state, action) {
      state.avatar = action.payload;
    },
    setSelectedAvatar(state, action) {
      state.selectedAvatar = action.payload; // Replace with the new file metadata
    },
    setNewSolutionName: (state, action) => {
      state.solutionName = action.payload;
    },
    setSolutionTagline: (state, action) => {
      state.solutionTagline = action.payload;
    },
    setSolutionWebUrl: (state, action) => {
      state.solutionWebUrl = action.payload;
    },
    setHeadquarter: (state, action) => {
      state.headquarter = action.payload;
    },
    setIndustry: (state, action) => {
      state.industry = action.payload;
    },
    setTech: (state, action) => {
      state.tech = action.payload;
    },
    setUseCase: (state, action) => {
      state.useCase = action.payload;
    },
    setPriceRange: (state, action) => {
      // New reducer to set price range
      state.priceRange = action.payload;
    },

    setShortOverview: (state, action) => {
      state.shortOverview = action.payload;
    },
    setOverview: (state, action) => {
      state.overview = action.payload;
    },
    setTrialAvailability: (state, action) => {
      state.trailAvailability = action.payload;
    },
    // Add a new benefit to the array
    addSolutionBenefit: (state) => {
      if (!state.solutionBenefits) {
        state.solutionBenefits = []; // Ensure it's an array
      }
      state.solutionBenefits.push({ id: shortid.generate(), value: "" });
    },
    removeSolutionBenefit: (state, action) => {
      state.solutionBenefits = state.solutionBenefits.filter(
        (benefit) => benefit.id !== action.payload
      );
    },
    updateSolutionBenefit: (state, action) => {
      const { id, value } = action.payload;
      const index = state.solutionBenefits.findIndex((benefit) => benefit.id === id);
      if (index !== -1) state.solutionBenefits[index].value = value;
    },
     // Add a new feature to the array
     addSolutionKeyFeature: (state) => {
      if (!state.solutionKeyFeatures) {
        state.solutionKeyFeatures = []; // Ensure it's an array
      }
      state.solutionKeyFeatures.push({ id: Date.now(), value: "" });
    },
    removeSolutionKeyFeature: (state, action) => {
      state.solutionKeyFeatures = state.solutionKeyFeatures.filter(
        (feature) => feature.id !== action.payload
      );
    },
    // Update a specific feature based on its id
    updateSolutionKeyFeature: (state, action) => {
      const { id, value } = action.payload;
      const index = state.solutionKeyFeatures.findIndex((feature) => feature.id === id);
      if (index !== -1) state.solutionKeyFeatures[index].value = value;
    },
    setSolutionNameError: (state, action) => {
      state.solutionNameError = action.payload;
    },
    setCheckSolutionName: (state, action) => {
      state.checkSolutionName = action.payload;
    },
    
    // If you want to reset all fields
    resetProfile: (state) => {
      state.solutionName= "",
      state.solutionTagline= "",
      state.solutionWebUrl= "",
      state.headquarter= "",
      state.industry= [],
      state.tech= [],
      state.useCase= [],
      state.avatar= null, // For storing avatar file
      state.selectedAvatar= null, // For storing avatar metadata
      state.priceRange= "",
      state.solutionBenefits= [],
      state.solutionKeyFeatures= [];
      state.shortOverview = '',
      state.overview= '' ,
      state.trailAvailability= '',
      state.solutionNameError=null,
      state.checkSolutionName = ""
    }
  }
});

export const {
  setAvatar,
  setSelectedAvatar,
  setNewSolutionName,
  setSolutionTagline,
  setSolutionWebUrl,
  setHeadquarter,
  setIndustry,
  setTech,
  setUseCase,
  setPriceRange,
  resetProfile,
  setShortOverview,
  setOverview,
  setTrialAvailability,
  addSolutionBenefit,
  updateSolutionBenefit,
  removeSolutionBenefit,
  addSolutionKeyFeature,
  removeSolutionKeyFeature,
  updateSolutionKeyFeature,
  setSolutionNameError,
  setCheckSolutionName
} = createSolutionSlice.actions;
export default createSolutionSlice.reducer;
