import SvgIcon from "@mui/material/SvgIcon";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import { styled } from "@mui/material/styles";
// import { ReactComponent as DrawIcon } from '../../assets/dashboard/img/draw-dynamic.svg'
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import { Typography } from "@mui/material";
import { getImages } from "../const";

const AvatarWithUpload = ({previewImage , handleFileChange}) => {
  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  return (
    <Box>
      {/* <Box>
        <Typography
                  noWrap
                  variant="body2"
                  sx={{
                    fontSize: "12px",
                    fontWeight: "500",
                    color: "ltheme.main",
                    width: "100%",
                    // mb: 0.5,
                  }}
                >
                  Business Logo
                </Typography>
        </Box> */}
      <Box
        sx={{
          position: "relative",
          "& .MuiButtonBase-root": {
            mb: 0,
          },
        }}
      >
        <Avatar
          alt="logo"
          // src={getImages("companyLogo.jfif")}
          src={previewImage}
          slotProps={{
            img: {
              sx: {
                maxWidth: "100%",
                maxHeight: "100%",
              },
            },
          }}
          sx={{ width: 150, height: 150 }}
        />
        <IconButton
          component="label"
          sx={{
            boxShadow: "0 0 5px rgb(0 0 0 / 20%)",
            p: "7px",
            position: "absolute",
            // right: props.isCompanyLogo ? '-10px' : '0px',
            right: "0px",
            bottom: "0px",
            // bottom: props.isCompanyLogo ? '-10px' : '0px',
            margin: "0px",
            backgroundColor: "common.white",
            "&:hover": {
              backgroundColor: "common.white",
            },
          }}
          tabIndex={-1}
          size="small"
        >
          <SvgIcon
            sx={{
              width: "16px",
              height: "16px",
              color: "common.black",
            }}
            viewBox="0 0 32 32"
            component={EditIcon}
          />
          <VisuallyHiddenInput 
          type="file"
          onChange={handleFileChange}
          />
        </IconButton>
      </Box>
    </Box>
  );
};

export default AvatarWithUpload;
