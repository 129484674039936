import React, { useEffect, useState } from "react";
import {
  Box,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Switch,
  FormControlLabel,
  IconButton,
  Typography,
  Autocomplete,
  Chip,
  ListItemAvatar,
  Avatar,
  Stack,
  Checkbox,
  ListItemText,
  Grid,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { Add, MeetingRoomOutlined, VideoCall } from "@mui/icons-material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import SubjectIcon from "@mui/icons-material/Subject";

// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import "../../styles/customDatePickerStyles.css";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import CloseIcon from "@mui/icons-material/Close";
import dayjs from "dayjs";
import TitleIcon from "@mui/icons-material/Title";
import { useFetchInboxRequirementBoardDetailsQuery } from "../../redux/api/Inbox/getInboxRequirementBoard";
import { useFetchInboxSelectedSolutionListQuery } from "../../redux/api/Inbox/getInboxSelectedSolutionList";
import { useFetchUserListQuery } from "../../redux/api/Inbox/getUserList";
import createMeetingApi, {
  useCreateMeetingMutation,
} from "../../redux/api/Meetings/createMeetings";
import { hideLoader, showLoader } from "../../redux/features/loader";
import { useDispatch } from "react-redux";
import {
  DateField,
  DatePicker,
  LocalizationProvider,
  MobileTimePicker,
  TimeField,
  TimePicker,
} from "@mui/x-date-pickers";
import { useTheme } from "@emotion/react";
import { setRefetchMeetingList } from "../../redux/features/refetch";
import { useFetchMeetingUserListQuery } from "../../redux/api/Meetings/getMeetingUserList";
import { useFetchCurrentMeetingDetailQuery } from "../../redux/api/Meetings/currentMeetingDetail";

const useStyles = makeStyles({
  datePicker: {
    "& > div.MuiInputBase-root > input": {
      padding: "10px 0 10px 14px !important",
    },
  },
});

const ScheduleMeetingModal = ({ showToastMessage, handleClose }) => {
  const theme = useTheme();
  const classes = useStyles();
  const userId = localStorage.getItem("userId");
  const dispatch = useDispatch();
  const [title, setTitle] = React.useState("");
  const [guests, setGuests] = React.useState([]);
  const [timeZone, setTimeZone] = React.useState("");
  const [location, setLocation] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [notify, setNotify] = React.useState(true);
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [startTime, setStartTime] = useState(dayjs());
  const [endTime, setEndTime] = useState(dayjs().add(1, "hour"));
  const [selectedBoard, setSelectedBoard] = useState("");
  const [selectedBoardId, setSelectedBoardId] = useState("");
  const [selectedSolutionId, setSelectedSolutionId] = useState("");
  const [selectedSolution, setSelectedSolution] = useState("Select Solution");
  const [meetingUrl, setMeetingUrl] = useState("");
  const [wordLimitExceeded, setWordLimitExceeded] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [errors, setErrors] = useState({
    title: false,
    meetingUrl: false,
    users: false,
  });
  const handleBoardSelection = (event) => {
    setSelectedBoardId(event.target.value);
  };
  const handleSolutionSelection = (event) => {
    setSelectedSolutionId(event.target.value);
    refetchUserList();
  };
  const handleTitleChange = (event) => {
    setTitle(event.target.value);

    // Clear title error when the field is not empty
    if (event.target.value !== "") {
      setErrors((prevErrors) => ({ ...prevErrors, title: false }));
    }
  };

  const handleStartTimeChange = (date) => {
    setStartTime(date);
    setEndTime(date.add(1, "hour"));
  };
  const handleMeetingUrl = (event) => {
    setMeetingUrl(event.target.value);
    // Clear URL error when the field is not empty
    if (event.target.value !== "") {
      setErrors((prevErrors) => ({ ...prevErrors, meetingUrl: false }));
    }
  };
  const handleDescription = (event) => {
    const inputText = event.target.value;
    const characterCount = inputText.length; // Count characters
  
    if (characterCount <= 160) {
      setDescription(inputText);
      setWordLimitExceeded(false);
    } else {
      setWordLimitExceeded(true);
    }
  };

  const formatDate = (date) => {
    return dayjs(date).format("YYYY-MM-DD");
  };

  const formatTime = (time) => {
    return dayjs(time).format("hh:mm A");
  };


  // Handle chip deletion

  const handleDelete = (userId) => {
    setSelectedUsers((prev) => prev.filter((id) => id !== userId));
  };

  const handleUserSelection = (event, value) => {
    // Filter out the "No user available" from selected values
    const filteredValue = value.filter(
      (user) => user.first_name !== "No user"
    );
    
    // Set the filtered value as selected users
    setSelectedUsers(filteredValue);
  
    // Remove error if the selection is valid
    if (filteredValue.length > 0) {
      setErrors((prevErrors) => ({ ...prevErrors, users: false }));
    }
  };


  const {
    data: userList,
    isLoading: userDetailLoading,
    error: userDetailError,
    refetch: refetchUserList,
  } = useFetchMeetingUserListQuery(userId);

  const [createMeeting, { isLoading, isError }] = useCreateMeetingMutation();
  const scheduleMeeting = async () => {
    let hasError = false;
    const newErrors = { title: false, meetingUrl: false, users: false };

    if (title === "") {
      newErrors.title = true;
      hasError = true;
      document.querySelector("#meetingTitleField").focus();
    }

    if (meetingUrl === "") {
      newErrors.meetingUrl = true;
      hasError = true;
      document.querySelector("#meetingUrlField").focus();
    }

    if (!selectedUsers || selectedUsers.length === 0) {
      newErrors.users = true;
      hasError = true;
      document.querySelector("#controlled-demo").focus();
    }

    if (hasError) {
      setErrors(newErrors);
      return;
    }
    try {
      const meetingDate = formatDate(selectedDate);
      const formattedStartTime = formatTime(startTime);
      const formattedEndTime = formatTime(endTime);
      const response = await createMeeting({
        userId,
        meetingTitle: title,
        meetingDate,
        meetingStartTime: formattedStartTime,
        meetingEndTime: formattedEndTime,
        participantsUserIds: selectedUsers.map((user) => user?._id),
        meetingLink: meetingUrl,
        description: description,
      }).unwrap();
      if (response.statusCode === 200) {
        showToastMessage("Meeting scheduled", "success");
        dispatch(setRefetchMeetingList(true));
      } else if (response.errorType === "Conflict") {
        showToastMessage("Slot is already booked", "info");
      } else {
        showToastMessage("Failed to schedule meeting", "error");
      }
      handleClose();
    } catch (error) {
      showToastMessage("Failed to schedule meeting", "error");
    } finally {
      // Hide the loader when the API call is finished, regardless of the outcome
      dispatch(hideLoader());
    }
  };

  useEffect(() => {
    if (isLoading) {
      dispatch(showLoader());
    } else {
      dispatch(hideLoader());
    }
  }, [isLoading, dispatch]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
      <Typography
        sx={{
          fontSize: "1.8rem",
          fontWeight: "700",
          color: "#073985",
          [theme.breakpoints.down("md")]: {
            fontSize: "1.2rem",
            maxWidth: "220px",
          },
          [theme.breakpoints.down("sm")]: {
            maxWidth: "180px",
          },
        }}
        variant="h4"
        noWrap
        component="div"
      >
        Schedule Meeting
      </Typography>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
      <TextField
        id="meetingTitleField"
        fullWidth
        required
        label="Meeting title"
        value={title}
        variant="standard"
        onChange={handleTitleChange}
        // sx={{ marginBottom: 2 }}
      />
        {errors.title && (
          <Typography
            variant="body2"
            color="error"
            sx={{
              display: "flex",
              ml: "0px",
            }}
          >
            Please add meeting title...
          </Typography>
        )}
      </Box>
      {/* Meeting Time */}
      <Grid
        xs={12}
        container
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "11px",
          // width: 400,
          // marginLeft: "2.8vh",
        }}
      >
        {/* Date Picker */}
        <Grid
          item
          xs={12}
          sm={4.5}
          className="customDatePickerContainer"
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              value={selectedDate}
              label="Select Date"
              disablePast
              onChange={(date) => setSelectedDate(date)}
              className={`${classes.datePicker} selectDate`}
            />
          </LocalizationProvider>
        </Grid>

        {/* Start Time */}
        <Grid
          item
          xs={5}
          sm={3}
          className="customDatePickerContainer"
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MobileTimePicker
              label="Start Time"
              value={startTime}
              onChange={handleStartTimeChange}
              className={`${classes.datePicker} selectDate`}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item sx={{ fontSize: "20px" }}>
          -
        </Grid>
        {/* End Time */}
        <Grid
          item
          xs={5}
          sm={3}
          className="customDatePickerContainer"
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MobileTimePicker
              label="End Time"
              value={endTime}
              onChange={(date) => setEndTime(date)}
              className={`${classes.datePicker} selectDate`}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>

      {/* set requirement boards */}
      {/* <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "11px",
          width: "100%",
          // marginLeft: "2.8vh",
        }}
      >
      </Box> */}
      {/* add member */}
      <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          gap: "11px",
        }}
      >
        <PersonAddAltIcon sx={{ marginTop: "1.1rem" }} />
        <FormControl
          variant="standard"
          fullWidth
          sx={{
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
          }}
        >
          <Autocomplete
            multiple
            id="controlled-demo"
            value={selectedUsers}
            onChange={handleUserSelection}
            options={
              userList?.data && userList?.data?.length > 0
                ? userList?.data.map((user, index) => {
                    return user?.provider;
                  })
                : [
                    {
                      _id: "",
                      email: "No user available",
                      first_name: "No user",
                      last_name: "available",
                    },
                  ]
            }
            isOptionDisabled={(option) => option.first_name === "No user"}
            getOptionLabel={(option) =>
              option?.first_name + " " + option?.last_name
            }

            renderInput={(params) => (
              <TextField
                {...params}
                required
                label="Select Users"
                variant="standard"
              />
            )}
            sx={{
              // minWidth: '20px',
              "& .MuiAutocomplete-inputRoot": {
                paddingRight: '0px !important', // Override the default padding
              },
              "& .MuiAutocomplete-tag": {
                marginRight: '5px', // Adjust margin between chips if needed
              },
              "& .MuiAutocomplete-clearIndicator": {
                display:'none' // Adjust position of clear icon if needed
              },
              "& .MuiAutocomplete-popupIndicator": {
                right: '5px', // Adjust position of popup icon if needed
              },
              maxHeight:"100px",
              overflowY: 'auto',
              "&::-webkit-scrollbar": {
                opacity: 0,
                width: "6px",
              },
              "s:hover": {
                "&::-webkit-scrollbar": {
                  width: "6px",
                  borderRadius: "4px",
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "transparent",
                  borderRadius: "4px",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#9C9C9C",
                  borderRadius: "4px",
                },
              },
              
            }}
          />
        </FormControl>
      </Box>
      {errors.users && (
          <Typography
            variant="body2"
            color="error"
            sx={{
              display: "flex",
              ml: "35px",
            }}
          >
            Please add at least one participant...
          </Typography>
        )}
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
      <Box sx={{ display: "flex", alignItems: "center", gap: "11px" }}>
        <VideoCall sx={{ marginTop: "20px" }} />
        <Box sx={{ width: 400 }}>
          <TextField
            id="meetingUrlField"
            fullWidth
            required
            label="Add Meeting Url"
            value={meetingUrl}
            variant="standard"
            onChange={handleMeetingUrl}
            // margin="normal"
          />
        </Box>
      </Box>
      {errors.meetingUrl && (
          <Typography
            variant="body2"
            color="error"
            sx={{
              display: "flex",
              ml: "35px",
            }}
          >
            Please add meeting url...
          </Typography>
        )}
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
        <Box sx={{ display: "flex", alignItems: "center", gap: "11px" }}>
          <SubjectIcon sx={{ marginTop: "1rem" }} />
          <TextField
            fullWidth
            label="Add description"
            variant="standard"
            value={description}
            onChange={handleDescription}
            error={wordLimitExceeded}
            // inputProps={{ spellCheck: "false" }}
          />
        </Box>
        {wordLimitExceeded && (
          <Typography
            variant="body2"
            color="error"
            sx={{
              display: "flex",
              justifyContent: "center",
              ml: "20px",
              alignItems: "center",
            }}
          >
            Word limit exceeded. Please limit your description to 160 characters.
          </Typography>
        )}
        {/* <Typography variant="caption" color={wordLimitExceeded ? "error" : "textSecondary"}>
      {`${description.split(/\s+/).filter(Boolean).length}/10 words`}
    </Typography> */}
      </Box>
      <Button
        variant="contained"
        color="primary"
        sx={{ 
          mt: 2,
          "&.Mui-disabled": {
          backgroundColor: "primary.main", // Change this to the desired color
          color: "white", // Maintain text color
          opacity: 1, // Keep the button fully opaque
          boxShadow: "none", // No shadow for the disabled state
        },
         }}
        onClick={() => scheduleMeeting()}
        disabled={isLoading}
      >
        Schedule Meeting
      </Button>
    </Box>
  );
};

export default ScheduleMeetingModal;
