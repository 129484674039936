import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  whitelist: [
    'drawer',
    'inbox', 
    'businessUserRequirement', 
    'viewRequirementSlice' , 
    'refetch', 
    'inviteSolutionProviderSlice' ,
    'chat' ,
     'createSolution',
     'editSolution'
  ], // Define which reducers to persist
}

export default persistConfig