import { fetchBaseQuery, createApi } from '@reduxjs/toolkit/query/react';


const baseUrl = process.env.REACT_APP_base_URL;

export const getBusinessUserRequirement  = createApi({
    reducerPath: 'getBusinessUserRequirement',
    baseQuery: fetchBaseQuery({
      baseUrl: baseUrl,
      prepareHeaders: (headers) => {
        // Add any headers you need here
        return headers;
      },
    }),
    endpoints: (builder) => ({
      fetchBusinessUserRequirement: builder.query({
        query: ({ userId, boardId , solutionRequirementId}) => ({
          url: `requirement_boards/solution-provider/view-requirement/${userId}?board_id=${boardId}&solutionRequirementId=${solutionRequirementId}`,
          method: 'GET',
        }),
        transformResponse: (response) => {
          if(response.status != "error"){
            return response.data
          }else{
           return  response = [];
          }
        },
        refetchOnWindowFocus: true,
        // retry: RetryOnError,
        onError: (error) => {
          console.error('Fetch business user requirement error:', error);
          // Handle error cases as per your application's requirements
        },
      })
    })
})


export const { useFetchBusinessUserRequirementQuery } = getBusinessUserRequirement;
export default getBusinessUserRequirement;