import { useMediaQuery, useTheme } from '@mui/material';

const useBreakpoint = () => {
  const theme = useTheme();

  const isXl = useMediaQuery(theme.breakpoints.up('xl'));
  const isLg = useMediaQuery(theme.breakpoints.between('lg', 'xl'));
  const isMd = useMediaQuery(theme.breakpoints.between('md', 'lg'));
  const isSm = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isXs = useMediaQuery(theme.breakpoints.down('sm'));

  let breakpoint = 'xs'; // Default to smallest

  if (isXl) breakpoint = 'xl';
  else if (isLg) breakpoint = 'lg';
  else if (isMd) breakpoint = 'md';
  else if (isSm) breakpoint = 'sm';

  return { breakpoint, isXs, isSm, isMd, isLg, isXl };
};

export default useBreakpoint;
