import { createSlice } from "@reduxjs/toolkit";

const inboxSlice = createSlice({
    name :'inbox',
    initialState: {
        boardId: "",
        boardName: "View Requirement Boards",
        solutionId: "",
        solutionName: "View Solutions",
    },
    reducers:{
        getBoardId: (state , action)=> {
          state.boardId =  action.payload
        },
        getBoardName: (state , action)=> {
          state.boardName =  action.payload
        },
        clearBoardData: (state) => {
            state.boardId = null;
            state.boardName = null;
          },
        getSolutionId: (state, action)=>{
          state.solutionId = action.payload
        },  
        getSolutionName: (state, action)=>{
          state.solutionName = action.payload
        },  
    },
});

export const {getBoardId , getBoardName , clearBoardData, getSolutionId , getSolutionName} = inboxSlice.actions;
export default inboxSlice.reducer;