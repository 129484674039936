import { createSlice } from "@reduxjs/toolkit";

const businessUserRequirementSlice = createSlice({
    name :'businessUserRequirement',
    initialState: {
        boardId: "",
        boardName: "",
        solutionRequirementId: "",
        businessUserId: "",
    },
    reducers:{
        getBoardIdForBusinessUserRequirement: (state , action)=> {
          state.boardId =  action.payload
        },
        getBoardNameForBusinessUserRequirement: (state , action)=> {
          state.boardName =  action.payload
        },
        getSolutionRequirementIdForBusinessUserRequirement: (state, action)=>{
          state.solutionRequirementId = action.payload
        },  
        getBusinessUserIdForBusinessUserRequirement: (state, action)=>{
          state.businessUserId = action.payload
        },  
    },
});

export const {getBoardIdForBusinessUserRequirement , getBoardNameForBusinessUserRequirement , getSolutionRequirementIdForBusinessUserRequirement, getBusinessUserIdForBusinessUserRequirement } = businessUserRequirementSlice.actions;
export default businessUserRequirementSlice.reducer;