import React, { useEffect, useRef, useState } from "react";
import {
  Avatar,
  AvatarGroup,
  Badge,
  Box,
  Button,
  Grid,
  IconButton,
  List,
  ListItemButton,
  Stack,
  styled,
  InputBase,
  Typography,
  ListItemText,
  Modal,
  Tooltip,
  Paper,
  Menu,
  MenuItem,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import MainCard from "../../commonComponents/MainCard";
import { getImages } from "../../commonComponents/const";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Chip from "@mui/material/Chip";
import SearchIcon from "@mui/icons-material/Search";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";

import Modals from "../../commonComponents/Modals";
import DeleteBoardAlert from "../../commonComponents/Kanban/DeleteBoardAlert";
import { useDispatch, useSelector } from "react-redux";
import { useFetchRequirementBoardDetailsQuery } from "../../redux/api/Requirement Boards/getRequirementBoardDetail";
import { useUpdateSolutionStatusMutation } from "../../redux/api/Requirement Boards/updateSolutionStatus";
import {
  addToInvited,
  addToRejected,
  addToResponded,
  addToSelected,
  addToShortlisted,
  getBoardDetail,
  setInvited,
} from "../../redux/features/kanbanSlice";
import BoardSetting from "../../commonComponents/Kanban/BoardSetting";
import { toast } from "react-toastify";
import CustomLoader from "../../utils/Loader/CustomLoader";
import { hideLoader, showLoader } from "../../redux/features/loader";
import { resetRefetchKanbanBoard } from "../../redux/features/refetch";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  getChatId,
  getGroupName,
  getSolutionLogo,
  setSolutionId,
  setSolutionName,
  toggleGroupSelected,
} from "../../redux/features/chatSlice";
import { getBoardIdForInviteSolutionProvider } from "../../redux/features/BusinessUser/inviteSolutionProvider";
import { getBoardId, getBoardName } from "../../redux/features/inboxSlice";
const Kanban = () => {
  // Open Other Options
  const currentStatus = useRef();
  const userType = localStorage.getItem("userType");
  const [anchorElOtherOptions, setAnchorElOtherOptions] = useState(null);
  const openOtherOptions = Boolean(anchorElOtherOptions);
  const [status, setStatus] = useState("");
  const [currentMenuId, setCrurrentMenuId] = useState("");
  const handleOtherOptionsClick = (event) => {
    setAnchorElOtherOptions(event.currentTarget);
  };
  const handleOtherOptionsClose = () => {
    setAnchorElOtherOptions(null);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openDw = Boolean(anchorEl);
  const handleClick = (event, id, status) => {
    setCrurrentMenuId(id);
    setAnchorEl(event.currentTarget);
    setStatus(status);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [anchorElStatus, setAnchorElStatus] = React.useState(null);
  const openStatus = Boolean(anchorElStatus);
  const handleStatusClick = (event, id, status) => {
    event.stopPropagation();
    setAnchorElStatus(event.currentTarget);
    setStatus(status);
  };
  const handleStatusClose = () => {
    setAnchorElStatus(null);
  };

  // Styling
  const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "#ECF6FF",
    "&:hover": {
      backgroundColor: "#ECF6FF",
    },
    marginRight: "0",
    marginLeft: 0,
    marginBottom: 5,
    width: "100%",
    display: "flex",
    alignItems: "center",
  }));
  const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    right: "0",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }));

  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "#106EED",
    flexBasis: "90%", // Occupy 70% of the search box
    "& .MuiInputBase-input": {
      padding: theme.spacing(1.7, 0, 1.7, 1.7),
      paddingRight: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create("width"),
      width: "100%",
      fontSize: "12px",
      fontWeight: "500",
    },
  }));
  const theme = useTheme();
  const location = useLocation();
  const dispatch = useDispatch();

  // toast messages
  const showToastMessage = (message, type) => {
    if (type === "success") {
      toast.success(message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (type === "error") {
      toast.error(message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (type === "info") {
      toast.warning(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  // getting board_id from localStorage
  const boardId = localStorage.getItem("boardId");
  const boardName = localStorage.getItem("boardName");
  const industryName = localStorage.getItem("industry");
  // getting userId from localStorage
  const userId = localStorage.getItem("userId");
  // set search params
  const [searchParams, setSearchParams] = useState("");
  const [searchValue, setSearchValue] = useState("");

  // apiCall
  const {
    data: boardDetail,
    error: boardDetailError,
    isLoading: boardDetailLoading,
    refetch,
  } = useFetchRequirementBoardDetailsQuery({
    user_id: userId,
    board_id: boardId,
    searchParams: searchValue,
  });

  // set refetchKanban in reduxStore
  const refetchKanbanBoard = useSelector(
    (state) => state.refetch.refetchKanbanBoard
  );
  useEffect(() => {
    if (refetchKanbanBoard) {
      refetch().finally(() => {
        dispatch(resetRefetchKanbanBoard());
      });
    }
  }, [refetchKanbanBoard, refetch, dispatch]);

  const [updateSolutionStatus, { isLoading: statusLoading }] =
    useUpdateSolutionStatusMutation();
  // search board on keyboard enter
  const handleInputChange = (event) => {
    setSearchParams(event.target.value);
  };

  const handleSearchSolutionProvider = async (event) => {
    if (event.key === "Enter") {
      const inputValue = event.target.value.trim();
      if (!inputValue) {
        setSearchValue(""); // Reset the searchValue state
        await refetch();
        showToastMessage("Please enter a search term", "info");
        return;
      }
      setSearchValue(inputValue);
      dispatch(showLoader());
      try {
        // Await refetch and handle search results
        await refetch();
        if (boardDetailError) {
          const errorMessage = boardDetail?.message;
          showToastMessage(errorMessage, "error");
        }
      } catch (boardError) {
        // Handle errors if needed
        showToastMessage("An error occurred during search", "error");
      } finally {
        // Hide loader after refetch is complete
        dispatch(hideLoader());
      }
    }
  };
  const handleClearSearch = () => {
    setSearchParams("");
    setSearchValue(""); // Reset search value to empty
    refetch(); // Refetch all data
  };

  // Drag and Drop logic
  const dragItem = useRef();
  const dragOverItem = useRef();
  const { open } = useSelector((state) => state.drawer);
  const Invited = useSelector((state) => state.kanban.invited);
  const Shortlisted = useSelector((state) => state.kanban.shortlisted);
  const Rejected = useSelector((state) => state.kanban.rejected);
  const Selected = useSelector((state) => state.kanban.selected);
  const Responded = useSelector((state) => state.kanban.responded);

  const dragStart = (e, listId) => {
    dragItem.current = { id: e.target.id, listId };
  };

  const dragEnter = (e, listId) => {
    dragOverItem.current = { id: e.currentTarget.id, listId };
  };
  const dragOver = (e) => {
    e.preventDefault(); // Prevent the default drag behavior
  };

  const getItemContent = (listId, index) => {
    switch (listId) {
      case "Invited":
        return Invited[index];
      case "Shortlisted":
        return Shortlisted[index];
      case "Rejected":
        return Rejected[index];
      case "Selected":
        return Selected[index];
      case "Responded":
        return Responded[index];
      default:
        return null;
    }
  };

  const getItemsArray = (listId) => {
    switch (listId) {
      case "Invited":
        return Invited;
      case "Shortlisted":
        return Shortlisted;
      case "Rejected":
        return Rejected;
      case "Selected":
        return Selected;
      case "Responded":
        return Responded;
      default:
        return [];
    }
  };

  const setItemsArray = (listId, items) => {
    switch (listId) {
      case "Invited":
        dispatch(setInvited(items));
        break;
      case "Shortlisted":
        dispatch(addToShortlisted(items));
        break;
      case "Rejected":
        dispatch(addToRejected(items));
        break;
      case "Selected":
        dispatch(addToSelected(items));
        break;
      case "Responded":
        dispatch(addToResponded(items));
        break;
      default:
        break;
    }
  };
  const statusMapping = {
    Invited: "Invited",
    Shortlisted: "Shortlisted",
    Rejected: "Rejected",
    Selected: "Selected",
    Responded: "Responded",
  };

  // Combine all lists into a single object
  const allLists = {
    Invited,
    Shortlisted,
    Rejected,
    Selected,
    Responded,
  };

  // Find the maximum length among all lists
  const maxLength = Math.max(
    ...Object.values(allLists)
      .filter((list) => list && list.length > 0)
      .map((list) => list.length),
    0
  );

  // Sort the statuses alphabetically
  const sortedStatuses = Object.keys(statusMapping).sort();

  const drop = async () => {
    try {
      if (dragItem.current !== null && dragOverItem.current !== null) {
        const dragItemIndex = parseInt(dragItem.current.id);
        const dragOverItemIndex = parseInt(dragOverItem.current.id);
        const dragItemContent = getItemContent(
          dragItem.current.listId,
          dragItemIndex
        );

        if (dragItem.current.listId === dragOverItem.current.listId) {
          // Dropping within the same list
          const copyDragListItems = [...getItemsArray(dragItem.current.listId)];
          copyDragListItems.splice(dragItemIndex, 1);
          copyDragListItems.splice(dragOverItemIndex, 0, dragItemContent);
          setItemsArray(dragItem.current.listId, copyDragListItems);
        } else {
          // Dropping onto a different list
          const copyDragListItems = [...getItemsArray(dragItem.current.listId)];
          const copyDragOverListItems = [
            ...getItemsArray(dragOverItem.current.listId),
          ];
          copyDragListItems.splice(dragItemIndex, 1);
          copyDragOverListItems.splice(dragOverItemIndex, 0, dragItemContent);
          setItemsArray(dragItem.current.listId, copyDragListItems);
          setItemsArray(dragOverItem.current.listId, copyDragOverListItems);

          // Determine the new status based on the listId of the destination
          const newStatus = statusMapping[dragOverItem.current.listId];
          // Call the API to update the status
          await updateSolutionStatus({
            id: dragItemContent.id,
            status: newStatus,
            userId: userId,
          });
        }
      }
    } catch (error) {
      console.error("Error during drop operation:", error);
    }
  };

  const handleInvite = (itemId, status) => {
    setStatus(status);
    updateSolutionStatus({
      id: currentMenuId,
      status: status,
      userId: userId,
    }).then(() => {
      refetch();
      handleStatusClose();
      handleClose();
    });
  };

  // list1Settings Modal
  const [openBoardSettings, setOpenBoardSettings] = useState(false);
  const boardSettingsHandle = () => {
    setOpenBoardSettings(true);
  };
  const closeBoardSettings = () => {
    setOpenBoardSettings(false);
  };
  // Delete list1Alert Modal
  const [openDeleteBoardAlert, setOpenDeleteBoardAlert] = useState(false);
  const deleteBoardAlertHandle = () => {
    setOpenDeleteBoardAlert(true);
    setOpenBoardSettings(false);
  };
  const closeDeleteBoardAlert = () => {
    setOpenDeleteBoardAlert(false);
  };
  const goBackFromAlertHandle = () => {
    setOpenDeleteBoardAlert(false);
    setOpenBoardSettings(true);
  };

  // get require solutions details in reduxStore when api calls
  useEffect(() => {
    if (boardDetail) {
      const boardData = boardDetail.invited.map((item) => ({
        boardName: item.boardName,
        industryId: item.industryId,
        useCaseId: item.useCaseId,
      }));
      const mapData = (data) =>
        data.map((item) => ({
          id: item.solution_id,
          boardName: item.boardName,
          logo: item.logo,
          name: item.solutionName,
          date: item.date_added,
          msg: item.displayOrder,
          url: item.sourceLink,
          status: item.solution_status,
          userId: item.userId,
          solutionId: item.solutionId,
          solutionName: item.solutionName,
          solutionProviderId: item.solutionProviderId,
          groupChatId: item.groupChatId,
        }));

      const invitedData = mapData(boardDetail.invited);
      const shortlistedData = mapData(boardDetail.shortlisted);
      const rejectedData = mapData(boardDetail.rejected);
      const selectedData = mapData(boardDetail.selected);
      const respondedData = mapData(boardDetail.responded);

      dispatch(setInvited(invitedData));
      dispatch(addToShortlisted(shortlistedData));
      dispatch(addToRejected(rejectedData));
      dispatch(addToSelected(selectedData));
      dispatch(addToResponded(respondedData));
      localStorage.setItem("boardName", boardDetail?.boardName);
      localStorage.setItem("boardDetail", JSON.stringify(boardData));
    }
  }, [boardDetail, dispatch]);

  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate("/bsi-co-lab");
    localStorage.removeItem("boardDetail");
    localStorage.removeItem("boardName");
  };

  const handleGoToChat = (
    solutionId,
    businessUserId,
    solutionName,
    solutionProviderId,
    groupChatId,
    solutionLogo
  ) => {
    navigate("/inbox");
    dispatch(setSolutionName(solutionName));
    dispatch(getGroupName(solutionName));
    dispatch(getChatId(groupChatId));
    dispatch(getSolutionLogo(solutionLogo));
    dispatch(setSolutionId(solutionId));
    dispatch(toggleGroupSelected(true));
    dispatch(getBoardId(boardId));
    dispatch(getBoardName(boardName));
  };

  const inviteSolutionProvider = (boardId)=>{
    navigate("/invite-solution-provider");
    dispatch(getBoardIdForInviteSolutionProvider(boardId))
  }

  const isLoading = boardDetailLoading;

  useEffect(() => {
    if (boardDetailLoading) {
      dispatch(showLoader());
    } else {
      dispatch(hideLoader());
    }
  }, [boardDetailLoading, dispatch]);

  return (
    <>
      <Box
        direction="row"
        sx={{
          width: "100%",
          maxWidth: "100%",
          // "@media (max-width:1600px) and (min-width:1400px)": {
          //       width:'100%',
          //       maxWidth: open ? "87%" : "99.8%",
          //      },
          //     "@media (max-width:1616px) and (min-width:1504px)": {
          //       width:'100%',
          //       maxWidth: open ? "76.06vw" : "88.1vw",
          //      },
          mb: 2,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography
          sx={{
            fontSize: "1.4rem",
            fontWeight: "700",
            color: "secondary.main",
            [theme.breakpoints.down("md")]: {
              fontSize: "1.1rem",
              maxWidth: "350px",
            },
            [theme.breakpoints.down("sm")]: {
              maxWidth: "250px",
            },
          }}
          variant="h4"
          noWrap
          component="div"
        >
          <Link
            style={{
              color: "#2A589C",
              textDecoration: "none",
            }}
          >
            <KeyboardBackspaceIcon
              style={{
                verticalAlign: "-8px",
                fontSize: "32px",
                marginRight: "7px",
              }}
              onClick={handleGoBack}
            />
          </Link>
          <Tooltip title={boardName}>{boardName}</Tooltip>
          {industryName !== "undefined" ? (
            <Chip
              label={industryName}
              sx={{
                backgroundColor: "primary.main",
                color: "#fff",
                ml: 3,
                fontWeight: "600",
              }}
            />
          ) : (
            <></>
          )}
        </Typography>
        <Stack
          direction="row"
          sx={{
            minWidth: "22%",
            pr: 0,
            "@media (max-width:2560px) and (min-width:1420px)": {
              pr: 0,
            },
          }}
        >
          <Search>
            <StyledInputBase
              placeholder="Search by Solution Name"
              inputProps={{ "aria-label": "search" }}
              value={searchParams}
              onChange={handleInputChange}
              onKeyDown={handleSearchSolutionProvider}
              autoFocus
            />
            {searchParams ? (
              <IconButton
                onClick={handleClearSearch}
                sx={{ position: "absolute", right: 0 }}
              >
                <CancelIcon sx={{ color: "lightSecondary.main" }} />
              </IconButton>
            ) : (
              <SearchIconWrapper>
                <SearchIcon sx={{ color: "lightSecondary.main" }} />
              </SearchIconWrapper>
            )}
          </Search>
          <IconButton onClick={boardSettingsHandle}>
            <SettingsOutlinedIcon sx={{ color: "lightSecondary.main" }} />
          </IconButton>
        </Stack>
      </Box>

      <Box
        style={{
          width: "100%",
          maxWidth: "100%",
          [theme.breakpoints.down("lg")]: {
            overflowX: "auto",
            "&::-webkit-scrollbar": {
              height: "6px",
              borderRadius: "4px",
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "transparent",
              borderRadius: "4px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#9C9C9C",
              borderRadius: "4px",
            },
          },
        }}
      >
        <Box
          style={{
            width: "100%",
          }}
          sx={{
            display: "flex",
            gap: 1.5,
          }}
        >
          <Box
            sx={{
              minWidth: "40px",
            }}
          >
            <Box
              sx={{
                marginBottom: "10px",
                backgroundColor: "transparent",
                textAlign: "center",
                borderRadius: "10px",
                padding: "11px",
              }}
            >
              <Typography
                variant="h6"
                color="textSecondary"
                sx={{
                  fontSize: "0.9rem",
                  fontWeight: "600",
                  color: "secondary.main",
                }}
              >
                &nbsp;
              </Typography>
            </Box>
            <MainCard
              content={false}
              sx={{
                backgroundColor: "transparent",
                border: "none",
                padding: "5px 0 10px",
                height: "calc(100vh - 10px)",
              }}
            >
              <Box sx={{ p: 0, pb: 0 }}>
                <List
                  component="nav"
                  sx={{
                    px: 0,
                    py: 0,
                    "& .MuiListItemButton-root": {
                      py: 1,
                    },
                    height: "100%",
                    maxHeight: open ? "95vh" : "95vh",
                    overflowY: "auto",
                    "&::-webkit-scrollbar": {
                      opacity: 0,
                      width: "6px",
                    },
                    "s:hover": {
                      "&::-webkit-scrollbar": {
                        width: "6px",
                        borderRadius: "4px",
                      },
                      "&::-webkit-scrollbar-track": {
                        backgroundColor: "transparent",
                        borderRadius: "4px",
                      },
                      "&::-webkit-scrollbar-thumb": {
                        backgroundColor: "#9C9C9C",
                        borderRadius: "4px",
                      },
                    },
                  }}
                >
                  {/* Render a default priority card if the invited list is empty */}
                  {(maxLength === 0
                    ? Array.from({ length: 5 })
                    : Array.from({ length: maxLength })
                  ).map((_, priorityIndex) => (
                    <ListItemText
                      key={`${status}_${priorityIndex}`}
                      sx={{
                        backgroundColor: "#ECF6FF",
                        height: "170px",
                        width: "40px",
                        background: "#fff",
                        borderRadius: "10px",
                        marginTop: "7px",
                        py: 3,
                        px: 2.5,
                        position: "relative",
                        "& .MuiTypography-root": {
                          fontSize: "0.9rem",
                          color: "#0092F0",
                          fontWeight: "600",
                          transform: "rotate(-90deg)",
                          whiteSpace: "nowrap",
                          top: "45%",
                          position: "absolute",
                          left: "-10px",
                        },
                      }}
                    >
                      {`Priority ${priorityIndex + 1}`}
                    </ListItemText>
                  ))}
                </List>
              </Box>
            </MainCard>
          </Box>
          <Box
             style={{ width: "100%" }}
             sx={{
               maxWidth: open ? "100vw" : "100vw",
               overflowX: "scroll",
               display: "flex",
               gap: 1.5,
               "&::-webkit-scrollbar": {
                 height: "6px",
                 borderRadius: "4px",
               },
               "&::-webkit-scrollbar-track": {
                 backgroundColor: "transparent",
                 borderRadius: "4px",
               },
               "&::-webkit-scrollbar-thumb": {
                 backgroundColor: "#9C9C9C",
                 borderRadius: "4px",
               },
               [theme.breakpoints.down("sm")]: {
                 maxWidth: open ? "153vw" : "153vw",
               },
               [theme.breakpoints.down("xs")]: {
                 maxWidth: open ? "178vw" : "178vw",
               },
               "@media (max-width:2560px) and (min-width:1616px)": {
                 width: "100%",
                 maxWidth: open ? "100vw" : "100vw",
               },
               "@media (max-width:1616px) and (min-width:1504px)": {
                 width: "100%",
                 maxWidth: open ? "76.06vw" : "88.1vw",
               },
               "@media (max-width:1504px) and (min-width:1250px)": {
                 width: "100%",
                 maxWidth: open ? "72.2vw" : "86.2vw",
               },
             }}
          >
            {/* Invited */}
            <Box
              sx={{
                flexShrink: 0,
                width: "calc(20% - 10px)",
                minWidth: "280px",
                overflowWrap: "break-word",
              }}
            >
              <Box
                sx={{
                  marginBottom: "10px",
                  backgroundColor: "#FFB185",
                  textAlign: "center",
                  borderRadius: "10px",
                  padding: "11px",
                }}
              >
                <Typography
                  variant="h6"
                  color="textSecondary"
                  sx={{
                    fontSize: "0.9rem",
                    fontWeight: "600",
                    color: "secondary.main",
                  }}
                >
                  Invited
                </Typography>
              </Box>
              <MainCard
                content={false}
                sx={{
                  backgroundColor: "#ECF6FF",
                  border: "none",
                  padding: "5px 10px 10px",
                  // height: 'calc(100vh - 10px)',
                  height: "100%",
                  maxHeight: open ? "95vh" : "95vh",
                  overflowY: "auto",
                  "&::-webkit-scrollbar": {
                    opacity: 0,
                    width: "6px",
                  },
                  "s:hover": {
                    "&::-webkit-scrollbar": {
                      width: "6px",
                      borderRadius: "4px",
                    },
                    "&::-webkit-scrollbar-track": {
                      backgroundColor: "transparent",
                      borderRadius: "4px",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      backgroundColor: "#9C9C9C",
                      borderRadius: "4px",
                    },
                  },
                }}
                // onDragEnter={(e) => dragEnter(e, "Invited")}
                //         onDragEnd={drop}
              >
                <Box sx={{ p: 0, pb: 0, height: "100%" }}>
                  <List
                    component="nav"
                    sx={{
                      height: "100%",
                      px: 0,
                      py: 0,
                      "& .MuiListItemButton-root": {
                        py: 1.5,
                      },
                    }}
                  >
                    {/* {Invited.length === 0 && (
                      <ListItemButton
                        sx={{
                          minHeight: "65px",
                          // height: "100%",
                          background: "#fff",
                          borderRadius: "10px",
                          marginTop: "5px",
                        }}
                        onDragEnter={(e) => dragEnter(e, "Invited")}
                        onDragEnd={drop}
                      >
                        Drag items here
                      </ListItemButton>
                    )} */}
                    {Invited.map((list1, index) => (
                      <ListItemButton
                        key={index}
                        id={index}
                        sx={{
                          minHeight: "170px",
                          height: "170px",
                          display: "block",
                          alignItems: "center",
                          background: "#FFFFFF",
                          borderRadius: "10px",
                          marginTop: "7px",
                          py: 3,
                          px: 2.5,
                        }}
                        // onDragStart={(e) => dragStart(e, "Invited")}
                        // onDragEnter={(e) => dragEnter(e, "Invited")}
                        // onDragEnd={drop}
                        // draggable
                      >
                        <Stack
                          direction="row"
                          sx={{
                            width: "100%",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box
                            draggable={false}
                            onDragStart={(e) => e.preventDefault()}
                          >
                            <img
                              style={{
                                width: "106px",
                                height: "30px",
                                mixBlendMode: "multiply",
                              }}
                              src={list1.logo}
                              alt="logo1"
                            />
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              gap: "10px",
                              alignItems: "center",
                            }}
                          >
                            <IconButton
                              draggable={false}
                              onDragStart={(e) => e.preventDefault()}
                            >
                              <Link
                                to={list1.url}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <OpenInNewIcon
                                  sx={{ color: "lightSecondary.main" }}
                                />
                              </Link>
                            </IconButton>
                            {/* <div style={{ position: "relative" }}>
                              <Button
                                id="basic-button1"
                                aria-controls={
                                  openDw ? "basic-menu1" : undefined
                                }
                                aria-haspopup="true"
                                aria-expanded={openDw ? "true" : undefined}
                                onClick={(event) =>
                                  handleClick(event, list1.id, list1.status)
                                }
                                sx={{
                                  p: 0,
                                  minWidth: "10px",
                                  verticalAlign: "0.5px",
                                }}
                              >
                                <MoreVertOutlinedIcon
                                  sx={{ color: "secondary.main" }}
                                  style={{ marginLeft: "0" }}
                                />
                              </Button>
                              <Menu
                                id={list1.id}
                                anchorEl={anchorEl}
                                open={openDw}
                                onClose={handleClose}
                                MenuListProps={{
                                  "aria-labelledby": "basic-button1",
                                }}
                                sx={{
                                  "& .MuiMenu-paper": {
                                    borderRadius: "4px",
                                    border: "none",
                                    minWidth: "210px",
                                    p: 0.5,
                                  },
                                }}
                              >
                                <Paper
                                  sx={{
                                    width: 100,
                                    maxWidth: "100%",
                                    background: "#fff",
                                    borderRadius: "10px",
                                    borderBottom: "none",
                                    boxShadow: "none",
                                    p: 0.5,
                                  }}
                                >
                                 
                                  <Typography
                                    sx={{
                                      fontSize: "0.9rem",
                                      fontWeight: "600",
                                      lineHeight: "20px",
                                      color: "lightSecondary.main",
                                      display: "block",
                                      py: 1,
                                      px: 1.4,
                                      "&:hover": {
                                        backgroundColor: "extraLTheme.main",
                                      },
                                      "&.active": {
                                        backgroundColor: "primary.main",
                                        color: "#fff",
                                        borderRadius: "10px",
                                      },
                                    }}
                                  >
                                    Status: <br />
                                    <div>
                                      <Button
                                        id="sub-basic-button1"
                                        key={list1.id}
                                        aria-controls={
                                          openStatus
                                            ? "sub-basic-menu1"
                                            : undefined
                                        }
                                        aria-haspopup="true"
                                        aria-expanded={
                                          openStatus ? "true" : undefined
                                        }
                                        onClick={(event) => {
                                          handleStatusClick(
                                            event,
                                            list1.id,
                                            list1.status
                                          ),
                                            event.stopPropagation();
                                        }}
                                        sx={{
                                          p: openStatus
                                            ? "10px 10px"
                                            : "10px  0",
                                          minWidth: "10px",
                                          width: "100%",
                                          verticalAlign: "-5px",
                                          display: "flex",
                                          justifyContent: "space-between",
                                          color: openStatus
                                            ? "#FFB185"
                                            : "secondary.main",
                                          backgroundColor: openStatus
                                            ? "#2A589C"
                                            : "#fff",
                                        }}
                                      >
                                        <div>Invited</div>
                                        <ChevronRightOutlinedIcon
                                          sx={{ color: "secondary.main" }}
                                          style={{ marginLeft: "0" }}
                                        />
                                      </Button>
                                      <Menu
                                        id={list1.id}
                                        anchorEl={anchorElStatus}
                                        open={openStatus}
                                        onClose={handleStatusClose}
                                        anchorOrigin={{
                                          vertical: "top",
                                          horizontal: "right",
                                        }}
                                        transformOrigin={{
                                          vertical: "top",
                                          horizontal: "left",
                                        }}
                                        MenuListProps={{
                                          "aria-labelledby":
                                            "sub-basic-button1",
                                        }}
                                        sx={{
                                          "& .MuiMenu-paper": {
                                            borderRadius: "4px",
                                            border: "none",
                                            minWidth: "130px",
                                          },
                                        }}
                                      >
                                        <Paper
                                          sx={{
                                            width: 100,
                                            maxWidth: "100%",
                                            background: "#fff",
                                            borderRadius: "10px",
                                            borderBottom: "none",
                                            boxShadow: "none",
                                          }}
                                        >
                                          <MenuItem
                                            onClick={() => {
                                              handleStatusClose(),
                                                handleInvite(
                                                  list1.id,
                                                  "Invited"
                                                );
                                            }}
                                            sx={{
                                              fontSize: "0.9rem",
                                              fontWeight: "600",
                                              lineHeight: "20px",
                                              color: "lightSecondary.main",
                                              py: 0.5,
                                              px: 1.4,
                                              "&:hover": {
                                                backgroundColor:
                                                  "extraLTheme.main",
                                              },
                                              "&.active": {
                                                backgroundColor: "primary.main",
                                                color: "#fff",
                                                borderRadius: "10px",
                                              },
                                            }}
                                          >
                                            Invited
                                          </MenuItem>
                                          <MenuItem
                                            onClick={() => {
                                              handleStatusClose(),
                                                handleInvite(
                                                  list4.id,
                                                  "Responded"
                                                );
                                            }}
                                            sx={{
                                              fontSize: "0.9rem",
                                              fontWeight: "600",
                                              lineHeight: "20px",
                                              color: "lightSecondary.main",
                                              py: 0.5,
                                              px: 1.4,
                                              "&:hover": {
                                                backgroundColor:
                                                  "extraLTheme.main",
                                              },
                                              "&.active": {
                                                backgroundColor: "primary.main",
                                                color: "#fff",
                                                borderRadius: "10px",
                                              },
                                            }}
                                          >
                                            Responded
                                          </MenuItem>
                                          <MenuItem
                                            onClick={() => {
                                              handleStatusClose(),
                                                handleInvite(
                                                  list1.id,
                                                  "Shortlisted"
                                                );
                                            }}
                                            sx={{
                                              fontSize: "0.9rem",
                                              fontWeight: "600",
                                              lineHeight: "20px",
                                              color: "lightSecondary.main",
                                              py: 0.5,
                                              px: 1.4,
                                              "&:hover": {
                                                backgroundColor:
                                                  "extraLTheme.main",
                                              },
                                              "&.active": {
                                                backgroundColor: "primary.main",
                                                color: "#fff",
                                                borderRadius: "10px",
                                              },
                                            }}
                                          >
                                            Shortlisted
                                          </MenuItem>
                                          <MenuItem
                                            onClick={() => {
                                              handleStatusClose(),
                                                handleInvite(
                                                  list1.id,
                                                  "Selected"
                                                );
                                            }}
                                            sx={{
                                              fontSize: "0.9rem",
                                              fontWeight: "600",
                                              lineHeight: "20px",
                                              color: "lightSecondary.main",
                                              py: 0.5,
                                              px: 1.4,
                                              "&:hover": {
                                                backgroundColor:
                                                  "extraLTheme.main",
                                              },
                                              "&.active": {
                                                backgroundColor: "primary.main",
                                                color: "#fff",
                                                borderRadius: "10px",
                                              },
                                            }}
                                          >
                                            Selected
                                          </MenuItem>
                                          <MenuItem
                                            onClick={() => {
                                              handleStatusClose(),
                                                handleInvite(
                                                  list1.id,
                                                  "Rejected"
                                                );
                                            }}
                                            sx={{
                                              fontSize: "0.9rem",
                                              fontWeight: "600",
                                              lineHeight: "20px",
                                              color: "lightSecondary.main",
                                              py: 0.5,
                                              px: 1.4,
                                              "&:hover": {
                                                backgroundColor:
                                                  "extraLTheme.main",
                                              },
                                              "&.active": {
                                                backgroundColor: "primary.main",
                                                color: "#fff",
                                                borderRadius: "10px",
                                              },
                                            }}
                                          >
                                            Rejected
                                          </MenuItem>
                                        </Paper>
                                      </Menu>
                                    </div>
                                  </Typography>
                                </Paper>
                              </Menu>
                            </div> */}
                          </Box>
                        </Stack>
                        <Box>
                          <Typography
                            sx={{
                              fontSize: "0.9rem",
                              fontWeight: "600",
                              color: "secondary.main",
                              lineHeight: "22px",
                              mb: 0.3,
                              minHeight: "30px",
                            }}
                            variant="h6"
                          >
                            {list1.name}
                          </Typography>
                          <Typography
                            noWrap
                            variant="body2"
                            sx={{
                              fontSize: "12px",
                              fontWeight: "500",
                              color: "ltheme.main",
                            }}
                          >
                            {list1.date}
                          </Typography>
                        </Box>

                        {/* <Stack
                            direction="row"
                            sx={{
                              width: "100%",
                              alignItems: "center",
                              justifyContent: 'space-between',
                              mt: 1.5
                            }}
                            draggable={false}
                            onDragStart={(e) => e.preventDefault()}
                          >
                            <AvatarGroup max={3}
                              sx={{
                                height: 32,
                                overflow: 'visible'
                              }}>
                              <Avatar
                                alt="Remy Sharp"
                                src={getImages("1.jpg")}
                                sx={{ width: 32, height: 32 }}
                              />
                              <Avatar
                                alt="Remy Sharp"
                                src={getImages("2.jpg")}
                                sx={{ width: 32, height: 32 }}
                              />
                              <Avatar
                                alt="Remy Sharp"
                                src={getImages("3.jpg")}
                                sx={{ width: 32, height: 32 }}
                              />
                            </AvatarGroup>
                            <Box
                            draggable={false}
                            onDragStart={(e) => e.preventDefault()}
                            >
                              <Typography variant="subtitle1"
                                sx={{
                                  fontSize: "0.95rem",
                                  color: "ltheme.main",
                                  fontWeight: "600"
                                }}>
                                <Badge color="secondary" badgeContent=" " variant="dot"
                                  sx={{
                                    '& .MuiBadge-badge': {
                                      backgroundColor: "red.main"
                                    }
                                  }}
                                >
                                  <MessageOutlinedIcon
                                    sx={{
                                      verticalAlign: "middle",
                                      fill: "ltheme.main",
                                      width: "20px",
                                      height: "20px"
                                    }}
                                  />
                                </Badge>
                                &nbsp;
                                {list1.msg}
                              </Typography>
                            </Box>
                          </Stack> */}
                      </ListItemButton>
                    ))}
                    
                        <Button
                          variant="contained"
                          sx={{
                            width: "100%",
                            textTransform: "inherit",
                            p: "10px",
                            mt: "7px",
                            boxShadow: "none",
                            backgroundColor: "#DBEBF8",
                            color: "secondary.main",
                            fontWeight: "600",
                            "&:hover": {
                              backgroundColor: "ltheme.main",
                              boxShadow: "none",
                            },
                          }}
                          onClick={(()=> inviteSolutionProvider(boardId))}
                        >
                          <AddOutlinedIcon
                            sx={{
                              width: "20px",
                              height: "20px",
                              marginRight: "5px",
                            }}
                          />
                          Invite Solution Provider
                        </Button>
                    <ListItemButton
                      sx={{
                        // minHeight: "65px",
                        // background: "#fff",
                        borderRadius: "10px",
                        marginTop: "5px",
                        height: "100%",
                      }}
                      onDragEnter={(e) => dragEnter(e, "Invited")}
                      onDragEnd={drop}
                    ></ListItemButton>
                  </List>
                </Box>
              </MainCard>
            </Box>
            {/* Responded */}
            <Box
              sx={{
                flexShrink: 0, // Prevents the box from shrinking
                width: "calc(20% - 10px)", // Adjusted width to fit within container
                minWidth: "280px", // Adjusted minimum width
                overflowWrap: "break-word", // Ensure text wraps within box
              }}
            >
              <Box
                sx={{
                  marginBottom: "10px",
                  backgroundColor: "#7AC5FF",
                  textAlign: "center",
                  borderRadius: "10px",
                  padding: "11px",
                }}
              >
                <Typography
                  variant="h6"
                  color="textSecondary"
                  sx={{
                    fontSize: "0.9rem",
                    fontWeight: "600",
                    color: "secondary.main",
                  }}
                >
                  Responded
                </Typography>
              </Box>
              <MainCard
                content={false}
                sx={{
                  backgroundColor: "#ECF6FF",
                  border: "none",
                  padding: "5px 10px 10px",
                  // height: 'calc(100vh - 10px)',
                  height: "100%",
                  maxHeight: open ? "95vh" : "95vh",
                  overflowY: "auto",
                  "&::-webkit-scrollbar": {
                    opacity: 0,
                    width: "6px",
                  },
                  ":hover": {
                    "&::-webkit-scrollbar": {
                      width: "6px",
                      borderRadius: "4px",
                    },
                    "&::-webkit-scrollbar-track": {
                      backgroundColor: "transparent",
                      borderRadius: "4px",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      backgroundColor: "#9C9C9C",
                      borderRadius: "4px",
                    },
                  },
                }}
                // onDragEnter={(e) => dragEnter(e, "Responded")}
                //         onDragEnd={drop}
              >
                <Box sx={{ p: 0, pb: 0, height: "100%" }}>
                  <List
                    component="nav"
                    sx={{
                      height: "100%",
                      px: 0,
                      py: 0,
                      "& .MuiListItemButton-root": {
                        py: 1.5,
                      },
                    }}
                  >
                    {Responded.length === 0 && (
                      <ListItemButton
                        sx={{
                          minHeight: "65px",
                          background: "#fff",
                          borderRadius: "10px",
                          marginTop: "5px",
                        }}
                        onDragEnter={(e) => dragEnter(e, "Responded")}
                        onDragEnd={drop}
                      >
                        Drag items here
                      </ListItemButton>
                    )}

                    {Responded?.map((list5, index) => {
                      return (
                        <>
                          <ListItemButton
                            id={index}
                            key={index}
                            sx={{
                              minHeight: "170px",
                              height: "170px",
                              display: "block",
                              alignItems: "center",
                              background: "#FFFFFF",
                              borderRadius: "10px",
                              marginTop: "7px",
                              py: 3,
                              px: 2.5,
                            }}
                            onDragStart={(e) => dragStart(e, "Responded")}
                            onDragEnter={(e) => dragEnter(e, "Responded")}
                            onDragEnd={drop}
                            onDragOver={dragOver}
                            draggable
                          >
                            <Stack
                              direction="row"
                              sx={{
                                width: "100%",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <Box
                                draggable={false}
                                onDragStart={(e) => e.preventDefault()}
                              >
                                <img
                                  style={{
                                    width: "106px",
                                    height: "30px",
                                    mixBlendMode: "multiply",
                                  }}
                                  src={list5.logo}
                                  alt="logo5"
                                />
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  gap: "10px",
                                  alignItems: "center",
                                }}
                              >
                                <IconButton
                                  draggable={false}
                                  onDragStart={(e) => e.preventDefault()}
                                >
                                  <Link
                                    to={list5.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <OpenInNewIcon
                                      sx={{ color: "lightSecondary.main" }}
                                    />
                                  </Link>
                                </IconButton>
                                <div style={{ position: "relative" }}>
                                  <Button
                                    id="basic-button2"
                                    aria-controls={
                                      openDw ? "basic-menu2" : undefined
                                    }
                                    aria-haspopup="true"
                                    aria-expanded={openDw ? "true" : undefined}
                                    onClick={(event) =>
                                      handleClick(event, list5.id, list5.status)
                                    }
                                    sx={{
                                      p: 0,
                                      minWidth: "10px",
                                      verticalAlign: "0.5px",
                                    }}
                                  >
                                    <MoreVertOutlinedIcon
                                      sx={{ color: "secondary.main" }}
                                      style={{ marginLeft: "0" }}
                                    />
                                  </Button>
                                  <Menu
                                    id={list5.id}
                                    anchorEl={anchorEl}
                                    open={openDw}
                                    onClose={handleClose}
                                    MenuListProps={{
                                      "aria-labelledby": "basic-button2",
                                    }}
                                    sx={{
                                      "& .MuiMenu-paper": {
                                        borderRadius: "4px",
                                        border: "none",
                                        minWidth: "210px",
                                        p: 0.5,
                                      },
                                    }}
                                  >
                                    <Paper
                                      sx={{
                                        width: 210,
                                        maxWidth: "100%",
                                        background: "#fff",
                                        borderRadius: "10px",
                                        borderBottom: "none",
                                        boxShadow: "none",
                                        p: 0.5,
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          fontSize: "0.9rem",
                                          fontWeight: "600",
                                          lineHeight: "20px",
                                          color: "lightSecondary.main",
                                          display: "block",
                                          py: 1,
                                          px: 1.4,
                                          "&:hover": {
                                            backgroundColor: "extraLTheme.main",
                                          },
                                          "&.active": {
                                            backgroundColor: "primary.main",
                                            color: "#fff",
                                            borderRadius: "10px",
                                          },
                                        }}
                                      >
                                        Status: <br />
                                        <div>
                                          <Button
                                            id="sub-basic-button2"
                                            key={list5.id}
                                            aria-controls={
                                              openStatus
                                                ? "sub-basic-menu2"
                                                : undefined
                                            }
                                            aria-haspopup="true"
                                            aria-expanded={
                                              openStatus ? "true" : undefined
                                            }
                                            onClick={(event) => {
                                              handleStatusClick(
                                                event,
                                                list5.id,
                                                list5.status
                                              ),
                                                event.stopPropagation();
                                            }}
                                            sx={{
                                              p: openStatus
                                                ? "10px 10px"
                                                : "10px  0",
                                              minWidth: "10px",
                                              width: "100%",
                                              verticalAlign: "-5px",
                                              display: "flex",
                                              justifyContent: "space-between",
                                              color: openStatus
                                                ? "#FFB185"
                                                : "secondary.main",
                                              backgroundColor: openStatus
                                                ? "#2A589C"
                                                : "#fff",
                                            }}
                                          >
                                            <div>Select Status</div>
                                            <ChevronRightOutlinedIcon
                                              sx={{ color: "secondary.main" }}
                                              style={{ marginLeft: "0" }}
                                            />
                                          </Button>
                                          <Menu
                                            id={list5.id}
                                            anchorEl={anchorElStatus}
                                            open={openStatus}
                                            onClose={handleStatusClose}
                                            anchorOrigin={{
                                              vertical: "top",
                                              horizontal: "right",
                                            }}
                                            transformOrigin={{
                                              vertical: "top",
                                              horizontal: "left",
                                            }}
                                            MenuListProps={{
                                              "aria-labelledby":
                                                "sub-basic-button2",
                                            }}
                                            sx={{
                                              "& .MuiMenu-paper": {
                                                borderRadius: "4px",
                                                border: "none",
                                                minWidth: "130px",
                                              },
                                            }}
                                          >
                                            <Paper
                                              sx={{
                                                width: 100,
                                                maxWidth: "100%",
                                                background: "#fff",
                                                borderRadius: "10px",
                                                borderBottom: "none",
                                                boxShadow: "none",
                                              }}
                                            >
                                              <MenuItem
                                                onClick={() => {
                                                  handleStatusClose(),
                                                    handleInvite(
                                                      list5.id,
                                                      "Responded"
                                                    );
                                                }}
                                                sx={{
                                                  fontSize: "0.9rem",
                                                  fontWeight: "600",
                                                  lineHeight: "20px",
                                                  color: "lightSecondary.main",
                                                  py: 0.5,
                                                  px: 1.4,
                                                  "&:hover": {
                                                    backgroundColor:
                                                      "extraLTheme.main",
                                                  },
                                                  "&.active": {
                                                    backgroundColor:
                                                      "primary.main",
                                                    color: "#fff",
                                                    borderRadius: "10px",
                                                  },
                                                }}
                                              >
                                                Responded
                                              </MenuItem>
                                              <MenuItem
                                                onClick={() => {
                                                  handleStatusClose(),
                                                    handleInvite(
                                                      list5.id,
                                                      "Shortlisted"
                                                    );
                                                }}
                                                sx={{
                                                  fontSize: "0.9rem",
                                                  fontWeight: "600",
                                                  lineHeight: "20px",
                                                  color: "lightSecondary.main",
                                                  py: 0.5,
                                                  px: 1.4,
                                                  "&:hover": {
                                                    backgroundColor:
                                                      "extraLTheme.main",
                                                  },
                                                  "&.active": {
                                                    backgroundColor:
                                                      "primary.main",
                                                    color: "#fff",
                                                    borderRadius: "10px",
                                                  },
                                                }}
                                              >
                                                In Review
                                              </MenuItem>
                                              <MenuItem
                                                onClick={() => {
                                                  handleStatusClose(),
                                                    handleInvite(
                                                      list5.id,
                                                      "Selected"
                                                    );
                                                }}
                                                sx={{
                                                  fontSize: "0.9rem",
                                                  fontWeight: "600",
                                                  lineHeight: "20px",
                                                  color: "lightSecondary.main",
                                                  py: 0.5,
                                                  px: 1.4,
                                                  "&:hover": {
                                                    backgroundColor:
                                                      "extraLTheme.main",
                                                  },
                                                  "&.active": {
                                                    backgroundColor:
                                                      "primary.main",
                                                    color: "#fff",
                                                    borderRadius: "10px",
                                                  },
                                                }}
                                              >
                                                Selected
                                              </MenuItem>
                                              <MenuItem
                                                onClick={() => {
                                                  handleStatusClose(),
                                                    handleInvite(
                                                      list5.id,
                                                      "Rejected"
                                                    );
                                                }}
                                                sx={{
                                                  fontSize: "0.9rem",
                                                  fontWeight: "600",
                                                  lineHeight: "20px",
                                                  color: "lightSecondary.main",
                                                  py: 0.5,
                                                  px: 1.4,
                                                  "&:hover": {
                                                    backgroundColor:
                                                      "extraLTheme.main",
                                                  },
                                                  "&.active": {
                                                    backgroundColor:
                                                      "primary.main",
                                                    color: "#fff",
                                                    borderRadius: "10px",
                                                  },
                                                }}
                                              >
                                                Rejected
                                              </MenuItem>
                                            </Paper>
                                          </Menu>
                                        </div>
                                      </Typography>
                                    </Paper>
                                  </Menu>
                                </div>
                              </Box>
                            </Stack>
                            <Box>
                              <Typography
                                sx={{
                                  fontSize: "0.9rem",
                                  fontWeight: "600",
                                  color: "secondary.main",
                                  lineHeight: "22px",
                                  mb: 0.3,
                                  minHeight: "30px",
                                }}
                                variant="h6"
                              >
                                {list5.name}
                              </Typography>
                              <Typography
                                noWrap
                                variant="body2"
                                sx={{
                                  fontSize: "12px",
                                  fontWeight: "500",
                                  color: "ltheme.main",
                                }}
                              >
                                {list5.date}
                              </Typography>
                            </Box>

                            <Button
                              // variant="contained"
                              onClick={() =>
                                handleGoToChat(
                                  list5.solutionId,
                                  list5.userId,
                                  list5.solutionName,
                                  list5.solutionProviderId,
                                  list5.groupChatId,
                                  list5.logo
                                )
                              }
                              sx={{
                                width: "100%",
                                textTransform: "inherit",
                                p: "10px",
                                bgcolor: "#ECF6FF",
                                color: "#2A589C",
                                fontWeight: "bold",
                                boxShadow: "none",
                                mt: "7px",
                                "&:hover": {
                                  boxShadow: "none",
                                  bgcolor: "#ECF6FF",
                                  color: "#2A589C",
                                },
                              }}
                            >
                              Go to Inbox
                              <ArrowForwardOutlinedIcon
                                sx={{
                                  fontSize: "20px",
                                  ml: "8px",
                                  color: "#2A589C",
                                }}
                              />
                            </Button>

                            {/* <Stack
                            direction="row"
                            sx={{
                              width: "100%",
                              alignItems: "center",
                              justifyContent: 'space-between',
                              mt: 1.5
                            }}
                            draggable={false}
                            onDragStart={(e) => e.preventDefault()}
                          >
                            <AvatarGroup max={3}
                              sx={{
                                height: 32,
                                overflow: 'visible'
                              }}>
                              <Avatar
                                alt="Remy Sharp"
                                src={getImages("1.jpg")}
                                sx={{ width: 32, height: 32 }}
                              />
                              <Avatar
                                alt="Remy Sharp"
                                src={getImages("2.jpg")}
                                sx={{ width: 32, height: 32 }}
                              />
                              <Avatar
                                alt="Remy Sharp"
                                src={getImages("3.jpg")}
                                sx={{ width: 32, height: 32 }}
                              />
                            </AvatarGroup>
                            <Box
                            draggable={false}
                            onDragStart={(e) => e.preventDefault()}
                            >
                              <Typography variant="subtitle1"
                                sx={{
                                  fontSize: "0.95rem",
                                  color: "ltheme.main",
                                  fontWeight: "600"
                                }}>
                                <Badge color="secondary" badgeContent=" " variant="dot"
                                  sx={{
                                    '& .MuiBadge-badge': {
                                      backgroundColor: "red.main"
                                    }
                                  }}
                                >
                                  <MessageOutlinedIcon
                                    sx={{
                                      verticalAlign: "middle",
                                      fill: "ltheme.main",
                                      width: "20px",
                                      height: "20px"
                                    }}
                                  />
                                </Badge>
                                &nbsp;
                                {list5.msg}
                              </Typography>
                            </Box>
                          </Stack> */}
                          </ListItemButton>
                        </>
                      );
                    })}
                    <ListItemButton
                      sx={{
                        // minHeight: "65px",
                        // background: "#fff",
                        borderRadius: "10px",
                        marginTop: "5px",
                        height: "100%",
                      }}
                      onDragEnter={(e) => dragEnter(e, "Responded")}
                      onDragEnd={drop}
                    ></ListItemButton>
                  </List>
                </Box>
              </MainCard>
            </Box>
            {/* In review */}
            <Box
              sx={{
                flexShrink: 0, // Prevents the box from shrinking
                width: "calc(20% - 10px)", // Adjusted width to fit within container
                minWidth: "280px", // Adjusted minimum width
                overflowWrap: "break-word", // Ensure text wraps within box
              }}
            >
              <Box
                sx={{
                  marginBottom: "10px",
                  backgroundColor: "#75DCD6",
                  textAlign: "center",
                  borderRadius: "10px",
                  padding: "11px",
                }}
              >
                <Typography
                  variant="h6"
                  color="textSecondary"
                  sx={{
                    fontSize: "0.9rem",
                    fontWeight: "600",
                    color: "secondary.main",
                  }}
                >
                  In Review
                </Typography>
              </Box>
              <MainCard
                content={false}
                sx={{
                  backgroundColor: "#ECF6FF",
                  border: "none",
                  padding: "5px 10px 10px",
                  // height: 'calc(100vh - 10px)',
                  height: "100%",
                  maxHeight: open ? "95vh" : "95vh",
                  overflowY: "auto",
                  "&::-webkit-scrollbar": {
                    opacity: 0,
                    width: "6px",
                  },
                  ":hover": {
                    "&::-webkit-scrollbar": {
                      width: "6px",
                      borderRadius: "4px",
                    },
                    "&::-webkit-scrollbar-track": {
                      backgroundColor: "transparent",
                      borderRadius: "4px",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      backgroundColor: "#9C9C9C",
                      borderRadius: "4px",
                    },
                  },
                }}
                // onDragEnter={(e) => dragEnter(e, "Shortlisted")}
                //         onDragEnd={drop}
              >
                <Box sx={{ p: 0, pb: 0, height: "100%" }}>
                  <List
                    // onDragEnter={(e) => dragEnter(e, "Shortlisted")}
                    // onDragEnd={drop}
                    component="nav"
                    sx={{
                      height: "100%",
                      px: 0,
                      py: 0,
                      "& .MuiListItemButton-root": {
                        py: 1.5,
                      },
                    }}
                  >
                    {Shortlisted.length === 0 && (
                      <ListItemButton
                        sx={{
                          minHeight: "65px",
                          // height: 'calc(100vh - 10px)',
                          background: "#fff",
                          borderRadius: "10px",
                          marginTop: "5px",
                        }}
                        onDragEnter={(e) => dragEnter(e, "Shortlisted")}
                        onDragEnd={drop}
                      >
                        Drag items here
                      </ListItemButton>
                    )}
                    {Shortlisted?.map((list2, index) => {
                      return (
                        <>
                          <ListItemButton
                            key={index}
                            id={index}
                            sx={{
                              minHeight: "170px",
                              height: "170px",
                              display: "block",
                              alignItems: "center",
                              background: "#FFFFFF",
                              borderRadius: "10px",
                              marginTop: "7px",
                              py: 3,
                              px: 2.5,
                            }}
                            onDragStart={(e) => dragStart(e, "Shortlisted")}
                            onDragEnter={(e) => dragEnter(e, "Shortlisted")}
                            onDragEnd={drop}
                            onDragOver={dragOver}
                            draggable
                          >
                            <Stack
                              direction="row"
                              sx={{
                                width: "100%",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <Box
                                draggable={false}
                                onDragStart={(e) => e.preventDefault()}
                              >
                                <img
                                  style={{
                                    width: "106px",
                                    height: "30px",
                                    mixBlendMode: "multiply",
                                  }}
                                  src={list2.logo}
                                  alt="logo2"
                                />
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  gap: "10px",
                                  alignItems: "center",
                                }}
                              >
                                <IconButton
                                  draggable={false}
                                  onDragStart={(e) => e.preventDefault()}
                                >
                                  <Link
                                    to={list2.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <OpenInNewIcon
                                      sx={{ color: "lightSecondary.main" }}
                                    />
                                  </Link>
                                </IconButton>
                                <div style={{ position: "relative" }}>
                                  <Button
                                    id="basic-button3"
                                    aria-controls={
                                      openDw ? "basic-menu3" : undefined
                                    }
                                    aria-haspopup="true"
                                    aria-expanded={openDw ? "true" : undefined}
                                    onClick={(event) =>
                                      handleClick(event, list2.id, list2.status)
                                    }
                                    sx={{
                                      p: 0,
                                      minWidth: "10px",
                                      verticalAlign: "0.5px",
                                    }}
                                  >
                                    <MoreVertOutlinedIcon
                                      sx={{ color: "secondary.main" }}
                                      style={{ marginLeft: "0" }}
                                    />
                                  </Button>
                                  <Menu
                                    id={list2.id}
                                    anchorEl={anchorEl}
                                    open={openDw}
                                    onClose={handleClose}
                                    MenuListProps={{
                                      "aria-labelledby": "basic-button3",
                                    }}
                                    sx={{
                                      "& .MuiMenu-paper": {
                                        borderRadius: "4px",
                                        border: "none",
                                        minWidth: "210px",
                                        p: 0.5,
                                      },
                                    }}
                                  >
                                    <Paper
                                      sx={{
                                        width: 210,
                                        maxWidth: "100%",
                                        background: "#fff",
                                        borderRadius: "10px",
                                        borderBottom: "none",
                                        boxShadow: "none",
                                        p: 0.5,
                                      }}
                                    >
                                      {/* <Divider
                                    sx={{
                                      borderColor: "ltheme.main",
                                    }}
                                  /> */}
                                      <Typography
                                        sx={{
                                          fontSize: "0.9rem",
                                          fontWeight: "600",
                                          lineHeight: "20px",
                                          color: "lightSecondary.main",
                                          display: "block",
                                          py: 1,
                                          px: 1.4,
                                          "&:hover": {
                                            backgroundColor: "extraLTheme.main",
                                          },
                                          "&.active": {
                                            backgroundColor: "primary.main",
                                            color: "#fff",
                                            borderRadius: "10px",
                                          },
                                        }}
                                      >
                                        Status: <br />
                                        <div>
                                          <Button
                                            id="sub-basic-button3"
                                            key={list2.id}
                                            aria-controls={
                                              openStatus
                                                ? "sub-basic-menu3"
                                                : undefined
                                            }
                                            aria-haspopup="true"
                                            aria-expanded={
                                              openStatus ? "true" : undefined
                                            }
                                            onClick={(event) => {
                                              handleStatusClick(
                                                event,
                                                list2.id,
                                                list2.status
                                              ),
                                                event.stopPropagation();
                                            }}
                                            sx={{
                                              p: openStatus
                                                ? "10px 10px"
                                                : "10px  0",
                                              minWidth: "10px",
                                              width: "100%",
                                              verticalAlign: "-5px",
                                              display: "flex",
                                              justifyContent: "space-between",
                                              color: openStatus
                                                ? "#FFB185"
                                                : "secondary.main",
                                              backgroundColor: openStatus
                                                ? "#2A589C"
                                                : "#fff",
                                            }}
                                          >
                                            <div>Select Status</div>
                                            <ChevronRightOutlinedIcon
                                              sx={{ color: "secondary.main" }}
                                              style={{ marginLeft: "0" }}
                                            />
                                          </Button>
                                          <Menu
                                            id={list2.id}
                                            anchorEl={anchorElStatus}
                                            open={openStatus}
                                            onClose={handleStatusClose}
                                            anchorOrigin={{
                                              vertical: "top",
                                              horizontal: "right",
                                            }}
                                            transformOrigin={{
                                              vertical: "top",
                                              horizontal: "left",
                                            }}
                                            MenuListProps={{
                                              "aria-labelledby":
                                                "sub-basic-button3",
                                            }}
                                            sx={{
                                              "& .MuiMenu-paper": {
                                                borderRadius: "4px",
                                                border: "none",
                                                minWidth: "130px",
                                              },
                                            }}
                                          >
                                            <Paper
                                              sx={{
                                                width: 100,
                                                maxWidth: "100%",
                                                background: "#fff",
                                                borderRadius: "10px",
                                                borderBottom: "none",
                                                boxShadow: "none",
                                              }}
                                            >
                                              <MenuItem
                                                onClick={() => {
                                                  handleStatusClose(),
                                                    handleInvite(
                                                      list2.id,
                                                      "Responded"
                                                    );
                                                }}
                                                sx={{
                                                  fontSize: "0.9rem",
                                                  fontWeight: "600",
                                                  lineHeight: "20px",
                                                  color: "lightSecondary.main",
                                                  py: 0.5,
                                                  px: 1.4,
                                                  "&:hover": {
                                                    backgroundColor:
                                                      "extraLTheme.main",
                                                  },
                                                  "&.active": {
                                                    backgroundColor:
                                                      "primary.main",
                                                    color: "#fff",
                                                    borderRadius: "10px",
                                                  },
                                                }}
                                              >
                                                Responded
                                              </MenuItem>
                                              <MenuItem
                                                onClick={() => {
                                                  handleStatusClose(),
                                                    handleInvite(
                                                      list2.id,
                                                      "Shortlisted"
                                                    );
                                                }}
                                                sx={{
                                                  fontSize: "0.9rem",
                                                  fontWeight: "600",
                                                  lineHeight: "20px",
                                                  color: "lightSecondary.main",
                                                  py: 0.5,
                                                  px: 1.4,
                                                  "&:hover": {
                                                    backgroundColor:
                                                      "extraLTheme.main",
                                                  },
                                                  "&.active": {
                                                    backgroundColor:
                                                      "primary.main",
                                                    color: "#fff",
                                                    borderRadius: "10px",
                                                  },
                                                }}
                                              >
                                                In Review
                                              </MenuItem>
                                              <MenuItem
                                                onClick={() => {
                                                  handleStatusClose(),
                                                    handleInvite(
                                                      list2.id,
                                                      "Selected"
                                                    );
                                                }}
                                                sx={{
                                                  fontSize: "0.9rem",
                                                  fontWeight: "600",
                                                  lineHeight: "20px",
                                                  color: "lightSecondary.main",
                                                  py: 0.5,
                                                  px: 1.4,
                                                  "&:hover": {
                                                    backgroundColor:
                                                      "extraLTheme.main",
                                                  },
                                                  "&.active": {
                                                    backgroundColor:
                                                      "primary.main",
                                                    color: "#fff",
                                                    borderRadius: "10px",
                                                  },
                                                }}
                                              >
                                                Selected
                                              </MenuItem>
                                              <MenuItem
                                                onClick={() => {
                                                  handleStatusClose(),
                                                    handleInvite(
                                                      list2.id,
                                                      "Rejected"
                                                    );
                                                }}
                                                sx={{
                                                  fontSize: "0.9rem",
                                                  fontWeight: "600",
                                                  lineHeight: "20px",
                                                  color: "lightSecondary.main",
                                                  py: 0.5,
                                                  px: 1.4,
                                                  "&:hover": {
                                                    backgroundColor:
                                                      "extraLTheme.main",
                                                  },
                                                  "&.active": {
                                                    backgroundColor:
                                                      "primary.main",
                                                    color: "#fff",
                                                    borderRadius: "10px",
                                                  },
                                                }}
                                              >
                                                Rejected
                                              </MenuItem>
                                            </Paper>
                                          </Menu>
                                        </div>
                                      </Typography>
                                    </Paper>
                                  </Menu>
                                </div>
                              </Box>
                            </Stack>
                            <Box>
                              <Typography
                                sx={{
                                  fontSize: "0.9rem",
                                  fontWeight: "600",
                                  color: "secondary.main",
                                  lineHeight: "22px",
                                  mb: 0.3,
                                  minHeight: "30px",
                                }}
                                variant="h6"
                              >
                                {list2.name}
                              </Typography>
                              <Typography
                                noWrap
                                variant="body2"
                                sx={{
                                  fontSize: "12px",
                                  fontWeight: "500",
                                  color: "ltheme.main",
                                }}
                              >
                                {list2.date}
                              </Typography>
                            </Box>
                            <Button
                              // variant="contained"
                              onClick={() =>
                                handleGoToChat(
                                  list2.solutionId,
                                  list2.userId,
                                  list2.solutionName,
                                  list2.solutionProviderId,
                                  list2.groupChatId,
                                  list2.logo
                                )
                              }
                              sx={{
                                width: "100%",
                                textTransform: "inherit",
                                p: "10px",
                                bgcolor: "#ECF6FF",
                                color: "#2A589C",
                                fontWeight: "bold",
                                boxShadow: "none",
                                mt: 1,
                                "&:hover": {
                                  boxShadow: "none",
                                  bgcolor: "#ECF6FF",
                                  color: "#2A589C",
                                },
                              }}
                            >
                              Go to Inbox
                              <ArrowForwardOutlinedIcon
                                sx={{
                                  fontSize: "20px",
                                  ml: "8px",
                                  color: "#2A589C",
                                }}
                              />
                            </Button>

                            {/* <Stack
                            direction="row"
                            sx={{
                              width: "100%",
                              alignItems: "center",
                              justifyContent: 'space-between',
                              mt: 1.5
                            }}
                            draggable={false}
                            onDragStart={(e) => e.preventDefault()}
                          >
                            <AvatarGroup max={3}
                              sx={{
                                height: 32,
                                overflow: 'visible'
                              }}>
                              <Avatar
                                alt="Remy Sharp"
                                src={getImages("1.jpg")}
                                sx={{ width: 32, height: 32 }}
                              />
                              <Avatar
                                alt="Remy Sharp"
                                src={getImages("2.jpg")}
                                sx={{ width: 32, height: 32 }}
                              />
                              <Avatar
                                alt="Remy Sharp"
                                src={getImages("3.jpg")}
                                sx={{ width: 32, height: 32 }}
                              />
                            </AvatarGroup>
                            <Box
                            draggable={false}
                            onDragStart={(e) => e.preventDefault()}
                            >
                              <Typography variant="subtitle1"
                                sx={{
                                  fontSize: "0.95rem",
                                  color: "ltheme.main",
                                  fontWeight: "600"
                                }}>
                                <Badge color="secondary" badgeContent=" " variant="dot"
                                  sx={{
                                    '& .MuiBadge-badge': {
                                      backgroundColor: "red.main"
                                    }
                                  }}
                                >
                                  <MessageOutlinedIcon
                                    sx={{
                                      verticalAlign: "middle",
                                      fill: "ltheme.main",
                                      width: "20px",
                                      height: "20px"
                                    }}
                                  />
                                </Badge>
                                &nbsp;
                                {list2.msg}
                              </Typography>
                            </Box>
                          </Stack> */}
                          </ListItemButton>
                        </>
                      );
                    })}
                    <ListItemButton
                      sx={{
                        // minHeight: "65px",
                        // background: "#fff",
                        borderRadius: "10px",
                        marginTop: "5px",
                        height: "100%",
                      }}
                      onDragEnter={(e) => dragEnter(e, "Shortlisted")}
                      onDragEnd={drop}
                    ></ListItemButton>
                  </List>
                </Box>
              </MainCard>
            </Box>
            {/* Selected */}
            <Box
              sx={{
                flexShrink: 0,
                width: "calc(20% - 10px)",
                minWidth: "280px",
                overflowWrap: "break-word",
              }}
            >
              <Box
                sx={{
                  marginBottom: "10px",
                  backgroundColor: "#7CDDA3",
                  textAlign: "center",
                  borderRadius: "10px",
                  padding: "11px",
                }}
              >
                <Typography
                  variant="h6"
                  color="textSecondary"
                  sx={{
                    fontSize: "0.9rem",
                    fontWeight: "600",
                    color: "secondary.main",
                  }}
                >
                  Selected
                </Typography>
              </Box>
              <MainCard
                content={false}
                sx={{
                  backgroundColor: "#ECF6FF",
                  border: "none",
                  padding: "5px 10px 10px",
                  // height: 'calc(100vh - 10px)',
                  height: "100%",
                  maxHeight: open ? "95vh" : "95vh",
                  overflowY: "auto",
                  "&::-webkit-scrollbar": {
                    opacity: 0,
                    width: "6px",
                  },
                  ":hover": {
                    "&::-webkit-scrollbar": {
                      width: "6px",
                      borderRadius: "4px",
                    },
                    "&::-webkit-scrollbar-track": {
                      backgroundColor: "transparent",
                      borderRadius: "4px",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      backgroundColor: "#9C9C9C",
                      borderRadius: "4px",
                    },
                  },
                }}
                // onDragEnter={(e) => dragEnter(e, "Selected")}
                //         onDragEnd={drop}
              >
                <Box sx={{ p: 0, pb: 0, height: "100%" }}>
                  <List
                    component="nav"
                    sx={{
                      height: "100%",
                      px: 0,
                      py: 0,
                      "& .MuiListItemButton-root": {
                        py: 1.5,
                      },
                    }}
                  >
                    {Selected.length === 0 && (
                      <ListItemButton
                        sx={{
                          minHeight: "65px",
                          background: "#fff",
                          borderRadius: "10px",
                          marginTop: "5px",
                        }}
                        onDragEnter={(e) => dragEnter(e, "Selected")}
                        onDragEnd={drop}
                      >
                        Drag items here
                      </ListItemButton>
                    )}

                    {Selected?.map((list4, index) => {
                      return (
                        <>
                          <ListItemButton
                            id={index}
                            key={index}
                            sx={{
                              minHeight: "170px",
                              height: "170px",
                              display: "block",
                              alignItems: "center",
                              background: "#FFFFFF",
                              borderRadius: "10px",
                              marginTop: "7px",
                              py: 3,
                              px: 2.5,
                            }}
                            onDragStart={(e) => dragStart(e, "Selected")}
                            onDragEnter={(e) => dragEnter(e, "Selected")}
                            onDragEnd={drop}
                            onDragOver={dragOver}
                            draggable
                          >
                            <Stack
                              direction="row"
                              sx={{
                                width: "100%",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <Box
                                draggable={false}
                                onDragStart={(e) => e.preventDefault()}
                              >
                                <img
                                  style={{
                                    width: "106px",
                                    height: "30px",
                                    mixBlendMode: "multiply",
                                  }}
                                  src={list4.logo}
                                  alt="logo4"
                                />
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  gap: "10px",
                                  alignItems: "center",
                                }}
                              >
                                <IconButton
                                  draggable={false}
                                  onDragStart={(e) => e.preventDefault()}
                                >
                                  <Link
                                    to={list4.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <OpenInNewIcon
                                      sx={{ color: "lightSecondary.main" }}
                                    />
                                  </Link>
                                </IconButton>
                                <div style={{ position: "relative" }}>
                                  <Button
                                    id="basic-button4"
                                    aria-controls={
                                      openDw ? "basic-menu4" : undefined
                                    }
                                    aria-haspopup="true"
                                    aria-expanded={openDw ? "true" : undefined}
                                    onClick={(event) =>
                                      handleClick(event, list4.id, list4.status)
                                    }
                                    sx={{
                                      p: 0,
                                      minWidth: "10px",
                                      verticalAlign: "0.5px",
                                    }}
                                  >
                                    <MoreVertOutlinedIcon
                                      sx={{ color: "secondary.main" }}
                                      style={{ marginLeft: "0" }}
                                    />
                                  </Button>
                                  <Menu
                                    id={list4.id}
                                    anchorEl={anchorEl}
                                    open={openDw}
                                    onClose={handleClose}
                                    MenuListProps={{
                                      "aria-labelledby": "basic-button4",
                                    }}
                                    sx={{
                                      "& .MuiMenu-paper": {
                                        borderRadius: "4px",
                                        border: "none",
                                        minWidth: "210px",
                                        p: 0.5,
                                      },
                                    }}
                                  >
                                    <Paper
                                      sx={{
                                        width: 210,
                                        maxWidth: "100%",
                                        background: "#fff",
                                        borderRadius: "10px",
                                        borderBottom: "none",
                                        boxShadow: "none",
                                        p: 0.5,
                                      }}
                                    >
                                      {/* <Divider
                                    sx={{
                                      borderColor: "ltheme.main",
                                    }}
                                  /> */}
                                      <Typography
                                        sx={{
                                          fontSize: "0.9rem",
                                          fontWeight: "600",
                                          lineHeight: "20px",
                                          color: "lightSecondary.main",
                                          display: "block",
                                          py: 1,
                                          px: 1.4,
                                          "&:hover": {
                                            backgroundColor: "extraLTheme.main",
                                          },
                                          "&.active": {
                                            backgroundColor: "primary.main",
                                            color: "#fff",
                                            borderRadius: "10px",
                                          },
                                        }}
                                      >
                                        Status: <br />
                                        <div>
                                          <Button
                                            id="sub-basic-button4"
                                            key={list4.id}
                                            aria-controls={
                                              openStatus
                                                ? "sub-basic-menu4"
                                                : undefined
                                            }
                                            aria-haspopup="true"
                                            aria-expanded={
                                              openStatus ? "true" : undefined
                                            }
                                            onClick={(event) => {
                                              handleStatusClick(
                                                event,
                                                list4.id,
                                                list4.status
                                              ),
                                                event.stopPropagation();
                                            }}
                                            sx={{
                                              p: openStatus
                                                ? "10px 10px"
                                                : "10px  0",
                                              minWidth: "10px",
                                              width: "100%",
                                              verticalAlign: "-5px",
                                              display: "flex",
                                              justifyContent: "space-between",
                                              color: openStatus
                                                ? "#FFB185"
                                                : "secondary.main",
                                              backgroundColor: openStatus
                                                ? "#2A589C"
                                                : "#fff",
                                            }}
                                          >
                                            <div>Select Status</div>
                                            <ChevronRightOutlinedIcon
                                              sx={{ color: "secondary.main" }}
                                              style={{ marginLeft: "0" }}
                                            />
                                          </Button>
                                          <Menu
                                            id={list4.id}
                                            anchorEl={anchorElStatus}
                                            open={openStatus}
                                            onClose={handleStatusClose}
                                            anchorOrigin={{
                                              vertical: "top",
                                              horizontal: "right",
                                            }}
                                            transformOrigin={{
                                              vertical: "top",
                                              horizontal: "left",
                                            }}
                                            MenuListProps={{
                                              "aria-labelledby":
                                                "sub-basic-button4",
                                            }}
                                            sx={{
                                              "& .MuiMenu-paper": {
                                                borderRadius: "4px",
                                                border: "none",
                                                minWidth: "130px",
                                              },
                                            }}
                                          >
                                            <Paper
                                              sx={{
                                                width: 100,
                                                maxWidth: "100%",
                                                background: "#fff",
                                                borderRadius: "10px",
                                                borderBottom: "none",
                                                boxShadow: "none",
                                              }}
                                            >
                                              <MenuItem
                                                onClick={() => {
                                                  handleStatusClose(),
                                                    handleInvite(
                                                      list4.id,
                                                      "Responded"
                                                    );
                                                }}
                                                sx={{
                                                  fontSize: "0.9rem",
                                                  fontWeight: "600",
                                                  lineHeight: "20px",
                                                  color: "lightSecondary.main",
                                                  py: 0.5,
                                                  px: 1.4,
                                                  "&:hover": {
                                                    backgroundColor:
                                                      "extraLTheme.main",
                                                  },
                                                  "&.active": {
                                                    backgroundColor:
                                                      "primary.main",
                                                    color: "#fff",
                                                    borderRadius: "10px",
                                                  },
                                                }}
                                              >
                                                Responded
                                              </MenuItem>
                                              <MenuItem
                                                onClick={() => {
                                                  handleStatusClose(),
                                                    handleInvite(
                                                      list4.id,
                                                      "Shortlisted"
                                                    );
                                                }}
                                                sx={{
                                                  fontSize: "0.9rem",
                                                  fontWeight: "600",
                                                  lineHeight: "20px",
                                                  color: "lightSecondary.main",
                                                  py: 0.5,
                                                  px: 1.4,
                                                  "&:hover": {
                                                    backgroundColor:
                                                      "extraLTheme.main",
                                                  },
                                                  "&.active": {
                                                    backgroundColor:
                                                      "primary.main",
                                                    color: "#fff",
                                                    borderRadius: "10px",
                                                  },
                                                }}
                                              >
                                                In Review
                                              </MenuItem>
                                              <MenuItem
                                                onClick={() => {
                                                  handleStatusClose(),
                                                    handleInvite(
                                                      list4.id,
                                                      "Selected"
                                                    );
                                                }}
                                                sx={{
                                                  fontSize: "0.9rem",
                                                  fontWeight: "600",
                                                  lineHeight: "20px",
                                                  color: "lightSecondary.main",
                                                  py: 0.5,
                                                  px: 1.4,
                                                  "&:hover": {
                                                    backgroundColor:
                                                      "extraLTheme.main",
                                                  },
                                                  "&.active": {
                                                    backgroundColor:
                                                      "primary.main",
                                                    color: "#fff",
                                                    borderRadius: "10px",
                                                  },
                                                }}
                                              >
                                                Selected
                                              </MenuItem>
                                              <MenuItem
                                                onClick={() => {
                                                  handleStatusClose(),
                                                    handleInvite(
                                                      list4.id,
                                                      "Rejected"
                                                    );
                                                }}
                                                sx={{
                                                  fontSize: "0.9rem",
                                                  fontWeight: "600",
                                                  lineHeight: "20px",
                                                  color: "lightSecondary.main",
                                                  py: 0.5,
                                                  px: 1.4,
                                                  "&:hover": {
                                                    backgroundColor:
                                                      "extraLTheme.main",
                                                  },
                                                  "&.active": {
                                                    backgroundColor:
                                                      "primary.main",
                                                    color: "#fff",
                                                    borderRadius: "10px",
                                                  },
                                                }}
                                              >
                                                Rejected
                                              </MenuItem>
                                            </Paper>
                                          </Menu>
                                        </div>
                                      </Typography>
                                    </Paper>
                                  </Menu>
                                </div>
                              </Box>
                            </Stack>
                            <Box>
                              <Typography
                                sx={{
                                  fontSize: "0.9rem",
                                  fontWeight: "600",
                                  color: "secondary.main",
                                  lineHeight: "22px",
                                  mb: 0.3,
                                  minHeight: "30px",
                                }}
                                variant="h6"
                              >
                                {list4.name}
                              </Typography>
                              <Typography
                                noWrap
                                variant="body2"
                                sx={{
                                  fontSize: "12px",
                                  fontWeight: "500",
                                  color: "ltheme.main",
                                }}
                              >
                                {list4.date}
                              </Typography>
                            </Box>
                            <Button
                              // variant="contained"
                              onClick={() =>
                                handleGoToChat(
                                  list4.solutionId,
                                  list4.userId,
                                  list4.solutionName,
                                  list4.solutionProviderId,
                                  list4.groupChatId,
                                  list4.logo
                                )
                              }
                              sx={{
                                width: "100%",
                                textTransform: "inherit",
                                p: "10px",
                                bgcolor: "#ECF6FF",
                                color: "#2A589C",
                                fontWeight: "bold",
                                boxShadow: "none",
                                mt: 1,
                                "&:hover": {
                                  boxShadow: "none",
                                  bgcolor: "#ECF6FF",
                                  color: "#2A589C",
                                },
                              }}
                            >
                              Go to Inbox
                              <ArrowForwardOutlinedIcon
                                sx={{
                                  fontSize: "20px",
                                  ml: "8px",
                                  color: "#2A589C",
                                }}
                              />
                            </Button>
                            {/* <Stack
                            direction="row"
                            sx={{
                              width: "100%",
                              alignItems: "center",
                              justifyContent: 'space-between',
                              mt: 1.5
                            }}
                            draggable={false}
                            onDragStart={(e) => e.preventDefault()}
                          >
                            <AvatarGroup max={3}
                              sx={{
                                height: 32,
                                overflow: 'visible'
                              }}>
                              <Avatar
                                alt="Remy Sharp"
                                src={getImages("1.jpg")}
                                sx={{ width: 32, height: 32 }}
                              />
                              <Avatar
                                alt="Remy Sharp"
                                src={getImages("2.jpg")}
                                sx={{ width: 32, height: 32 }}
                              />
                              <Avatar
                                alt="Remy Sharp"
                                src={getImages("3.jpg")}
                                sx={{ width: 32, height: 32 }}
                              />
                            </AvatarGroup>
                            <Box
                            draggable={false}
                            onDragStart={(e) => e.preventDefault()}
                            >
                              <Typography variant="subtitle1"
                                sx={{
                                  fontSize: "0.95rem",
                                  color: "ltheme.main",
                                  fontWeight: "600"
                                }}>
                                <Badge color="secondary" badgeContent=" " variant="dot"
                                  sx={{
                                    '& .MuiBadge-badge': {
                                      backgroundColor: "red.main"
                                    }
                                  }}
                                >
                                  <MessageOutlinedIcon
                                    sx={{
                                      verticalAlign: "middle",
                                      fill: "ltheme.main",
                                      width: "20px",
                                      height: "20px"
                                    }}
                                  />
                                </Badge>
                                &nbsp;
                                {list4.msg}
                              </Typography>
                            </Box>
                          </Stack> */}
                          </ListItemButton>
                        </>
                      );
                    })}
                    <ListItemButton
                      sx={{
                        // minHeight: "65px",
                        // background: "#fff",
                        borderRadius: "10px",
                        marginTop: "5px",
                        height: "100%",
                      }}
                      onDragEnter={(e) => dragEnter(e, "Selected")}
                      onDragEnd={drop}
                    ></ListItemButton>
                  </List>
                </Box>
              </MainCard>
            </Box>
            {/* Rejected */}
            <Box
              sx={{
                flexShrink: 0, // Prevents the box from shrinking
                width: "calc(20% - 10px)", // Adjusted width to fit within container
                minWidth: "280px", // Adjusted minimum width
                overflowWrap: "break-word", // Ensure text wraps within box
              }}
            >
              <Box
                sx={{
                  marginBottom: "10px",
                  backgroundColor: "#FFB2B2",
                  textAlign: "center",
                  borderRadius: "10px",
                  padding: "11px",
                }}
              >
                <Typography
                  variant="h6"
                  color="textSecondary"
                  sx={{
                    fontSize: "0.9rem",
                    fontWeight: "600",
                    color: "secondary.main",
                  }}
                >
                  Rejected
                </Typography>
              </Box>
              <MainCard
                content={false}
                sx={{
                  backgroundColor: "#ECF6FF",
                  border: "none",
                  padding: "5px 10px 10px",
                  // height: 'calc(100vh - 10px)',
                  height: "100%",
                  maxHeight: open ? "95vh" : "95vh",
                  overflowY: "auto",
                  "&::-webkit-scrollbar": {
                    opacity: 0,
                    width: "6px",
                  },
                  ":hover": {
                    "&::-webkit-scrollbar": {
                      width: "6px",
                      borderRadius: "4px",
                    },
                    "&::-webkit-scrollbar-track": {
                      backgroundColor: "transparent",
                      borderRadius: "4px",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      backgroundColor: "#9C9C9C",
                      borderRadius: "4px",
                    },
                  },
                }}
                // onDragEnter={(e) => dragEnter(e, "Rejected")}
                //         onDragEnd={drop}
              >
                <Box sx={{ p: 0, pb: 0, height: "100%" }}>
                  <List
                    component="nav"
                    sx={{
                      height: "100%",
                      px: 0,
                      py: 0,
                      "& .MuiListItemButton-root": {
                        py: 1.5,
                      },
                    }}
                  >
                    {Rejected.length === 0 && (
                      <ListItemButton
                        sx={{
                          minHeight: "65px",
                          background: "#fff",
                          borderRadius: "10px",
                          marginTop: "5px",
                        }}
                        onDragEnter={(e) => dragEnter(e, "Rejected")}
                        onDragEnd={drop}
                      >
                        Drag items here
                      </ListItemButton>
                    )}

                    {Rejected?.map((list3, index) => {
                      return (
                        <>
                          <ListItemButton
                            id={index}
                            key={index}
                            sx={{
                              minHeight: "170px",
                              height: "170px",
                              display: "block",
                              alignItems: "center",
                              background: "#FFFFFF",
                              borderRadius: "10px",
                              marginTop: "7px",
                              py: 3,
                              px: 2.5,
                            }}
                            onDragStart={(e) => dragStart(e, "Rejected")}
                            onDragEnter={(e) => dragEnter(e, "Rejected")}
                            onDragEnd={drop}
                            onDragOver={dragOver}
                            draggable
                          >
                            <Stack
                              direction="row"
                              sx={{
                                width: "100%",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <Box
                                draggable={false}
                                onDragStart={(e) => e.preventDefault()}
                              >
                                <img
                                  style={{
                                    width: "106px",
                                    height: "30px",
                                    mixBlendMode: "multiply",
                                  }}
                                  src={list3.logo}
                                  alt="logo3"
                                />
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  gap: "10px",
                                  alignItems: "center",
                                }}
                              >
                                <IconButton
                                  draggable={false}
                                  onDragStart={(e) => e.preventDefault()}
                                >
                                  <Link
                                    to={list3.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <OpenInNewIcon
                                      sx={{ color: "lightSecondary.main" }}
                                    />
                                  </Link>
                                </IconButton>
                                <div style={{ position: "relative" }}>
                                  <Button
                                    id="basic-button5"
                                    aria-controls={
                                      openDw ? "basic-menu5" : undefined
                                    }
                                    aria-haspopup="true"
                                    aria-expanded={openDw ? "true" : undefined}
                                    onClick={(event) =>
                                      handleClick(event, list3.id, list3.status)
                                    }
                                    sx={{
                                      p: 0,
                                      minWidth: "10px",
                                      verticalAlign: "0.5px",
                                    }}
                                  >
                                    <MoreVertOutlinedIcon
                                      sx={{ color: "secondary.main" }}
                                      style={{ marginLeft: "0" }}
                                    />
                                  </Button>
                                  <Menu
                                    id={list3.id}
                                    anchorEl={anchorEl}
                                    open={openDw}
                                    onClose={handleClose}
                                    MenuListProps={{
                                      "aria-labelledby": "basic-button5",
                                    }}
                                    sx={{
                                      "& .MuiMenu-paper": {
                                        borderRadius: "4px",
                                        border: "none",
                                        minWidth: "210px",
                                        p: 0.5,
                                      },
                                    }}
                                  >
                                    <Paper
                                      sx={{
                                        width: 210,
                                        maxWidth: "100%",
                                        background: "#fff",
                                        borderRadius: "10px",
                                        borderBottom: "none",
                                        boxShadow: "none",
                                        p: 0.5,
                                      }}
                                    >
                                      {/* <Divider
                                    sx={{
                                      borderColor: "ltheme.main",
                                    }}
                                  /> */}
                                      <Typography
                                        sx={{
                                          fontSize: "0.9rem",
                                          fontWeight: "600",
                                          lineHeight: "20px",
                                          color: "lightSecondary.main",
                                          display: "block",
                                          py: 1,
                                          px: 1.4,
                                          "&:hover": {
                                            backgroundColor: "extraLTheme.main",
                                          },
                                          "&.active": {
                                            backgroundColor: "primary.main",
                                            color: "#fff",
                                            borderRadius: "10px",
                                          },
                                        }}
                                      >
                                        Status: <br />
                                        <div>
                                          <Button
                                            id="sub-basic-button5"
                                            key={list3.id}
                                            aria-controls={
                                              openStatus
                                                ? "sub-basic-menu5"
                                                : undefined
                                            }
                                            aria-haspopup="true"
                                            aria-expanded={
                                              openStatus ? "true" : undefined
                                            }
                                            onClick={(event) => {
                                              handleStatusClick(
                                                event,
                                                list3.id,
                                                list3.status
                                              ),
                                                event.stopPropagation();
                                            }}
                                            sx={{
                                              p: openStatus
                                                ? "10px 10px"
                                                : "10px  0",
                                              minWidth: "10px",
                                              width: "100%",
                                              verticalAlign: "-5px",
                                              display: "flex",
                                              justifyContent: "space-between",
                                              color: openStatus
                                                ? "#FFB185"
                                                : "secondary.main",
                                              backgroundColor: openStatus
                                                ? "#2A589C"
                                                : "#fff",
                                            }}
                                          >
                                            <div>Select Status</div>
                                            <ChevronRightOutlinedIcon
                                              sx={{ color: "secondary.main" }}
                                              style={{ marginLeft: "0" }}
                                            />
                                          </Button>
                                          <Menu
                                            id={list3.id}
                                            anchorEl={anchorElStatus}
                                            open={openStatus}
                                            onClose={handleStatusClose}
                                            anchorOrigin={{
                                              vertical: "top",
                                              horizontal: "right",
                                            }}
                                            transformOrigin={{
                                              vertical: "top",
                                              horizontal: "left",
                                            }}
                                            MenuListProps={{
                                              "aria-labelledby":
                                                "sub-basic-button5",
                                            }}
                                            sx={{
                                              "& .MuiMenu-paper": {
                                                borderRadius: "4px",
                                                border: "none",
                                                minWidth: "130px",
                                              },
                                            }}
                                          >
                                            <Paper
                                              sx={{
                                                width: 100,
                                                maxWidth: "100%",
                                                background: "#fff",
                                                borderRadius: "10px",
                                                borderBottom: "none",
                                                boxShadow: "none",
                                              }}
                                            >
                                              <MenuItem
                                                onClick={() => {
                                                  handleStatusClose(),
                                                    handleInvite(
                                                      list3.id,
                                                      "Responded"
                                                    );
                                                }}
                                                sx={{
                                                  fontSize: "0.9rem",
                                                  fontWeight: "600",
                                                  lineHeight: "20px",
                                                  color: "lightSecondary.main",
                                                  py: 0.5,
                                                  px: 1.4,
                                                  "&:hover": {
                                                    backgroundColor:
                                                      "extraLTheme.main",
                                                  },
                                                  "&.active": {
                                                    backgroundColor:
                                                      "primary.main",
                                                    color: "#fff",
                                                    borderRadius: "10px",
                                                  },
                                                }}
                                              >
                                                Responded
                                              </MenuItem>
                                              <MenuItem
                                                onClick={() => {
                                                  handleStatusClose(),
                                                    handleInvite(
                                                      list3.id,
                                                      "Shortlisted"
                                                    );
                                                }}
                                                sx={{
                                                  fontSize: "0.9rem",
                                                  fontWeight: "600",
                                                  lineHeight: "20px",
                                                  color: "lightSecondary.main",
                                                  py: 0.5,
                                                  px: 1.4,
                                                  "&:hover": {
                                                    backgroundColor:
                                                      "extraLTheme.main",
                                                  },
                                                  "&.active": {
                                                    backgroundColor:
                                                      "primary.main",
                                                    color: "#fff",
                                                    borderRadius: "10px",
                                                  },
                                                }}
                                              >
                                                In Review
                                              </MenuItem>
                                              <MenuItem
                                                onClick={() => {
                                                  handleStatusClose(),
                                                    handleInvite(
                                                      list3.id,
                                                      "Selected"
                                                    );
                                                }}
                                                sx={{
                                                  fontSize: "0.9rem",
                                                  fontWeight: "600",
                                                  lineHeight: "20px",
                                                  color: "lightSecondary.main",
                                                  py: 0.5,
                                                  px: 1.4,
                                                  "&:hover": {
                                                    backgroundColor:
                                                      "extraLTheme.main",
                                                  },
                                                  "&.active": {
                                                    backgroundColor:
                                                      "primary.main",
                                                    color: "#fff",
                                                    borderRadius: "10px",
                                                  },
                                                }}
                                              >
                                                Selected
                                              </MenuItem>
                                              <MenuItem
                                                onClick={() => {
                                                  handleStatusClose(),
                                                    handleInvite(
                                                      list3.id,
                                                      "Rejected"
                                                    );
                                                }}
                                                sx={{
                                                  fontSize: "0.9rem",
                                                  fontWeight: "600",
                                                  lineHeight: "20px",
                                                  color: "lightSecondary.main",
                                                  py: 0.5,
                                                  px: 1.4,
                                                  "&:hover": {
                                                    backgroundColor:
                                                      "extraLTheme.main",
                                                  },
                                                  "&.active": {
                                                    backgroundColor:
                                                      "primary.main",
                                                    color: "#fff",
                                                    borderRadius: "10px",
                                                  },
                                                }}
                                              >
                                                Rejected
                                              </MenuItem>
                                            </Paper>
                                          </Menu>
                                        </div>
                                      </Typography>
                                    </Paper>
                                  </Menu>
                                </div>
                              </Box>
                            </Stack>
                            <Box>
                              <Typography
                                sx={{
                                  fontSize: "0.9rem",
                                  fontWeight: "600",
                                  color: "secondary.main",
                                  lineHeight: "22px",
                                  mb: 0.3,
                                  minHeight: "30px",
                                }}
                                variant="h6"
                              >
                                {list3.name}
                              </Typography>
                              <Typography
                                noWrap
                                variant="body2"
                                sx={{
                                  fontSize: "12px",
                                  fontWeight: "500",
                                  color: "ltheme.main",
                                }}
                              >
                                {list3.date}
                              </Typography>
                            </Box>

                            {/* <Stack
                            direction="row"
                            sx={{
                              width: "100%",
                              alignItems: "center",
                              justifyContent: 'space-between',
                              mt: 1.5
                            }}
                            draggable={false}
                            onDragStart={(e) => e.preventDefault()}
                          >
                            <AvatarGroup max={3}
                              sx={{
                                height: 32,
                                overflow: 'visible'
                              }}>
                              <Avatar
                                alt="Remy Sharp"
                                src={getImages("1.jpg")}
                                sx={{ width: 32, height: 32 }}
                              />
                              <Avatar
                                alt="Remy Sharp"
                                src={getImages("2.jpg")}
                                sx={{ width: 32, height: 32 }}
                              />
                              <Avatar
                                alt="Remy Sharp"
                                src={getImages("3.jpg")}
                                sx={{ width: 32, height: 32 }}
                              />
                            </AvatarGroup>
                            <Box
                            draggable={false}
                            onDragStart={(e) => e.preventDefault()}
                            >
                              <Typography variant="subtitle1"
                                sx={{
                                  fontSize: "0.95rem",
                                  color: "ltheme.main",
                                  fontWeight: "600"
                                }}>
                                <Badge color="secondary" badgeContent=" " variant="dot"
                                  sx={{
                                    '& .MuiBadge-badge': {
                                      backgroundColor: "red.main"
                                    }
                                  }}
                                >
                                  <MessageOutlinedIcon
                                    sx={{
                                      verticalAlign: "middle",
                                      fill: "ltheme.main",
                                      width: "20px",
                                      height: "20px"
                                    }}
                                  />
                                </Badge>
                                &nbsp;
                                {list3.msg}
                              </Typography>
                            </Box>
                          </Stack> */}
                          </ListItemButton>
                        </>
                      );
                    })}

                    <ListItemButton
                      sx={{
                        // minHeight: "65px",
                        // background: "#fff",
                        borderRadius: "10px",
                        marginTop: "5px",
                        height: "100%",
                      }}
                      onDragEnter={(e) => dragEnter(e, "Rejected")}
                      onDragEnd={drop}
                    ></ListItemButton>
                  </List>
                </Box>
              </MainCard>
            </Box>
          </Box>
        </Box>
      </Box>
      {/* <Modal open={isLoading == true}>
          <div></div>
      </Modal> */}
      <Modals
        open={openBoardSettings}
        handleClose={closeBoardSettings}
        modalWidth={500}
      >
        <BoardSetting
          handleClose={closeBoardSettings}
          deleteBoardAlertHandle={deleteBoardAlertHandle}
          boardName={boardName}
          showToastMessage={showToastMessage}
          refetch={refetch}
        />
      </Modals>
      <Modals
        open={openDeleteBoardAlert}
        handleClose={closeDeleteBoardAlert}
        modalWidth={500}
      >
        <DeleteBoardAlert
          handleClose={closeDeleteBoardAlert}
          goBackFromAlertHandle={goBackFromAlertHandle}
          showToastMessage={showToastMessage}
          refetchKanbanBoard={refetch}
          deleteTitle={"Board"}
        />
      </Modals>
    </>
  );
};

export default Kanban;
