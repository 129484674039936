import React, { useEffect, useState } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import theme from './styles/muiTheme';
import { Outlet, useLocation } from "react-router-dom";
import {store} from './redux/store/store';
import { Provider, useDispatch ,useSelector} from 'react-redux';
// import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Layout from './commonComponents/layout';
import './assets/css/style.css'
import FrontLayout from './commonComponents/Frontend/FrontLayout';
import TitleMap from './commonComponents/titleMap';
import ThemeRoutes from './routes';
import Cookies from 'js-cookie';

function App() {
  const location = useLocation();
  const [title, setTitle] = useState('');
  const [userType, setUserType] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkUserType = () => {
        const storedUserType = localStorage.getItem("userType");
        if (storedUserType) {
            setUserType(storedUserType);
            setLoading(false);
        }
    };

    // Initial check
    checkUserType();

    // Set an interval to keep checking
    const intervalId = setInterval(() => {
        checkUserType();
    }, 1000); // Check every second

    // Cleanup interval on component unmount or when userType is set
    return () => clearInterval(intervalId);
}, []);

  // Update title whenever the pathname or userType changes
  useEffect(() => {
    const newTitle = TitleMap(location.pathname, userType);
    setTitle(newTitle);
  }, [location.pathname, userType]);


  return (
  
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <ThemeRoutes />
        {location.pathname === '/ask-for-technology' || location.pathname === '/solution-listing' ? (
          <FrontLayout>
            <Outlet />
          </FrontLayout>
        ) : (
          <Layout title={title}>
            <Outlet title={title}/>
          </Layout>
        )}
       </ThemeProvider>
      <ToastContainer/>
      {/* <ReactQueryDevtools initialIsOpen={false} position='bottom-left' /> */}
    </Provider>
  );
}

export default App;
