import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl =process.env.REACT_APP_base_URL;

const updateBusinessUserProfile = createApi({
    reducerPath: 'updateBusinessUserProfile',
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl,
        prepareHeaders: (headers) => {
            // Add any headers you need here
            return headers;
        },
    }),
    endpoints: (builder) => ({
        updateBusinessUserProfile: builder.mutation({
            query: ({ profileId, formData}) => ({
                url:`user/business/update-business-user-profile-first-form/${profileId}`,
                method: 'PUT',
                body: formData,
            }),
            transformResponse: (response) => {
                return response;
            },
        }),
    }),
});

export const { useUpdateBusinessUserProfileMutation } = updateBusinessUserProfile;
export default updateBusinessUserProfile;
