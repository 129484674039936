import { combineReducers } from 'redux';
// Import feature reducers
import chatReducer from '../features/chatSlice';
import drawerReducer from '../features/drawerSlice';
import userReducer from '../features/userSlice';
// Import API reducers
import { apiReducers } from './apiMiddleware';
import kanbanReducer from '../features/kanbanSlice';
import refetchReducer from '../features/refetch';
import solutionsReducer from '../features/inboxSolutions';
import driveReducer from '../features/driveSlice';
import chatLoadingReducer from '../features/chatLoadingSLice';
import loaderReducer from '../features/loader';
import boardReducer from '../features/boardSlice';
import inboxReducer from '../features/inboxSlice';
import businessUserRequirementReducer from '../features/SolutionProvider/businessUserRequirementSlice';
import viewRequirementReducer from  '../features/BusinessUser/ViewRequirementSlice';
import inviteSolutionProviderReducer from  '../features/BusinessUser/inviteSolutionProvider';
import createSolutionReducer from  '../features/SolutionProvider/createSolutionSlice';
import editSolutionReducer from  '../features/SolutionProvider/editSolutionSlice';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'
import persistConfig from './persistConfig';



const rootReducer = combineReducers({
  ...apiReducers, // Spread API reducers
  drawer: drawerReducer,
  chat: chatReducer,
  user: userReducer,
  kanban: kanbanReducer,
  refetch: refetchReducer,
  solutions: solutionsReducer,
  drive: driveReducer,
  chatLoadingSlice: chatLoadingReducer,
  loader: loaderReducer,
  board: boardReducer,
  inbox: inboxReducer,
  businessUserRequirement: businessUserRequirementReducer,
  viewRequirementSlice: viewRequirementReducer, 
  inviteSolutionProviderSlice: inviteSolutionProviderReducer,
  createSolution: createSolutionReducer,
  editSolution: editSolutionReducer,
});


const persistedReducer = persistReducer(persistConfig, rootReducer);

export default persistedReducer; 
