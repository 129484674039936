import { fetchBaseQuery, createApi } from '@reduxjs/toolkit/query/react';

const baseUrl = process.env.REACT_APP_base_URL;

export const getSolutionDetail  = createApi({
    reducerPath: 'solutionDetail',
    baseQuery: fetchBaseQuery({
      baseUrl: baseUrl,
      prepareHeaders: (headers) => {
        // Add any headers you need here
        return headers;
      },
    }),
    endpoints: (builder) => ({
      fetchSolutionDetail: builder.query({
        query: ({solutionId }) => ({
          url: `solution/detail/${solutionId}`,
          method: 'GET',
        }),
        transformResponse: (response) => {
           return response;
        },
        refetchOnWindowFocus: true,
        // retry: RetryOnError,
        onError: (error) => {
          console.error('Fetch user details error:', error);
          // Handle error cases as per your application's requirements
        },
      })
    })
})


export const { useFetchSolutionDetailQuery  } = getSolutionDetail;
