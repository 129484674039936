import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl = process.env.REACT_APP_base_URL;

const messageRead = createApi({
    reducerPath: 'messageRead',
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl,
        prepareHeaders: (headers) => {
            // Add any headers you need here
            return headers;
        },
    }),
    endpoints: (builder) => ({
        fetchMessageRead: builder.query({
            query: ({ userId  , groupChatId}) => ({
                url:`message/read/${userId}?chatId=${groupChatId}`,
                method: 'GET',
            }),
            transformResponse: (response) => {
                return response;
            },
        }),
    }),
});

export const { useFetchMessageReadQuery } = messageRead;
export default messageRead;


