import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { Provider } from 'react-redux';
import {persistor, store} from './redux/store/store';
import { PersistGate } from 'redux-persist/integration/react'
import MainRoutesWrapper from './routes/appRoutes';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
    <MainRoutesWrapper />
    </PersistGate>

    </Provider>
  </React.StrictMode> 
);
