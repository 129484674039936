import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  FormControl,
  Autocomplete,
} from "@mui/material";
import { useFetchRequirementBoardQuery } from "../../redux/api/Requirement Boards/getRequirementBoardList";
import { useSendMemberInvitationMutation } from "../../redux/api/Requirement Boards/getInviteBsiColab";
import { useFetchBusinessUserDetailsQuery } from "../../redux/api/Users/getuserProfile";
import { toast } from "react-toastify";
import { Grid } from "@mui/material";
import { useFetchSolutionProviderSolutionListQuery } from "../../redux/api/SolutionProvider/solutionList";

const InvitetoBSIColab = ({handleClose, refetchTeamMemberList , }) => {
  const [selectedUsers, setSelectedUsers] = useState([]);
  const userType = localStorage.getItem("userType");
  const [searchValue, setSearchValue] = useState("");
  const [industryFilterId, setIndustryFilterId] = useState("");
  const [techValue, setTechValue] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({
    firstName: false,
    lastName: false,
    email: false,
    users: false,
  });

  const userId = localStorage.getItem("userId");

  const {
    data: boardData,
    error: boardError,
    isLoading: boardLoading,
    refetch: refetchBoard,
  } = useFetchRequirementBoardQuery({
    user_id: userId,
    boardName: searchValue,
    sort: "",
    industryFilterId: industryFilterId,
    techFilterValue: techValue,
  });
  const {
    data: solutionList,
    error: solutionListError,
    isLoading: solutionListLoading,
    refetch: refetchSolutionList,
  } = useFetchSolutionProviderSolutionListQuery({ userId });
  const {
    data: userProfileData,
    isLoading: userLoading,
    isError: userError,
    refetch: refetchProfile,
  } = useFetchBusinessUserDetailsQuery(userId);

  const showToastMessage = (message, type) => {
    if (type === "success") {
      toast.success(message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (type === "error") {
      toast.error(message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (type === "info") {
      toast.warning(message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const handleFirstName = (event) => {
    setFirstName(event.target.value);
    // Clear title error when the field is not empty
    if (event.target.value !== "") {
      setErrors((prevErrors) => ({ ...prevErrors, firstName: false }));
    }
  };
  const handleLastName = (event) => {
    setLastName(event.target.value);
    // Clear title error when the field is not empty
    if (event.target.value !== "") {
      setErrors((prevErrors) => ({ ...prevErrors, lastName: false }));
    }
  };
  const handleEmail = (event) => {
    setEmail(event.target.value);
    // Clear title error when the field is not empty
    if (event.target.value !== "") {
      setErrors((prevErrors) => ({ ...prevErrors, email: false }));
    }
  };
  const handleUserSelection = (event, value) => {
    // Filter out the unwanted options ("No board available" and "No solution available")
    const filteredValue = value.filter(
      (item) => item !== "No board available" && item !== "No solution available"
    );
    
    // Set the filtered value as the selected users
    setSelectedUsers(filteredValue);
  
    // Remove the error if the selection is valid
    if (filteredValue.length > 0) {
      setErrors((prevErrors) => ({ ...prevErrors, users: false }));
    }
  };

  const boardNames =
    boardData?.length > 0
      ? boardData.map((item) => item.boardName)
      : ["No board available"];

  const boardIds =
    boardData?.length > 0
      ? boardData.map((item) => item.board_id)
      : ["No ids available"];
  const solutionName =
    solutionList?.data?.solutions?.length > 0
      ? solutionList?.data?.solutions.map((item) => item.solution_name)
      : ["No solution available"];

  const solutionIds =
    solutionList?.data?.solutions?.length > 0
      ? solutionList?.data?.solutions.map((item) => item.board_id)
      : ["No ids available"];

  const [sendMemberInvitation] = useSendMemberInvitationMutation();

  const handleSubmit = async () => {
    let hasError = false;
    const newErrors = { title: false, meetingUrl: false, users: false };
    if (firstName === "") {
      newErrors.firstName = true;
      hasError = true;
      document.querySelector("#firstName").focus();
    }
    if (lastName === "") {
      newErrors.lastName = true;
      hasError = true;
      document.querySelector("#lastName").focus();
    }
    if (email === "") {
      newErrors.email = true;
      hasError = true;
      document.querySelector("#email").focus();
    }
    if (!selectedUsers || selectedUsers.length === 0 || selectedUsers.includes("No board available") || selectedUsers.includes("No solution available")) {
      newErrors.users = true;
      hasError = true;
      document.querySelector("#selectedUsers").focus();
    }



    if (hasError) {
      setErrors(newErrors);
      return;
    }
    try {
      const selectedBoardIds = boardData
        .filter((item) => selectedUsers.includes(item.boardName))
        .map((item) => item.board_id);
      const selectedSolutionIds = solutionList?.data?.solutions
        ?.filter((item) => selectedUsers.includes(item.solution_name))
        .map((item) => item._id);
      const response = await sendMemberInvitation({
        email: email,
        first_name: firstName,
        last_name: lastName,
        id: userProfileData?.data._id,
        requirement_id: userType === "solution_provider"?  [] : selectedBoardIds,
        solution_id:selectedSolutionIds,
      }).unwrap();
      handleClose();
      refetchTeamMemberList();
      const successMessage = response?.message;
      if (response.statusCode === 200) {
        showToastMessage(successMessage, "success");
      } else if (response.statusCode === 409) {
        showToastMessage(successMessage, "info");
      }
    } catch (error) {
      showToastMessage("Error sending member invitation:", error);
    }
  };

  return (
    <>
      <Box sx={{ mb: 3 }}>
        <Typography
          variant="h5"
          sx={{
            fontSize: "1.1rem",
            color: "secondary.main",
            fontWeight: "700",
          }}
        >
          Invite to BSI Co-lab
        </Typography>
      </Box>

      <Box>
        <Grid container spacing={4} sx={{ mb: 1 }}>
          <Grid item xs={12} sm={6}>
            <Typography
              variant="h5"
              sx={{
                fontSize: "0.9rem",
                color: "secondary.main",
                fontWeight: "600",
                mb: 0.5,
              }}
            >
              First Name
            </Typography>
            <TextField
              id="firstName"
              value={firstName}
              required
              onChange={handleFirstName}
              hiddenLabel
              variant="filled"
              fullWidth
              sx={{
                width: "100%",
                borderRadius: "4px",
                backgroundColor: "extraLTheme.main",
                border: "none",
                "& .MuiInputBase-input": {
                  fontSize: "0.9rem",
                  color: "lightSecondary.main",
                  fontWeight: "500",
                  pt: 1.5,
                  pb: 1.5,
                  backgroundColor: "extraLTheme.main",
                },
                "&:hover .MuiInputBase-root:hover:before": {
                  border: "none",
                },
                "& .MuiInputBase-root:hover:before": {
                  border: "none",
                },
                "&:hover": {
                  borderColor: "extraLTheme.main",
                },
                "& .MuiInputBase-root:before": {
                  border: "none",
                },
                "& .MuiInputBase-root:after": {
                  border: "none",
                },
              }}
            />
            {errors.firstName && (
              <Typography
                variant="body2"
                color="error"
                sx={{
                  display: "flex",
                  ml: "0px",
                }}
              >
                Please add first name...
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography
              variant="h5"
              sx={{
                fontSize: "0.9rem",
                color: "secondary.main",
                fontWeight: "600",
                mb: 0.5,
              }}
            >
              Last Name
            </Typography>
            <TextField
              id="lastName"
              required
              value={lastName}
              onChange={handleLastName}
              hiddenLabel
              variant="filled"
              fullWidth
              sx={{
                width: "100%",
                borderRadius: "4px",
                backgroundColor: "extraLTheme.main",
                border: "none",
                "& .MuiInputBase-input": {
                  fontSize: "0.9rem",
                  color: "lightSecondary.main",
                  fontWeight: "500",
                  pt: 1.5,
                  pb: 1.5,
                  backgroundColor: "extraLTheme.main",
                },
                "&:hover .MuiInputBase-root:hover:before": {
                  border: "none",
                },
                "& .MuiInputBase-root:hover:before": {
                  border: "none",
                },
                "&:hover": {
                  borderColor: "extraLTheme.main",
                },
                "& .MuiInputBase-root:before": {
                  border: "none",
                },
                "& .MuiInputBase-root:after": {
                  border: "none",
                },
              }}
            />
            {errors.lastName && (
              <Typography
                variant="body2"
                color="error"
                sx={{
                  display: "flex",
                  ml: "0px",
                }}
              >
                Please add last name...
              </Typography>
            )}
          </Grid>
        </Grid>

        <Typography
          variant="h5"
          sx={{
            fontSize: "0.9rem",
            color: "secondary.main",
            fontWeight: "600",
            mb: 0.5,
          }}
        >
          Email
        </Typography>
        <TextField
          id="email"
          required
          type="email"
          value={email}
          onChange={handleEmail}
          hiddenLabel
          variant="filled"
          sx={{
            width: "100%",
            borderRadius: "4px",
            backgroundColor: "extraLTheme.main",
            border: "none",
            "& .MuiInputBase-input": {
              fontSize: "0.9rem",
              color: "lightSecondary.main",
              fontWeight: "500",
              pt: 1.5,
              pb: 1.5,
              backgroundColor: "extraLTheme.main",
            },
            "&:hover .MuiInputBase-root:hover:before": {
              border: "none",
            },
            "& .MuiInputBase-root:hover:before": {
              border: "none",
            },
            "&:hover": {
              borderColor: "extraLTheme.main",
            },
            "& .MuiInputBase-root:before": {
              border: "none",
            },
            "& .MuiInputBase-root:after": {
              border: "none",
            },
          }}
        />
        {errors.email && (
          <Typography
            variant="body2"
            color="error"
            sx={{
              display: "flex",
              ml: "0px",
            }}
          >
            Please add email...
          </Typography>
        )}
        {userType !== "solution_provider" ? (
          <FormControl
            variant="standard"
            fullWidth
            sx={{
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              mt: 1,
            }}
          >
            <Autocomplete
              multiple
              id="selectedUsers"
              value={selectedUsers}
              onChange={handleUserSelection}
              options={boardNames}
              getOptionLabel={(option) => option}
              isOptionDisabled={(option) => option == "No board available"}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Boards List"
                  variant="standard"
                />
              )}
              sx={{
                // minWidth: '20px',
                "& .MuiAutocomplete-inputRoot": {
                  paddingRight: "0px !important", 
                },
                "& .MuiAutocomplete-tag": {
                  marginRight: "5px", 
                },
                "& .MuiAutocomplete-clearIndicator": {
                  display: "none", 
                },
                "& .MuiAutocomplete-popupIndicator": {
                  right: "5px",
                },
                maxHeight: "100px",
                overflowY: "auto",
                "&::-webkit-scrollbar": {
                  opacity: 0,
                  width: "6px",
                },
                "s:hover": {
                  "&::-webkit-scrollbar": {
                    width: "6px",
                    borderRadius: "4px",
                  },
                  "&::-webkit-scrollbar-track": {
                    backgroundColor: "transparent",
                    borderRadius: "4px",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "#9C9C9C",
                    borderRadius: "4px",
                  },
                },
              }}
            />
            {errors.users && (
              <Typography
                variant="body2"
                color="error"
                sx={{
                  display: "flex",
                }}
              >
                Please add at least one participant...
              </Typography>
            )}
          </FormControl>
        ) : (
          // solution provider
          <FormControl
            variant="standard"
            fullWidth
            sx={{
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              mt: 1,
            }}
          >
            <Autocomplete
              multiple
              id="selectedUsers"
              value={selectedUsers}
              onChange={handleUserSelection}
              options={solutionName}
              getOptionLabel={(option) => option}
              isOptionDisabled={(option) => option === "No solution available"}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Solution List"
                  variant="standard"
                />
              )}
              sx={{
                // minWidth: '20px',
                "& .MuiAutocomplete-inputRoot": {
                  paddingRight: "0px !important", // Override the default padding
                },
                "& .MuiAutocomplete-tag": {
                  marginRight: "5px", // Adjust margin between chips if needed
                },
                "& .MuiAutocomplete-clearIndicator": {
                  display: "none", // Adjust position of clear icon if needed
                },
                "& .MuiAutocomplete-popupIndicator": {
                  right: "5px", // Adjust position of popup icon if needed
                },
                maxHeight: "100px",
                overflowY: "auto",
                "&::-webkit-scrollbar": {
                  opacity: 0,
                  width: "6px",
                },
                "s:hover": {
                  "&::-webkit-scrollbar": {
                    width: "6px",
                    borderRadius: "4px",
                  },
                  "&::-webkit-scrollbar-track": {
                    backgroundColor: "transparent",
                    borderRadius: "4px",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "#9C9C9C",
                    borderRadius: "4px",
                  },
                },
              }}
            />
            {errors.users && (
              <Typography
                variant="body2"
                color="error"
                sx={{
                  display: "flex",
                }}
              >
                Please add at least one participant...
              </Typography>
            )}
          </FormControl>
        )}
        <Box sx={{ mt: 3, display: "flex", gap: 2 }}>
          <Button
            variant="outlined"
            sx={{
              textTransform: "inherit",
              p: "10px 25px",
              width: "50%",
              borderWidth: "2px",
              fontWeight: "600",
              "&:hover": {
                borderWidth: "2px",
              },
            }}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{
              textTransform: "inherit",
              p: "12px 25px",
              width: "50%",
              boxShadow: "none",
              fontWeight: "600",
            }}
            onClick={handleSubmit}
          >
            Send Invite
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default InvitetoBSIColab;
