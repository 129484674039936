import { Box, Card, CardContent, Grid, IconButton, Skeleton } from "@mui/material";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";


const MySolutionSkull = ()=>{
    return(
    <>
    <Grid item xs={12} md={6} lg={6} sx={{ mb: 2 }}>
                   <Card sx={{ boxShadow: "none", borderRadius: "10px" }}>
                     <CardContent sx={{ p: 0 }}>
                       <Box sx={{ px: 2, pt: 2, pb: 2 }}>
                         <Box sx={{ display: "flex", alignItems: "start", justifyContent: "space-between" }}>
                           <Box>
                             <Skeleton variant="rectangular" width={50} height={50} />
                           </Box>
                           <Box sx={{ display: "flex", alignItems: "center" }}>
                             <IconButton sx={{ p: 0.5 }}>
                               <OpenInNewOutlinedIcon sx={{ color: "lightSecondary.main" }} />
                             </IconButton>
                           </Box>
                         </Box>
                         <Box sx={{ mt: 1, maxWidth: "80%" }}>
                           <Box>
                             <Skeleton variant="text" width="100%" height={30} sx={{ mb: 1 }} />
                             <Skeleton variant="text" width="90%" height={20} />
                           </Box>
                         </Box>
                       </Box>
                       <Box
                         sx={{
                           py: 2,
                           px: 2,
                           width: "100%",
                           backgroundColor: "rgba(219, 235, 248, 0.2)",
                           display: "flex",
                           alignItems: "start",
                           justifyContent: "space-between",
                           borderTop: "solid 1px rgba(162, 194, 241, 1)",
                         }}
                       >
                         <Box display={"flex"} sx={{ alignItems: "center", maxWidth: "33.33%" }}>
                           <Box>
                             <Skeleton variant="circular" width={24} height={24} />
                           </Box>
                           <Box sx={{ pl: 1 }}>
                             <Skeleton variant="text" width={120} height={18} sx={{ mb: 0.5 }} />
                             <Skeleton variant="text" width={150} height={14} />
                           </Box>
                         </Box>
                         <Box display={"flex"} sx={{ alignItems: "center", maxWidth: "33.33%" }}>
                           <Box>
                             <Skeleton variant="circular" width={24} height={24} />
                           </Box>
                           <Box sx={{ pl: 1 }}>
                             <Skeleton variant="text" width={120} height={18} sx={{ mb: 0.5 }} />
                             <Skeleton variant="text" width={150} height={14} />
                           </Box>
                         </Box>
                         <Box display={"flex"} sx={{ alignItems: "center", maxWidth: "33.33%" }}>
                           <Box>
                             <Skeleton variant="circular" width={24} height={24} />
                           </Box>
                           <Box sx={{ pl: 1 }}>
                             <Skeleton variant="text" width={120} height={18} sx={{ mb: 0.5 }} />
                             <Skeleton variant="text" width={150} height={14} />
                           </Box>
                         </Box>
                       </Box>
                     </CardContent>
                   </Card>
                 </Grid>
    </>
    )
}

export default MySolutionSkull;

