// // store.js
import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import persistedReducer from './rootReducer';
import { persistStore } from 'redux-persist';
import { apiMiddlewares } from './apiMiddleware';

export const store = configureStore({
  reducer:persistedReducer,
  middleware: (getDefaultMiddleware) => 
  getDefaultMiddleware().concat(...apiMiddlewares)
});

export const persistor = persistStore(store)

setupListeners(store.dispatch)

