import { createSlice } from "@reduxjs/toolkit";

const inviteSolutionProviderSlice = createSlice({
    name :'inviteSolutionProviderSlice',
    initialState: {
        boardId: "",

    },
    reducers:{
        getBoardIdForInviteSolutionProvider: (state , action)=> {
          state.boardId =  action.payload
    },
}
});

export const {getBoardIdForInviteSolutionProvider } = inviteSolutionProviderSlice.actions;
export default inviteSolutionProviderSlice.reducer;