import React, { useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
} from '@mui/material';
import { useTheme } from '@emotion/react';
import { DeleteOutlineOutlined } from '@mui/icons-material';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import { useLocation } from 'react-router-dom';
import { useDeleteDriveFolderMutation } from '../../redux/api/BsiDrive/deleteDriveFolder';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { setRefetchDocument, setRefetchSubFolder } from '../../redux/features/refetch';
import { hideLoader, showLoader } from '../../redux/features/loader';

const DeleteFolderAlert = ({ goBackFromAlertHandle,  showToastMessage , documentId }) => {
  const theme = useTheme();
  const location = useLocation();
  const userId = localStorage.getItem("userId");
  const dispatch = useDispatch();
  const [deleteDriveFolder, { isLoading, isError: deleteError }] = useDeleteDriveFolderMutation();
  const deleteItem = async () => {
    try {
      let id = documentId;
      const response = await deleteDriveFolder({id , userId}).unwrap();
      const successMessage = response?.message || 'Item moved';
      showToastMessage(successMessage, 'success');
      goBackFromAlertHandle();
      dispatch(setRefetchDocument(true));
      dispatch(setRefetchDocument(true));
      dispatch(setRefetchSubFolder(true));
    } catch (error) {
      const errorMessage = error.message || "Failed to delete item";
      showToastMessage(errorMessage, "error");
    }
  };

  useEffect(() => {
    if (isLoading) {
      dispatch(showLoader());
    } else {
      dispatch(hideLoader());
    }
  }, [isLoading, dispatch]);

  return (
    <>
      <Box sx={{ mb: 4, mt: 3 }}>
      <Typography variant='h5' sx={{ fontSize: "1.1rem", color: "secondary.main", fontWeight: "700" }}>
      Are you sure you want to delete this document?
      </Typography>
      </Box>
      <Box sx={{ mt: 3, display: 'flex', gap: 2 }}>
        <Button
          variant="outlined"
          sx={{
            textTransform: 'inherit',
            p: "10px 25px",
            width: '50%',
            borderWidth: '2px',
            fontWeight: '600',
            borderColor: 'primary.main',
            '&:hover': { borderWidth: '2px' },
          }}
          onClick={goBackFromAlertHandle}
        >
          <ArrowBackIosNewOutlinedIcon style={{ fontSize: '16px', marginRight: "5px", marginTop: "-2px" }} />
          Go back
        </Button>
        <Button
          variant="outlined"
          sx={{
            textTransform: 'inherit',
            p: "10px 25px",
            borderColor: 'red.main',
            color: 'red.main',
            width: '50%',
            fontSize: '0.9rem',
            fontWeight: '600',
            [theme.breakpoints.down('md')]: { width: "100%", p: "10px 10px" },
            '&:hover': { borderColor: 'red.main', backgroundColor: 'transparent' },
          }}
          onClick={deleteItem}
        >
          <DeleteOutlineOutlined style={{ fontSize: '20px', marginRight: "5px", marginTop: "-3px" }} />
          Delete Document
        </Button>
      </Box>
    </>
  );
};

export default DeleteFolderAlert;
