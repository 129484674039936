import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Grid,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Stack,
  Typography,
  Menu,
  Card,
  CardContent,
  IconButton,
  Radio,
  Select,
  FormControl,
  FormControlLabel,
  Tooltip,
  Skeleton,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import { styled } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import CancelIcon from "@mui/icons-material/Cancel";
import { UserAddOutlined, UsergroupAddOutlined } from "@ant-design/icons";
import {
  KeyboardArrowDownOutlined,
  KeyboardArrowRightOutlined,
} from "@mui/icons-material";
import { getImages } from "../../commonComponents/const";
import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import Modals from "../../commonComponents/Modals";
import CreateNewGroup from "../../commonComponents/ManageTeamMembers/CreateNewGroup";
import { Link } from "react-router-dom";
import NorthOutlinedIcon from "@mui/icons-material/NorthOutlined";
import SouthOutlinedIcon from "@mui/icons-material/SouthOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import InvitetoBSIColab from "../../commonComponents/Inbox/InvitetoBSIColab";
import DirectMessage from "../../commonComponents/ManageTeamMembers/DirectMessage";
import InitiateNewDirectMessage from "../../commonComponents/ManageTeamMembers/InitiateNewDirectMessage";
import SelectBoardSolution from "../../commonComponents/ManageTeamMembers/SelectBoardSolution";
import CreateNewGroupChat from "../../commonComponents/Inbox/CreateNewGroupChat";

import { useFetchTeamMemberListQuery } from "../../redux/api/Requirement Boards/getInviteListsDetail";
import { hideLoader, showLoader } from "../../redux/features/loader";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import InviteToBsiCoLabForSolutionProvider from "../../commonComponents/ManageTeamMembers/InviteToBsiColabForSolutionProvider";
import NoDataScreen from "../../commonComponents/noData";
import { resetRefetchTeamMemberList } from "../../redux/features/refetch";
import ManageTeamSkull from "../../commonComponents/ManageTeamMembers/manageTeamSkull";
import NoTeamMemberScreen from "../../commonComponents/ManageTeamMembers/NoTeamMemberScreen";
import useBreakpoint from "../../commonComponents/ResponsiveBreakPoints";

const ManageTeamMembers = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const userType = localStorage.getItem("userType");
  const { breakpoint } = useBreakpoint();

  // Define skull count based on breakpoints
  const getSkullCount = (breakpoint) => {
    switch (breakpoint) {
      case 'xl':
        return 15; // e.g., 10 skulls for extra-large screens
      case 'lg':
        return 9; // e.g., 8 skulls for large screens
      case 'md':
        return 6; // e.g., 6 skulls for medium screens
      case 'sm':
        return 4; // e.g., 4 skulls for small screens
      case 'xs':
      default:
        return 2; // e.g., 2 skulls for extra-small screens
    }
  };
  const skullCount = getSkullCount(breakpoint);

  // Search Box
  const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "#ECF6FF",
    "&:hover": {
      backgroundColor: "#ECF6FF",
    },
    marginRight: "0",
    marginLeft: 0,
    marginBottom: 0,
    width: "100%",
    display: "flex",
    alignItems: "center",
  }));
  const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    right: "0",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }));

  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "#106EED",
    width: "100%",
    "& .MuiInputBase-input": {
      padding: theme.spacing(1.7, 0, 1.7, 1.7),
      paddingRight: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create("width"),
      width: "100%",
      fontSize: "12px",
      fontWeight: "500",
    },
  }));
  const showToastMessage = (message, type) => {
    if (type === "success") {
      toast.success(message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (type === "error") {
      toast.error(message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (type === "info") {
      toast.warning(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  // Dropdown Menu
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [openSort, setOpenSort] = useState(false);
  const [selectedSortOption, setSelectedSortOption] = useState("");

  const [openFilter, setOpenFilter] = useState(false);
  const [anchorElSort, setAnchorElSort] = useState(null);
  const [anchorElFilter, setAnchorElFilter] = useState(null);

  const userId = localStorage.getItem("userId");
  // Searching logic
  const [searchParams, setSearchParams] = useState("");
  const [searchValue, setSearchValue] = useState("");


  const handleInputChange = (event) => {
    setSearchParams(event.target.value);
  };

  const {
    data: ListData,
    error: MemberListError,
    isLoading: TeamMemberLoading,
    refetch: refetchTeamMemberList,
  } = useFetchTeamMemberListQuery({
    userId: userId,
    searchParams: searchValue,
  });



  const refetchTeamMembers = useSelector(
    (state) => state.refetch.refetchTeamMemberList
  );
  useEffect(() => {
    if (refetchTeamMembers) {
      refetchTeamMemberList().finally(() => {
        dispatch(resetRefetchTeamMemberList());
      });
    }
  }, [refetchTeamMembers, refetchTeamMemberList, dispatch]);

  const handleTeamMemberSearch = async (event) => {
    if (event.key === "Enter") {
      const inputValue = event.target.value.trim();
      if (!inputValue) {
        setSearchValue(""); // Reset the searchValue state
        await refetchTeamMemberList();
        return;
      }

      setSearchValue(inputValue);
      dispatch(showLoader());
      try {
        // Start loading
        await refetchTeamMemberList(); 
        if (MemberListError || ListData.statusCode === 404) {
          const errorMessage = "No result found";
          showToastMessage(errorMessage, "error");
        }
      } catch (MemberListError) {
        showToastMessage("An error occurred during search", "error");
      } finally {
        // Hide loader after refetch is complete
        dispatch(hideLoader());
      }
    }
  };
  const handleClearSearch = () => {
    setSearchParams("");
    setSearchValue(""); // Reset search value to empty
    refetchTeamMemberList(); // Refetch all data
  };

  const handleClickSort = (event) => {
    setAnchorElSort(event.currentTarget);
    setOpenSort(!openSort);
  };
  const handleCloseSort = (event) => {
    setAnchorElSort(null);
    setOpenSort(false);
    setSelectedSortOption(event.target.value);
  };
  const handleClickFilter = (event) => {
    setAnchorElFilter(event.currentTarget);
    setOpenFilter(!openFilter);
  };
  const handleCloseFilter = () => {
    setAnchorElFilter(null);
    setOpenFilter(false);
  };

  // Select Field
  const [designation, setDesignation] = React.useState("");
  const handleChange = (event) => {
    setDesignation(event.target.value);
  };

  // InviteBsiCoLab Modal
  const [openInviteBsiCoLab, setOpenInviteBsiCoLab] = useState(false);
  const InviteBsiCoLabHandle = () => {
    setOpenInviteBsiCoLab(true);
  };
  const closeInviteBsiCoLab = () => {
    setOpenInviteBsiCoLab(false);
  };

  // InviteBsiCoLab Modal for Solution Provider
  const [openInviteBsiCoLabForSolutionProvider, setOpenInviteBsiCoLabForSolutionProvider] = useState(false);
  const InviteBsiCoLabSolutionProviderHandle = () => {
    setOpenInviteBsiCoLabForSolutionProvider(true);
  };
  const closeInviteBsiCoLabSolutionProvider = () => {
    setOpenInviteBsiCoLabForSolutionProvider(false);
  };


  // Direct Message Modal
  const [openDirectMessage, setOpenDirectMessage] = useState(false);
  const directMessageHandle = () => {
    setOpenDirectMessage(true);
  };
  const closedirectMessage = () => {
    setOpenDirectMessage(false);
  };
  // Initiate New Direct Message Modal
  const [openInitiateNewDM, setOpenInitiateNewDM] = useState(false);
  const initiateNewDMHandle = () => {
    setOpenInitiateNewDM(true);
    setOpenDirectMessage(false);
  };
  const closeInitiateNewDM = () => {
    setOpenInitiateNewDM(false);
  };

  // Create New Group
  const [modalopen, setModalOpen] = useState(false);
  const handleModalOpen = () => {
    setModalOpen(true);
  };
  const handleModalClose = () => {
    setModalOpen(false);
  };
  // Selected Borad Solution
  const [openSelectedBoard, setOpenSelectedBoard] = useState(false);
  const selectedBoardOpen = () => {
    setOpenSelectedBoard(true);
    setModalOpen(false);
  };
  const closeSelectedBoard = () => {
    setOpenSelectedBoard(false);
  };
  // Create New Group Chat
  const [openCreateNewGroupChat, setOpenCreateNewGroupChat] = useState(false);
  const createNewGroupChatHandle = () => {
    setOpenCreateNewGroupChat(true);
    setOpenSelectedBoard(false);
  };
  const closeCreateNewGroupChat = () => {
    setOpenCreateNewGroupChat(false);
  };
  const goBackHandle = () => {
    setOpenCreateNewGroupChat(false);
    setOpenSelectedBoard(true);
  };
  
  if (TeamMemberLoading) {
    dispatch(showLoader());
  }else{
    dispatch(hideLoader());
  }


  return (
    <>
      <Grid container rowSpacing={{ md: 4.5, xs: 1 }} columnSpacing={2.75}>
        <Grid item lg={6} md={6} sm={4} xs={12}>
          <Box
            sx={{
              display: "flex",
              gap: 2,
              flexWrap: "wrap",
            }}
          >
            <Box
              sx={{
                [theme.breakpoints.down("sm")]: {
                  width: "100%",
                },
              }}
            >
              <Button
                variant="contained"
                sx={{
                  width: "100%",
                  textTransform: "inherit",
                  p: "12px 25px",
                  boxShadow: "none",
                  "&:hover": {
                    boxShadow: "none",
                  },
                }}
                // onClick={userType !== "solution_provider"?InviteBsiCoLabHandle:InviteBsiCoLabSolutionProviderHandle}
                onClick={InviteBsiCoLabHandle}
              >
                <UserAddOutlined
                  style={{
                    width: "20px",
                    height: "20px",
                    marginRight: "1px",
                    // marginTop: "4px",
                  }}
                />
                Invite to BSI Co-lab
              </Button>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} lg={6} sm={8}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              gap: 2,
            }}
          >
            <Box
              sx={{
                mr: 0.5,
                width: "45%",
                [theme.breakpoints.down("sm")]: {
                  width: "100%",
                  mr: 0,
                },
              }}
            >
              <Search
                sx={{
                  backgroundColor: "extraLTheme.main",
                  mr: 2,
                  ml: "auto",
                  [theme.breakpoints.down("sm")]: {
                    mr: 0,
                    ml: "auto",
                  },
                }}
              >
                <StyledInputBase
                  id="searchBox"
                  placeholder="Search file or folder"
                  inputProps={{ "aria-label": "search" }}
                  value={searchParams}
                  onChange={handleInputChange}
                  onKeyDown={handleTeamMemberSearch}
                  autoFocus
                />
                {searchParams ? (
                  <IconButton
                    onClick={handleClearSearch}
                    sx={{ position: "absolute", right: 0 }}
                  >
                    <CancelIcon sx={{ color: "lightSecondary.main" }} />
                  </IconButton>
                ) : (
                  <SearchIconWrapper>
                    <SearchIcon sx={{ color: "lightSecondary.main" }} />
                  </SearchIconWrapper>
                )}
              </Search>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                gap: 1,
              }}
            >
              <Box
                sx={{
                  mr: 1,
                }}
              >
                <Button
                  id="basic-button"
                  aria-controls={openSort ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={openSort ? "true" : undefined}
                  onClick={handleClickSort}
                  sx={{
                    background: "#fff",
                    fontSize: "0.9rem",
                    color: "lightSecondary.main",
                    textTransform: "inherit",
                    fontWeight: "600",
                    p: "10px 20px",
                  }}
                >
                  Sort
                  <KeyboardArrowDownOutlined />
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorElSort}
                  open={openSort}
                  onClose={handleCloseSort}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  sx={{
                    "& .MuiMenu-paper": {
                      borderRadius: "10px",
                      border: "solid 1px #106EED",
                    },
                  }}
                >
                  {/* <MenuItem
                    sx={{
                        '& .MuiFormControlLabel-label': {
                        fontSize: "0.9rem",
                        color: "#2A589C",
                        fontWeight: "600",
                        }
                    }}
                    onClick={handleCloseSort}>
                    <FormControlLabel
                        value="CreatedUp" control={<Radio sx={{ paddingY: 0, color: "#2A589C" }} checked={selectedSortOption === 'CreatedUp'} />} label="Created" />
                    <NorthOutlinedIcon sx={{
                        fontSize: "1.1rem",
                        color: "#2A589C"
                    }} />
                    </MenuItem>
                    <MenuItem
                    sx={{
                        '& .MuiFormControlLabel-label': {
                        fontSize: "0.9rem",
                        color: "#2A589C",
                        fontWeight: "600",
                        }
                    }}
                    onClick={handleCloseSort}>
                    <FormControlLabel
                        value="CreatedDown" control={<Radio sx={{ paddingY: 0, color: "#2A589C" }} checked={selectedSortOption === 'CreatedDown'} />} label="Created" />
                    <SouthOutlinedIcon sx={{
                        fontSize: "1.1rem",
                        color: "#2A589C"
                    }} />
                    </MenuItem> */}
                  <MenuItem
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        fontSize: "0.9rem",
                        color: "#2A589C",
                        fontWeight: "600",
                      },
                    }}
                    onClick={handleCloseSort}
                  >
                    <FormControlLabel
                      value="name-a-z"
                      control={
                        <Radio
                          sx={{ paddingY: 0, color: "#2A589C" }}
                          checked={selectedSortOption === "name-a-z"}
                        />
                      }
                      label="Name A-Z"
                    />
                  </MenuItem>
                  <MenuItem
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        fontSize: "0.9rem",
                        color: "#2A589C",
                        fontWeight: "600",
                      },
                    }}
                    onClick={handleCloseSort}
                  >
                    <FormControlLabel
                      value="name-z-a"
                      control={
                        <Radio
                          sx={{ paddingY: 0, color: "#2A589C" }}
                          checked={selectedSortOption === "name-z-a"}
                        />
                      }
                      label="Name Z-A"
                    />
                  </MenuItem>
                </Menu>
              </Box>
              {/* <Box>
                <Button
                    id="basic-button"
                    aria-controls={openFilter ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={openFilter ? 'true' : undefined}
                    onClick={handleClickFilter}
                    sx={{
                    background: "#fff",
                    fontSize: "0.9rem",
                    color: "lightSecondary.main",
                    textTransform: "inherit",
                    fontWeight: "600",
                    p: "10px 20px"
                    }}
                >
                    Filter
                    <KeyboardArrowDownOutlined />
                </Button>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorElFilter}
                    open={openFilter}
                    onClose={handleCloseFilter}
                    MenuListProps={{
                    'aria-labelledby': 'basic-button',
                    }}
                    sx={{
                    '& .MuiMenu-paper': {
                        borderRadius: "10px",
                        border: "solid 1px #106EED",
                        minWidth: "280px",
                        px: 2.3,
                        py: 1
                    }
                    }}
                >
                    <Box sx={{ mb: 1.5 }}>
                    <Typography
                        sx={{
                        fontSize: "12px",
                        fontWeight: '500',
                        color: 'ltheme.main',
                        lineHeight: '24px',
                        }}
                        variant="body2">
                        Select Industry
                    </Typography>
                    <FormControl fullWidth
                        sx={{
                        '& .MuiOutlinedInput-notchedOutline': {
                            border: 'none'
                        }
                        }}
                    >
                        <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={designation}
                        onChange={handleChange}
                        displayEmpty
                        IconComponent={KeyboardArrowDownOutlinedIcon}
                        sx={{
                            background: "#ECF6FF",
                            '& .MuiSelect-select': {
                            minHeight: "20px",
                            padding: "11px 14px",
                            color: "lightSecondary.main",
                            fontWeight: "600",
                            fontSize: "0.9rem"
                            },
                            '& .MuiSelect-icon': {
                            color: 'lightSecondary.main'
                            },
                        }}
                        >
                        <MenuItem>Select Industry</MenuItem>
                        <MenuItem value={2}>Insurance</MenuItem>
                        <MenuItem value={3}>Citizen service</MenuItem>
                        <MenuItem value={4}>Healthcare</MenuItem>
                        <MenuItem value={5}>Supply chain</MenuItem>
                        </Select>
                    </FormControl>
                    </Box>
                    <Box>
                    <Typography
                        sx={{
                        fontSize: "12px",
                        fontWeight: '500',
                        color: 'ltheme.main',
                        lineHeight: '24px',
                        }}
                        variant="body2">
                        Select Type
                    </Typography>
                    <FormControl fullWidth
                        sx={{
                        '& .MuiOutlinedInput-notchedOutline': {
                            border: 'none'
                        }
                        }}
                    >
                        <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={designation}
                        onChange={handleChange}
                        displayEmpty
                        IconComponent={KeyboardArrowDownOutlinedIcon}
                        sx={{
                            background: "#ECF6FF",
                            '& .MuiSelect-select': {
                            minHeight: "20px",
                            padding: "11px 14px",
                            color: "lightSecondary.main",
                            fontWeight: "600",
                            fontSize: "0.9rem"
                            },
                            '& .MuiSelect-icon': {
                            color: 'lightSecondary.main'
                            },
                        }}
                        >
                        <MenuItem>Select Type</MenuItem>
                        <MenuItem value={12}>All</MenuItem>
                        <MenuItem value={13}>Starred</MenuItem>
                        </Select>
                    </FormControl>
                    </Box>
                    <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
                    <Box sx={{ width: '50%' }}>
                        <Button variant="outlined"
                        sx={{
                            width: "100%",
                            textTransform: 'inherit',
                            p: "7px 25px",
                            border: "1px solid ltheme.main",
                            color: "lightSecondary.main",
                            fontWeight: '700',
                            fontSize: '12px',
                            '&:hover': {
                            border: "1px solid ltheme.main",
                            }
                        }}
                        onClick={InviteBsiCoLabHandle}
                        >
                        Reset
                        </Button>
                    </Box>
                    <Box sx={{ width: '50%' }}>
                        <Button variant="contained"
                        sx={{
                            width: "100%",
                            textTransform: 'inherit',
                            p: "7px 25px",
                            boxShadow: 'none',
                            color: "#fff",
                            fontWeight: '700',
                            fontSize: '12px',
                            '&:hover': {
                            boxShadow: 'none',
                            }
                        }}
                        onClick={InviteBsiCoLabHandle}
                        >
                        Apply
                        </Button>
                    </Box>
                    </Box>
                </Menu>
                </Box> */}
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Grid mt={1.5} sx={{paddingLeft:'16px'}} container rowSpacing={2} columnSpacing={2}>
        <Grid container spacing={2}>
          {ListData && ListData.length > 0 ? (
            ListData.map((user, index) => (  
                <Grid  item xs={12} md={6} lg={4} key={index}>
                  <Card
                   sx={{
                    boxShadow: "none",
                    borderRadius: "10px",
                  }}
                  >
                    <CardContent style={{ paddingBottom: "10px" }}>
                      <Box
                        sx={{
                          p: "0",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box display={"flex"}
                        sx={{
                            px: "0",
                            alignItems: "center",
                        }}>
                          <ListItemAvatar>
                            <Avatar
                              sx={{
                                bgcolor: "lightSecondary.main",
                                px: 2.8,
                                fontSize: "1.8rem",
                                fontWeight: "700",
                                width: "90px",
                                height: "90px",
                                borderRadius: "10px",
                                [theme.breakpoints.down("md")]: {
                                  width: "70px",
                                  height: "70px",
                                  fontSize: "1.2rem",
                                },
                              }}
                              variant="rounded"
                            >
                              {`${user?.first_name
                                .charAt(0)
                                .toUpperCase()}${user?.last_name
                                .charAt(0)
                                .toUpperCase()}`}
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText sx={{ pl: "10px", width: "60%" }}>
                            <Typography
                              noWrap
                              sx={{
                                fontSize: "1.1rem",
                                fontWeight: "600",
                                color: "secondary.main",
                                lineHeight: "24px",
                                maxWidth: "100%",
                              }}
                              variant="h6"
                              
                            >
                             <Tooltip title={`${user?.first_name} ${user?.last_name}`}>
                              {user?.first_name} {user?.last_name}
                            </Tooltip>
                            </Typography>
                            <Typography
                              noWrap
                              variant="body2"
                              sx={{
                                fontSize: "0.9rem",
                                fontWeight: "500",
                                color: "primary.main",
                                maxWidth: "100%",
                              }}
                            >

                             <Tooltip title={user?.email}>{user?.email}</Tooltip> 
                            </Typography>
                            <Typography
                              noWrap
                              variant="body2"
                              sx={{
                                fontSize: "0.9rem",
                                fontWeight: "500",
                                color: "ltheme.main",
                                width: "100%",
                              }}
                            >
                              {user.isAdmin ? "Admin" : "Manager"}
                            </Typography>
                          </ListItemText>
                        </Box>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>  
            ))
          ) : (
            ListData && ListData.length == 0 ?
            (
              <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "70vh",
                width: "100%",
              }}
            >
              <NoTeamMemberScreen />
            </Box>
            ):
            (
              <Grid container spacing={2} sx={{ py: 2, px: 1 }}>
              {[...Array(skullCount)].map((_, index) => (
                  <ManageTeamSkull/>
              ))}
             </Grid>
            )
          )}
        </Grid>
      </Grid>
      <Modals open={modalopen} handleClose={handleModalClose} modalWidth={500}>
        <CreateNewGroup selectedBoardOpen={selectedBoardOpen} />
      </Modals>
      <Modals
        open={openSelectedBoard}
        handleClose={closeSelectedBoard}
        modalWidth={500}
      >
        <SelectBoardSolution
          createNewGroupChatHandle={createNewGroupChatHandle}
        />
      </Modals>
      <Modals
        open={openCreateNewGroupChat}
        handleClose={closeCreateNewGroupChat}
        modalWidth={500}
      >
        <CreateNewGroupChat goBackHandle={goBackHandle} manageTeam={true} />
      </Modals>
      
      <Modals
        open={openInviteBsiCoLab}
        handleClose={closeInviteBsiCoLab}
        modalWidth={500}
      >
        <InvitetoBSIColab
          showToastMessage={showToastMessage}
          refetchTeamMemberList={refetchTeamMemberList}
          handleClose={closeInviteBsiCoLab}
        />
      </Modals>
      {/* {
        userType !== "solution_provider"?
      <Modals
        open={openInviteBsiCoLab}
        handleClose={closeInviteBsiCoLab}
        modalWidth={500}
      >
        <InvitetoBSIColab
          showToastMessage={showToastMessage}
          refetchTeamMemberList={refetchTeamMemberList}
          handleClose={closeInviteBsiCoLab}
        />
      </Modals>
      :
      <Modals
        open={openInviteBsiCoLabForSolutionProvider}
        handleClose={closeInviteBsiCoLabSolutionProvider}
        modalWidth={500}
      >
        <InviteToBsiCoLabForSolutionProvider
          showToastMessage={showToastMessage}
          refetchTeamMemberList={refetchTeamMemberList}
          handleClose={closeInviteBsiCoLabSolutionProvider}
        />
      </Modals>
      } */}
      
      <Modals
        open={openDirectMessage}
        handleClose={closedirectMessage}
        modalWidth={500}
      >
        <DirectMessage
          handleClose={closedirectMessage}
          initiateNewDMHandle={initiateNewDMHandle}
        />
      </Modals>
      <Modals
        open={openInitiateNewDM}
        handleClose={closeInitiateNewDM}
        modalWidth={500}
      >
        <InitiateNewDirectMessage handleClose={closeInitiateNewDM} />
      </Modals>
    </>
  );
};

export default ManageTeamMembers;
